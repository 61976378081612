import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from '../../api/axios';
import './styles/AdminChristmasChild.css';

function AddEditPastSession() {
  const { id } = useParams();
  const navigate = useNavigate();
  const editMode = Boolean(id);

  // State for form fields
  const [serviceDate, setServiceDate] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [description, setDescription] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [useVideoLink, setUseVideoLink] = useState(true);
  const [includeVideo, setIncludeVideo] = useState(true);
  const [bulletinFile, setBulletinFile] = useState(null);
  const [includeBulletin, setIncludeBulletin] = useState(true);
  const [existingVideoFileName, setExistingVideoFileName] = useState('');
  const [existingBulletinFileName, setExistingBulletinFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editMode) {
        setIsLoading(true);
        axios.get(`/api/past-services/${id}`)
            .then(response => {
                const data = response.data;
                setServiceDate(data.service_date.split('T')[0]);
                setServiceType(data.service_type);
                setDescription(data.description);
                setVideoLink(data.video_link);
                setExistingVideoFileName(data.video_file_name);
                setUseVideoLink(data.video_link !== 'none');
                setExistingBulletinFileName(data.bulletin_file_name);
                setIncludeBulletin(data.bulletin_file_name !== 'none');
                setIncludeVideo(data.video_link !== 'none' || data.video_file_name !== 'none');
            })
            .catch(error => console.error("Failed to fetch data:", error))
            .finally(() => setIsLoading(false));
    }
}, [id, editMode]);



const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true); // Set loading to true when starting the submission

  const formData = new FormData();
  formData.append('service_date', String(new Date(serviceDate).toISOString().split('T')[0]));
  formData.append('service_type', serviceType);
  formData.append('description', description);

  // Handle video link and file conditionally
  if (includeVideo) {
    formData.append('video_link', useVideoLink ? videoLink : 'none');
    
    // Only append a new video file if selected and file exists
    if (!useVideoLink && videoFile) {
      formData.append('video_file', videoFile);
    }
  } else {
    formData.append('video_link', 'none');
  }

  // Handle bulletin file conditionally
  if (includeBulletin && bulletinFile) {
    formData.append('bulletin_file', bulletinFile);
  }

  try {
    const url = editMode ? `/api/past-services/${id}` : '/api/past-services';
    const method = editMode ? 'put' : 'post';
    await axios({
      method,
      url,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    navigate("/admin/past-sessions");
  } catch (error) {
    console.error("Error submitting form:", error.response?.data || error.message);
  } finally {
    setIsLoading(false); // Reset loading state after submission
  }
};

   
  
  
    

  return (
    <>
      <div className="add-new-button form">
        <Link to="/admin/past-sessions">Cancel</Link>
      </div>
      <div className="add-edit-wrapper">
        <div className="add-edit-form">
          <h2>{editMode ? "Edit Past Session" : "Add New Session"}</h2>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            
            <div className="admin-input-wrapper">
              <label htmlFor="service-date">Service Date</label>
              <input
                type="date"
                id="service-date"
                value={serviceDate}
                onChange={(e) => setServiceDate(e.target.value)}
                required
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="service-type">Service Type</label>
              <input
                type="text"
                id="service-type"
                value={serviceType}
                onChange={(e) => setServiceType(e.target.value)}
                required
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="4"
                required
              />
            </div>

            <div className="admin-input-wrapper">
    <div className="checkbox-wrapper">
        <input
            type="checkbox"
            checked={includeVideo}
            onChange={() => setIncludeVideo(!includeVideo)}
        />
        <label>Include Video</label>
    </div>

    {includeVideo && (
    <>
        <label>Video</label>
        <div className="toggle-switch">
            <label className="switch">
                <input
                    type="checkbox"
                    checked={useVideoLink}
                    onChange={() => setUseVideoLink(!useVideoLink)}
                />
                <span className="slider"></span>
            </label>
            <span>{useVideoLink ? "Use Video Link" : "Use Video File"}</span>
        </div>

        {useVideoLink ? (
            <input
                type="text"
                placeholder="Enter video link or iframe embed code"
                value={videoLink}
                onChange={(e) => setVideoLink(e.target.value)}
                required={!videoFile}
            />
        ) : (
            <div className="file-input-wrapper">
                <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => setVideoFile(e.target.files[0])}
                />
                {editMode && existingVideoFileName && !videoFile && (
                    <small>Current File: {existingVideoFileName}</small>
                )}
            </div>
        )}
    </>
)}

  </div>


            <div className="admin-input-wrapper">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={includeBulletin}
                  onChange={() => setIncludeBulletin(!includeBulletin)}
                />
                <label>Upload Bulletin</label>
              </div>
              {includeBulletin && (
                <div className="file-input-wrapper">
                  <input
                    type="file"
                    id="bulletin-file"
                    accept=".pdf"
                    onChange={(e) => setBulletinFile(e.target.files[0])}
                  />
                  {editMode && existingBulletinFileName && !bulletinFile && (
                    <small>Current Bulletin: {existingBulletinFileName}</small>
                  )}
                </div>
              )}
            </div>

            <button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddEditPastSession;
