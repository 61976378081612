import React, { useState, useEffect } from "react";
import axios from './../../api/axios';
import './styles/AdminDailyReadings.css';
import { Link } from "react-router-dom";

const AdminDailyReadings = () => {
    const [readings, setReadings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchReadings = async () => {
            try {
                const response = await axios.get('/api/daily-readings');
                setReadings(response.data);
            } catch (error) {
                console.error("Failed to fetch daily readings:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchReadings();
    }, []);

    const handleDelete = async (id) => {
        if (!window.confirm("Are you sure you want to delete this entry?")) return;

        try {
            await axios.delete(`/api/daily-readings/${id}`);
            setReadings((prevReadings) => prevReadings.filter((reading) => reading.id !== id));
        } catch (error) {
            console.error("Failed to delete entry:", error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
    };

    return (
        <>
            <div className="add-new-button">
                <Link to="/admin/daily-readings/create-new">Add New</Link>
            </div>
            <div className="daily-readings-wrapper admin">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    readings.map((reading) => (
                        <div className="bible-reading-wrapper" key={reading.id}>
                            <div className="bible-reading admin">
                                <h3>{formatDate(reading.reading_date)}</h3>
                                <h5>{reading.scripture}</h5>

                                <div className="actions-wrapper">
                                    <Link to={`/admin/daily-readings/edit/${reading.id}`}>Edit</Link>
                                    <button onClick={() => handleDelete(reading.id)} className="delete-button">Delete</button>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </>
    );
};

export default AdminDailyReadings;
