import React from 'react';
import { Helmet } from 'react-helmet-async';
import scouts from './../assets/images/campfire.webp';

import cubScouts from './../assets/images/cubScoutLogo.png';
import boyScouts from './../assets/images/boyScoutLogo.png';

const Scouts = () => {
    return (
        <>
            <Helmet>
                <title>Boy and Cub Scouts - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Join Troop 25 and Pack 25 for Boy and Cub Scouts at Cross Roads United Methodist Church. Learn about our activities, meetings, and how to get involved."
                />
                <meta property="og:title" content="Boy and Cub Scouts - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Cross Roads UMC is proud to sponsor Troop 25 and Pack 25. Discover how to join and participate in Boy and Cub Scouts programs."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/cub-boy-scouts" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/cub-boy-scouts" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "PlaceOfWorship",
                        "name": "Cross Roads United Methodist Church",
                        "url": "https://www.crossroadswv.com",
                        "logo": "%PUBLIC_URL%/logo512.png",
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ],
                        "contactPoint": [
                            {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-522-7434",
                                "contactType": "Church Support",
                                "areaServed": "US",
                                "availableLanguage": "English",
                                "email": "crossroadsumc@comcast.net"
                            }
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "3146 Saltwell Rd",
                            "addressLocality": "Huntington",
                            "addressRegion": "WV",
                            "postalCode": "25705",
                            "addressCountry": "US"
                        },
                        "description": "Cross Roads United Methodist Church sponsors Boy Scout Troop 25 and Cub Scout Pack 25, providing opportunities for youth to learn, grow, and give back to the community.",
                        "foundingDate": "1892",
                        "department": {
                            "@type": "Organization",
                            "name": "Scouts Program",
                            "description": "Troop 25 and Pack 25 offer scouting opportunities for boys and young men to develop skills, values, and camaraderie.",
                            "url": "https://www.crossroadswv.com/cub-boy-scouts",
                            "additionalType": "Nonprofit Organization",
                            "parentOrganization": {
                                "@type": "PlaceOfWorship",
                                "name": "Cross Roads United Methodist Church"
                            }
                        },
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com/cub-boy-scouts"
                        }
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper scouts'>
                <div className='page-title'>
                    <h1>Boy and Cub Scouts</h1>
                    <hr/>
                </div>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Troop 25 and Pack 25 at Cross Roads</h2>
                            <div className='text-content'>
                                <p>Cross Roads United Methodist Church is proud to sponsor <b>Boy Scout Troop 25</b> and <b>Cub Scout Pack 25</b>. As the charter organization, we support these groups by providing a safe space and resources for activities, leadership training, and community involvement.</p>
                            </div>
                            <div className='text-content'>
                                <p>Our scouts participate in a variety of activities including camping, community service projects, and skill-building events. This program fosters growth, teamwork, and character in young men, preparing them to be responsible, active members of the community.</p>
                            </div>
                            <br/>
                            <div className='text-content'>
                                <p><b>Cub Scouts meet on Mondays at 6:30 PM.</b></p>
                                <p><b>Boy Scouts meet on Thursdays at 7:00 PM.</b></p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={scouts} alt="Boy and Cub Scouts in activities sponsored by Cross Roads United Methodist Church" />
                        </div>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>How to Join</h2>
                            <div className='text-content'>
                                <p>If your child is interested in joining Troop 25 or Pack 25, we welcome them to come to one of our meetings. Scouting is open to boys who want to learn valuable skills, make friends, and be part of a supportive community.</p>
                                <p>Feel free to reach out to Cross Roads UMC for more information or visit us during meeting times to speak with our scout leaders and get an idea of the activities we offer. We look forward to welcoming new members to our scouting family.</p>
                            </div>
                            <div className='scouts-wrapper'>
                                <div className='scout-wrapper'>
                                    <img src={cubScouts} alt="Boy and Cub Scouts in activities sponsored by Cross Roads United Methodist Church" />
                                    <span>Boy Scouting is available to boys who earned the Arrow of Light as a Cub Scout and are at least 10 years old or have completed the 5th grade and are at least 10, or who are 11, but not yet 18 years old. The scouting objectives are developing character, citizenship, and personal fitness. The troop has an active year-round camping schedule.</span>
                                </div>
                                <div className='scout-wrapper'>
                                    <img src={boyScouts} alt="Boy and Cub Scouts in activities sponsored by Cross Roads United Methodist Church" />
                                    <span>Boy Scouting is available to boys who earned the Arrow of Light as a Cub Scout and are at least 10 years old or have completed the 5th grade and are at least 10, or who are 11, but not yet 18 years old. The scouting objectives are developing character, citizenship, and personal fitness. The troop has an active year-round camping schedule.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Scouts;
