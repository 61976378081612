import React, { useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import './styles/Admin.css';
import AdminHeader from '../components/admin/AdminHeader';
import { Helmet } from 'react-helmet-async';

function Admin() {

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Redirect to /admin/dashboard only if the current path is /admin
    if (location.pathname === '/admin') {
      navigate('/admin/dashboard');
    }
  }, [location.pathname, navigate]);


  return (
    <div className='adminWrapper'>
      
      <Helmet>
        <title>Admin - Cross Roads United Methodist Church</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      
      <AdminHeader />
      
      <Outlet />
    </div>
  );
}

export default Admin;
