import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from './../api/axios';
import './styles/CommonGrounds.css';
import { Helmet } from 'react-helmet-async';

import ImageGalleryComponent from '../components/ImageGalleryComponent';

const ViewMissionTrip = () => {
    const { id } = useParams();
    const [missionTrip, setMissionTrip] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const baseURL = process.env.REACT_APP_API_URL;

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    useEffect(() => {
        const fetchMissionTrip = async () => {
            try {
                const response = await axios.get(`/api/mission-trips/${id}`);
                setMissionTrip(response.data);
            } catch (error) {
                console.error("Failed to load mission trip details:", error);
            }
        };

        const fetchImages = async () => {
            try {
                const response = await axios.get(`/api/mission-trips/${id}/images`);
                const { location, files } = response.data;
                const sanitizedLocation = location.replace(/[^a-z0-9]/gi, '_').toLowerCase();
                const images = files.map((filename) => ({
                    original: `${baseURL}/uploads/mission_trips/${sanitizedLocation}/${filename}`,
                    thumbnail: `${baseURL}/uploads/mission_trips/${sanitizedLocation}/${filename}`,
                }));
                setGalleryImages(images);
            } catch (error) {
                console.error("Failed to load images:", error);
            }
        };

        fetchMissionTrip();
        fetchImages();
    }, [id, baseURL]);

    if (!missionTrip) return <p>Loading...</p>;

    const sanitizedLocation = missionTrip.location?.replace(/[^a-z0-9]/gi, '_').toLowerCase();

    return (
        <>
            <Helmet>
                <title>{`${missionTrip.location || "Mission Trip"} - Cross Roads United Methodist Church`}</title>
                <meta
                    name="description"
                    content={`Learn about our mission trip to ${missionTrip.location || "various locations"} held in ${missionTrip.trip_date ? formatDate(missionTrip.trip_date) : "recent years"} by Cross Roads United Methodist Church.`}
                />
                <meta property="og:title" content={`${missionTrip.location || "Mission Trip"} - Cross Roads United Methodist Church`} />
                <meta
                    property="og:description"
                    content={`Discover our mission trip to ${missionTrip.location || "various locations"} and view highlights from our service work.`}
                />
                <meta property="og:image" content={`${baseURL}/uploads/mission_trips/${sanitizedLocation}/${missionTrip.image}`} />
                <meta property="og:url" content={`https://crossroadswv.com/mission-trips/${id}`} />
                <meta property="og:type" content="website" />

                <link rel="canonical" href={`https://crossroadswv.com/mission-trips/${id}`} />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "name": `Mission Trip to ${missionTrip.location || "Undisclosed Location"}`,
                        "description": missionTrip.story || "Join us in learning more about our mission work and experiences during this trip.",
                        "startDate": missionTrip.trip_date,
                        "location": {
                            "@type": "Place",
                            "name": missionTrip.location || "Various Locations",
                            "address": {
                                "@type": "PostalAddress",
                                "addressCountry": "US"
                            }
                        },
                        "image": `${baseURL}/uploads/mission_trips/${sanitizedLocation}/${missionTrip.image}`,
                        "organizer": {
                            "@type": "Organization",
                            "name": "Cross Roads United Methodist Church",
                            "url": "https://crossroadswv.com",
                            "logo": "%PUBLIC_URL%/logo512.png",
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-522-7434",
                                "contactType": "Church Support",
                                "email": "crossroadsumc@comcast.net",
                                "areaServed": "US",
                                "availableLanguage": "English"
                            }
                        },
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": `https://crossroadswv.com/mission-trips/${id}`
                        }
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper past-service'>
                <div className='page-title'>
                    <h1>{missionTrip.location || "Mission Trip"}</h1>
                    <h2 className='page-title-subtitle'>
                        {missionTrip.trip_date ? formatDate(missionTrip.trip_date) : "Date unavailable"}
                    </h2>
                    <hr />
                </div>

                {/* About Mission Trip Section */}
                <section className='text-content-section' aria-labelledby="mission-trip-about">
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2 id="mission-trip-about">About {missionTrip.location || "Common Grounds"}</h2>
                            <div className='text-content'>
                                <p dangerouslySetInnerHTML={{ __html: missionTrip.story || "No story available" }} />
                            </div>
                        </div>
                        <figure className='section-image-placeholder'>
                            <img 
                                src={`${baseURL}/uploads/mission_trips/${sanitizedLocation}/${missionTrip.image}`} 
                                alt={missionTrip.location || 'Mission Trip'} 
                            />
                        </figure>
                    </div>
                </section>

                {/* Gallery Section */}
                <section className='gallery-section' aria-labelledby="mission-trip-gallery">
                    <div className='section-content-wrapper mission-trips'>
                        <h2 id="mission-trip-gallery">Gallery/Highlights</h2>
                        <div className='gallery-wrapper' aria-label="Gallery of mission trip highlights" aria-live="polite">
                            <ImageGalleryComponent images={galleryImages} />
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default ViewMissionTrip;
