import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY; // Retrieve the API key

// Global axios defaults
axios.defaults.withCredentials = true;

// Creating an axios instance with default settings
const instance = axios.create({
    baseURL: API_URL,
    withCredentials: true, // Ensure credentials are sent with each request
    headers: {
        'x-api-key': API_KEY // Set the API key as a custom header
    }
});

export default instance;