import React, { useState, useEffect } from 'react';
import axios from './../api/axios';
import './styles/CommonGrounds.css';
import { Helmet } from 'react-helmet-async';

import banner from './../assets/images/commonGrounds1.webp';
import ImageGalleryComponent from '../components/ImageGalleryComponent';
import septemberClub1 from './../assets/images/septemberClub1.webp';
import septemberClub2 from './../assets/images/septemberClub2.webp'

const SeptemberClub = () => {
    const [galleryImages, setGalleryImages] = useState([]);
    const baseURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchImages = async () => {
            try {
                // Updated route for September Club images
                const response = await axios.get('/api/september-club/images');
                const images = response.data.map((filename) => ({
                    original: `${baseURL}/uploads/september-club/${filename}`,
                    thumbnail: `${baseURL}/uploads/september-club/${filename}`,
                }));
                setGalleryImages(images);
            } catch (error) {
                console.error("Failed to load images:", error);
            }
        };
        fetchImages();
    }, [baseURL]);

    return (
        <>
            <Helmet>
                <title>September Club - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="September Club is a monthly gathering at Cross Roads United Methodist Church where individuals come together for lunch, fellowship, and meaningful conversation."
                />
                <meta property="og:title" content="September Club - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Join September Club, a monthly gathering at Cross Roads United Methodist Church, for lunch, fellowship, and community-building activities."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/september-club" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="%PUBLIC_URL%/septemberClub1.jpg" />

                <link rel="canonical" href="https://www.crossroadswv.com/september-club" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": ["Organization", "Event"],
                        "name": "September Club Gathering",
                        "description": "September Club is a monthly gathering at Cross Roads United Methodist Church offering lunch, fellowship, and meaningful conversation.",
                        "url": "https://www.crossroadswv.com/september-club",
                        "image": "%PUBLIC_URL%/septemberClub1.jpg",
                        "location": {
                            "@type": "Place",
                            "name": "Cross Roads United Methodist Church",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "3146 Saltwell Rd",
                                "addressLocality": "Huntington",
                                "addressRegion": "WV",
                                "postalCode": "25705",
                                "addressCountry": "US"
                            }
                        },
                        "event": {
                            "@type": "SocialEvent",
                            "name": "September Club Lunch & Fellowship",
                            "description": "A community lunch gathering that encourages fellowship, connection, and meaningful conversation.",
                            "location": {
                                "@type": "Place",
                                "name": "Local Restaurant or Venue",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "TBD",
                                    "addressLocality": "Huntington",
                                    "addressRegion": "WV",
                                    "postalCode": "25705",
                                    "addressCountry": "US"
                                }
                            }
                        },
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+1-304-522-7434",
                            "contactType": "Church Support",
                            "areaServed": "US",
                            "availableLanguage": "English",
                            "email": "crossroadsumc@comcast.net"
                        },
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com/september-club"
                        }
                    })}
                </script>
            </Helmet>
            
            <div className='page-wrapper common-grounds'>
                <div className='page-title'>
                    <h1>SEPTEMBER CLUB</h1>
                    <hr/>
                </div>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>About September Club</h2>
                            <div className='text-content'>
                            <p>September Club meets for lunch and fellowship at noon on the first Wednesday of each month. It's a time for individuals to gather, enjoy a meal together, and engage in meaningful conversation. Whether at a local restaurant or another venue, the gathering provides an opportunity to build connections, enjoy each other's company, and share the love of Christ.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={septemberClub1} alt='Group of smiling individuals gathered around a table in a restaurant, enjoying lunch and conversation during a September Club meeting.' />
                        </div>
                    </div>
                </section>

                <section className='gallery-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Gallery</h2>
                            <div className='gallery-wrapper' aria-label="Gallery of September Club events" aria-live="polite">
                                <ImageGalleryComponent images={galleryImages} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
}

export default SeptemberClub;
