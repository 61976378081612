import React, { useState } from 'react';
import './styles/Baptism.css';
import { Helmet } from 'react-helmet-async';

import communion from './../assets/images/communion.webp';

import holymeal from './../assets/images/holymeal.webp';


const Communion = () => {

    return (
        <>
            <Helmet>
                <title>Communion - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Learn about the significance of Holy Communion within the United Methodist Church, including its meaning, open table, and practices."
                />
                <meta property="og:title" content="Communion at Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Understand the practice of Holy Communion, an open table for all, within the United Methodist Church. Discover the meanings of this sacred meal and its role in Christian faith."
                />

                <meta property="og:url" content="https://www.crossroadswv.com/communion" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/communion" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Communion",
                        "url": "https://www.crossroadswv.com/communion",
                        "mainEntity": {
                            "@type": "Article",
                            "headline": "Communion",
                            "description": "An overview of Holy Communion practices and beliefs within the United Methodist Church, including the meaning of open communion and the significance of the Holy Meal.",
                            "author": {
                                "@type": "Organization",
                                "name": "Cross Roads United Methodist Church",
                                "url": "https://www.crossroadswv.com"
                            },
                            "publisher": {
                                "@type": "Organization",
                                "name": "Cross Roads United Methodist Church",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "%PUBLIC_URL%/logo512.png"
                                }
                            },
                            "keywords": ["communion", "holy meal", "open communion", "Methodist beliefs", "sacrament"],
                            "articleBody": [
                                {
                                    "@type": "FAQPage",
                                    "mainEntity": [
                                        {
                                            "@type": "Question",
                                            "name": "What is Holy Communion in the United Methodist Church?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Holy Communion is one of two sacraments in the United Methodist Church, serving as a means of grace and symbolizing unity with Christ and fellow believers. It is known by various names, including the Lord’s Supper, Holy Communion, and Eucharist."
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "What does 'open communion' mean?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Open Communion means that all people, regardless of membership or age, are invited to partake in the sacrament. This practice reflects the United Methodist belief that the table belongs to Christ, who invites all to come."
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "How is Communion received?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Communion may be received by intinction, where participants dip the bread into a shared cup, or by individual bread pieces and cups. It represents the body and blood of Christ and strengthens believers in their faith journey."
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "Can children participate in Communion?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Yes, children are welcomed at the Communion table in the United Methodist Church, as part of the inclusive nature of Christ’s table. They are encouraged to partake and experience the mystery and wonder of the sacrament."
                                            }
                                        }
                                    ]
                                }
                            ],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "https://www.crossroadswv.com/communion"
                            }
                        }
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper communion'>
                <div className='page-title'>
                    <h1>COMMUNION</h1>
                    <hr/>
                </div>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>United Methodists and Communion</h2>
                            <div className='text-content'>
                                <p>Holy Communion, along with Baptism, one of two sacraments ordained by Christ our Lord, is a means of grace by which God works invisibly in us, bringing alive, strengthening, and confirming our faith. The term “sacrament” is taken from the Latin sacramentum, which was a Roman soldier’s pledge of allegiance. A sacrament is God’s pledge of allegiance (love and faithfulness) to us and our answering pledge of allegiance to God.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={communion} alt='A loaf of bread and a silver chalice set on a cloth, symbolizing the elements of Holy Communion in the Christian tradition.'/>
                        </div>
                    </div>
                </section>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>This Holy Meal</h2>
                            <div className='text-content'>
                                <p>This Holy Meal is known by several names. “The Lord's Supper” reminds us that it is a meal instituted by Christ and hosted by him at his table when it takes place, recalling not just the Upper Room Passover meal, but the many meals Jesus ate with his disciples. “Holy Communion” reminds us that it is an act of the most holy and intimate sharing, making us one with Christ Jesus and part of his living body, the church. “Eucharist,” a term taken from the New Testament Greek word meaning thanksgiving, reminds us that giving thanks to God for all that God has done is an essential part of the meal itself. The broken bread and shared cup are foretastes of the food we shall share in the heavenly banquet to which we have all been invited. By using different names, we acknowledge that no single name can contain the rich wealth of meanings in this sacred act as we celebrate the presence of the living, resurrected, Christ with us.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={holymeal} alt='A loaf of bread and a silver chalice set on a cloth, symbolizing the elements of Holy Communion in the Christian tradition.'/>
                        </div>
                    </div>
                </section>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Open Communion</h2>
                            <div className='text-content'>
                                <p>United Methodist receive Communion by the bread, representing the body of Christ, and the cup, representing the blood of Christ, both broken and spilled out for us on the cross. United Methodists come forward for Communion, as an act of willful response to the invitation to “come and feast at the table of the Lord.” Persons may receive Communion by intinction (receiving a piece of bread and dipping in to a common cup) or by individual pieces of bread and individual cups. It is a common practice to hold your hands in the form of a cross to receive Communion. We believe that the bread and juice are changed spiritually into the body and blood of Christ, helping us to be Christ’s body in the world, redeemed by Christ’s blood, “one with each other, and one in service to the world". United Methodists practice “open communion” because the supper is Christ’s Supper and not our own, all are invited to receive. We do not refuse anyone present desiring to receive. Children are also welcome at the table, just as we welcome them at family tables. Communion is a wonderful mystery and children who are always welcome can sense the wonder and mystery.</p>
                            </div>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Communion;
