import React, { useState, useEffect } from "react";
import axios from './../../api/axios';
import './styles/AdminMissionTrips.css';
import { Link } from "react-router-dom";

const AdminMissionTrips = () => {
    const [entries, setEntries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const baseURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchEntries = async () => {
            try {
                const response = await axios.get('/api/mission-trips'); // Fetch mission trips data
                setEntries(response.data);
            } catch (error) {
                console.error("Failed to fetch entries:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchEntries();
    }, []);

    const handleDelete = async (id) => {
        if (!window.confirm("Are you sure you want to delete this entry?")) return;

        try {
            await axios.delete(`/api/mission-trips/${id}`); // Delete mission trip by ID
            setEntries((prevEntries) => prevEntries.filter((entry) => entry.id !== id));
        } catch (error) {
            console.error("Failed to delete entry:", error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", { year: "numeric", month: "long" });
    };

    const sanitizeLocation = (location) => {
        return location.replace(/[^a-z0-9]/gi, '_').toLowerCase(); // Sanitize to match backend directory
    };

    return (
        <>
            <div className="add-new-button">
                <Link to="/admin/mission-trips/create-new">Add New</Link>
            </div>
            <div className="mission-trips-wrapper admin">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    entries.map((entry) => (
                        <div className="mission-trip admin" key={entry.id}>
                            <div className="mission-image-wrapper">
                                <img src={`${baseURL}/uploads/mission_trips/${sanitizeLocation(entry.location)}/${entry.image}`} alt={entry.location} />
                            </div>
                            <div className="mission-boxes-wrapper">
                                <h5>{entry.location}</h5>
                                <p>{formatDate(entry.trip_date)}</p>
                            </div>
                            <div className="mission-desc-wrapper">
                                <p>{entry.description}</p>
                            </div>
                            <div className="actions-wrapper">
                                <Link to={`/admin/mission-trips/edit/${entry.id}`}>Edit</Link>
                                <button onClick={() => handleDelete(entry.id)} className="delete-button">Delete</button>
                                <Link to={`/admin/mission-trips/${entry.id}/images`}>
                                    <i className="fa-solid fa-images"></i> View/Upload Images
                                </Link>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </>
    );
};

export default AdminMissionTrips;
