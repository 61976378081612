import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from '../../api/axios';
import './styles/AdminAnnouncements.css';

const AdminChurchStaff = () => {
    const [staffList, setStaffList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const baseURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchStaff = async () => {
            try {
                const response = await axios.get('/api/church-staff');
                setStaffList(response.data);
            } catch (error) {
                console.error("Failed to fetch church staff data:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchStaff();
    }, []);

    const handleDelete = async (id) => {
        if (!window.confirm("Are you sure you want to delete this staff member?")) return;

        try {
            await axios.delete(`/api/church-staff/${id}`);
            setStaffList((prevList) => prevList.filter((staff) => staff.id !== id));
        } catch (error) {
            console.error("Failed to delete staff member:", error);
        }
    };

    return (
        <>
            <div className="add-new-button">
                <Link to="/admin/church-staff/create-new">Add New Staff Member</Link>
            </div>
            <div className="church-staff-list-wrapper">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    staffList.map((staff) => (
                        <div className="staff-member-wrapper" key={staff.id}>
                            <div className="staff-member">
                                {staff.image && (
                                    <div className="staff-member-image">
                                        <img src={`${baseURL}/uploads/church-staff/${staff.image}`} alt={staff.name} />
                                    </div>
                                )}
                                <div className="staff-member-info-wrapper">
                                    <div className="staff-member-info">
                                        <div className="info-line">
                                            <h3>{staff.name}</h3>
                                        </div>
                                        <div className="info-line">
                                            <p>{staff.role_title}</p>
                                        </div>
                                        {staff.email && staff.email !== "none" && (
                                            <div className="info-line">
                                                <p>{staff.email}</p>
                                            </div>
                                        )}
                                        {staff.phone && staff.phone !== "none" && (
                                            <div className="info-line">
                                                <p>{staff.phone}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="actions-wrapper">
                                    <Link to={`/admin/church-staff/edit/${staff.id}`}>Edit</Link>
                                    <button onClick={() => handleDelete(staff.id)} className="delete-button">Delete</button>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </>
    );
};

export default AdminChurchStaff;
