// src/components/ImageGalleryComponent.js
import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './styles/ImageGalleryComponent.css';

const ImageGalleryComponent = ({ images }) => {
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    // Prevent body scroll when modal is open
    useEffect(() => {
        if (isGalleryOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isGalleryOpen]);

    const openGallery = (index) => {
        setSelectedIndex(index);
        setIsGalleryOpen(true);
    };

    const closeGallery = () => {
        setIsGalleryOpen(false);
    };

    return (
        <div>
            <div className="image-grid">
                {images.length > 0 ? (
                    images.map((image, index) => (
                        <div className='gallery-image-wrapper' key={index}>
                            <div className='image-item'>
                                <img
                                    src={image.thumbnail}
                                    alt="Gallery thumbnail"
                                    onClick={() => openGallery(index)}
                                    className="gallery-thumbnail"
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No images to display</p>
                )}
            </div>
    
            {isGalleryOpen && (
                <div className="gallery-modal" onClick={closeGallery}>
                    <div className="gallery-content" onClick={(e) => e.stopPropagation()}>
                        <ImageGallery
                            items={images}
                            startIndex={selectedIndex}
                            onSlide={(currentIndex) => setSelectedIndex(currentIndex)}
                            showThumbnails={false}
                            showPlayButton={false}
                            showFullscreenButton={false}
                            onClick={closeGallery}
                        />
                    </div>
                </div>
            )}
        </div>
    );
    
};

export default ImageGalleryComponent;
