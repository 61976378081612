import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';

const PrivateRoute = () => {
  const { auth, hasAccess } = useAuth(); // Use the shared `hasAccess` from AuthProvider
  const location = useLocation();

  if (!auth.isAuthenticated) {
    // If not authenticated, redirect to login
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!hasAccess(location.pathname)) {
    // If authenticated but lacks access, redirect to dashboard
    return <Navigate to="/admin/dashboard" state={{ from: location }} replace />;
  }

  // Allow access to the route
  return <Outlet />;
};

export default PrivateRoute;
