
import React from 'react';
import './styles/Childcare.css'
import { Helmet } from 'react-helmet-async';

import childCare from './../assets/images/childcareWindow.webp';
import ChildcareFees from '../components/ChildcareFees';
import ChildcareStaff from '../components/ChildcareStaff';
import ChildcareForms from '../components/ChildcareForms';

import employmentApplication from './../assets/childcare_forms/childcareEmploymentApplication.pdf';

import parentHandbook from './../assets/childcare_forms/childcareParentPolicyHandbook.pdf';


const Childcare = () => {


    return (
        <>
            <Helmet>
                <title>Cross Roads United Methodist Church - Childcare and Pre-K</title>
                <meta
                    name="description"
                    content="Cross Roads Child Care provides licensed childcare and preschool services. Learn about our programs, mission, and dedicated staff."
                />
                <meta property="og:title" content="Cross Roads Childcare and Preschool" />
                <meta
                    property="og:description"
                    content="Licensed childcare and preschool services with a Christian foundation, offered by Cross Roads United Methodist Church."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/childcare-preschool" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/childcare-preschool" />

                {/* JSON-LD Structured Data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "PlaceOfWorship",
                        "name": "Cross Roads United Methodist Church Childcare and Preschool",
                        "url": "https://www.crossroadswv.com/childcare-preschool",
                        "logo": "%PUBLIC_URL%/logo512.png",
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ],
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+1-304-525-6547",
                            "contactType": "Childcare Services",
                            "areaServed": "US",
                            "email": "crccm@comcast.net",
                            "description": "Contact Cross Roads Childcare and Preschool for enrollment and program details."
                        },
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "3146 Saltwell Rd",
                            "addressLocality": "Huntington",
                            "addressRegion": "WV",
                            "postalCode": "25705",
                            "addressCountry": "US"
                        },
                        "hasMap": "https://www.google.com/maps/place/Crossroads+UMC/@38.4024952,-82.3881641,15z/data=!4m2!3m1!1s0x0:0x4b99b1521962cec5?sa=X&ved=1t:2428&ictx=111",
                        "description": "Cross Roads Child Care offers licensed childcare and preschool programs in a nurturing Christian environment. Available programs include infant care, preschool, and after-school care.",
                        "foundingDate": "1892",
                        "keywords": ["licensed childcare", "preschool", "pre-k", "Christian daycare", "WV childcare", "developmentally appropriate care"],
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "38.4024952",
                            "longitude": "-82.3881641"
                        },
                        "openingHours": "Mo-Fr 07:00-18:00",
                        "department": [
                            {
                                "@type": "Organization",
                                "name": "Cross Roads Daycare and Preschool",
                                "sameAs": "https://www.crossroadswv.com/childcare-preschool",
                                "serviceType": "Infant Care, Preschool, School-age Programs",
                                "description": "Our childcare program serves families with high-quality, developmentally appropriate care in a Christian atmosphere.",
                                "staff": [
                                    { 
                                        "@type": "Person", 
                                        "jobTitle": "Director" 
                                    },
                                    { 
                                        "@type": "Person", 
                                        "jobTitle": "Assistant Director" 
                                    },
                                    { 
                                        "@type": "Role", 
                                        "name": "Infant Care Team" 
                                    },
                                    { 
                                        "@type": "Role", 
                                        "name": "Toddler Care Team" 
                                    },
                                    { 
                                        "@type": "Role", 
                                        "name": "Preschool Teachers" 
                                    },
                                    { 
                                        "@type": "Role", 
                                        "name": "Kitchen Staff" 
                                    }
                                ]
                            }
                        ],
                        "programsOffered": [
                            {
                                "@type": "Offer",
                                "name": "Infant Care Program",
                                "description": "For infants ages 6 weeks to 24 months.",
                                "price": "140",
                                "priceCurrency": "USD",
                                "eligibleCustomerType": "Infant"
                            },
                            {
                                "@type": "Offer",
                                "name": "2-Year-Old Class",
                                "description": "For children aged 2 years.",
                                "price": "130",
                                "priceCurrency": "USD",
                                "eligibleCustomerType": "Child"
                            },
                            {
                                "@type": "Offer",
                                "name": "3-Year-Old Class",
                                "description": "For children aged 3 years.",
                                "price": "120",
                                "priceCurrency": "USD",
                                "eligibleCustomerType": "Child"
                            },
                            {
                                "@type": "Offer",
                                "name": "4-Year-Old Class",
                                "description": "For children aged 4 years.",
                                "price": "110",
                                "priceCurrency": "USD",
                                "eligibleCustomerType": "Child"
                            },
                            {
                                "@type": "Offer",
                                "name": "Preschool Program",
                                "description": "Preschool care during the Pre-K school year.",
                                "price": "100",
                                "priceCurrency": "USD",
                                "eligibleCustomerType": "Preschooler"
                            },
                            {
                                "@type": "Offer",
                                "name": "School-Age Program",
                                "description": "After-school care for children attending Explorer Academy and Hite Saunders Elementary.",
                                "price": "65",
                                "priceCurrency": "USD",
                                "eligibleCustomerType": "School Age"
                            }
                        ],
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com/childcare-preschool"
                        }
                    })}
                </script>
            </Helmet>
    
            <div className='page-wrapper childcare'>
                <div className='page-title'>
                    <h1>CHILDCARE AND PRE-K</h1>
                    <hr/>
                </div>
                <div className='verified-wrapper'>
                    <div className='checkmark-wrapper' aria-hidden="true">
                        <i className="fa-solid fa-certificate"></i><i className="fa-solid fa-check"></i>
                    </div>
                    <div className='verified-text-wrapper'>
                        <p>Cross Roads Child Care is licensed by the West Virginia Department of Health and Human Resources and is inspected and registered with the State Fire Marshal, Cabell County Health Department, and the Department of Education Office of Child Nutrition. We follow best practices in Early Childhood Education, and all employees receive ongoing state-approved training.</p>
                    </div>
                </div>
                <section className='text-content-section main-childcare'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2 id="mission-title">Our Mission</h2>
                            <div className='text-content' aria-labelledby="mission-title">
                                <p>The Child Care facility is just one facet of Cross Roads UMC Child Care's ministry to children. The mission of the program is to minister to families in the area by providing a high quality, developmentally appropriate program in a Christian atmosphere. This program shall address the physical, emotional, social, and spiritual needs of the children and their families.</p>
                            </div>
    
                            <h2 id="contact-title">Contact</h2>
                            <div className='text-content' aria-labelledby="contact-title">
                                <p style={{marginBottom: '0.5rem'}}>For comprehensive info, click <a href={parentHandbook} target="_blank" rel="noopener noreferrer">here</a> to view the parent policy handbook.</p>
                                
                                <p style={{marginBottom: '0.5rem'}}>You can find necessary childcare enrollment forms at the bottom of this page.</p>
                                <p>For any questions or for more information, please contact the childcare office at the following:</p>
    
                                <div className='info-icons-wrapper'>
                                    <div className='info-pillar general'>
                                        <div className='info-icon' style={{borderRadius: '50%'}}>
                                            <i className="fa-solid fa-clock" aria-hidden="true" />
                                        </div>
                                        <div className='info-text'>
                                            <p>7:00 a.m. to 6:00 p.m. Monday Through Friday</p>
                                        </div>
                                    </div>
                                    <div className='info-pillar general'>
                                        <div className='info-icon' style={{borderRadius: '50%'}}>
                                            <i className="fa-solid fa-phone" aria-hidden="true" />
                                        </div>
                                        <div className='info-text'>
                                            <a href="tel:304-525-6547" aria-label="Phone number 304-525-6547">304-525-6547</a>
                                        </div>
                                    </div>
                                    <div className='info-pillar'>
                                        <div className='info-icon' style={{borderRadius: '50%'}}>
                                            <i className="fa-solid fa-at" aria-hidden="true" />
                                        </div>
                                        <div className='info-text'>
                                            <a href="mailto:crccm@comcast.net" aria-label="Email crccm@comcast.net">crccm@comcast.net</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='section-image-placeholder childcare'>
                            <img src={childCare} loading='eager' alt='Beautiful stained glass church window showing Jesus with children, surrounded by nature symbols of a seashell and butterfly.' style={{borderRadius: '1rem'}}/>
                            <h2>More Info</h2>
                            <div className='text-content' aria-labelledby="mission-title">
                                <p>The Cross Roads UMC Child Care is owned by Cross Roads United Methodist Church and is operated by the Board of Directors selected by the Administrative Counsel of the Church, and is a non-profit organization.</p>
                                <p style={{margin: '0.8rem 0'}}><b>Note on Discipline: </b>Children shall be disciplined with kindness and understanding and are never to be spanked, or given any type of corporal punishment.</p>
                                <p style={{marginBottom: '0.5rem'}}>To apply for employment, click <a href={employmentApplication} target="_blank" rel="noopener noreferrer">here</a> to view and download a job application.</p>
                            </div>
                        </div>
                    </div>
                </section>
    
                <ChildcareFees />
                
                <ChildcareStaff />
                <ChildcareForms />
            </div>
        </>
    );
    
}

export default Childcare;