import React, { useState, useEffect, useMemo } from "react";
import { Link } from 'react-router-dom';
import axios from './../../api/axios';
import { useAuth } from '../../context/AuthProvider';
import './styles/AdminUsers.css';

const AdminUsers = () => {
    const [users, setUsers] = useState([]);
    const [loggedInUsers, setLoggedInUsers] = useState([]);
    const [hoveredRow, setHoveredRow] = useState(null);
    const { auth } = useAuth();

    // Memoize ROLE_HIERARCHY
    const ROLE_HIERARCHY = useMemo(() => ({
        siteadmin: 3,
        admin: 2,
        user: 1,
        church: 1,
        childcare: 1,
    }), []);

    // Filtered users based on hierarchy
    const filteredUsers = useMemo(() => 
        users.filter(
            (user) => ROLE_HIERARCHY[user.role] <= ROLE_HIERARCHY[auth.user.role]
        ),
        [users, ROLE_HIERARCHY, auth.user.role]
    );

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-users`);
                const enrichedUsers = Array.isArray(response.data)
                    ? response.data.map(user => ({
                        ...user,
                        security_level: ROLE_HIERARCHY[user.role],
                    }))
                    : []; // Default to empty array if data isn't an array
                setUsers(enrichedUsers);
            } catch (error) {
                console.error("Failed to fetch users:", error);
            }
        };

        const fetchLoggedInUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-logged-in-users`);
                setLoggedInUsers(response.data);
            } catch (error) {
                console.error("Failed to fetch logged-in users:", error);
            }
        };

        fetchUsers();

        if (auth.user.role === 'siteadmin') {
            fetchLoggedInUsers();
        }
    }, [auth.user.role, ROLE_HIERARCHY]);

    const deleteUser = async (id, userSecurityLevel) => {
        if (auth.user.role !== 'siteadmin' && auth.user.security_level <= userSecurityLevel) {
            alert("You cannot delete a user with higher or equal security level.");
            return;
        }
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete-user/${id}`);
            setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
        } catch (error) {
            console.error("Failed to delete user:", error);
        }
    };

    const logoutUser = async (userId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/logout-user/${userId}`);
            setLoggedInUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
        } catch (error) {
            console.error("Failed to log out user:", error);
        }
    };

    return (
        <div className="users-admin">
            <div className="add-new-button">
                <Link to="/admin/users/new-user">Add User</Link>
            </div>

            <h2>All Users</h2>
            <table className="users-table">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Role</th>
                        {auth.user.role === 'siteadmin' && <th>Security Level</th>}
                        <th className="actions-column"></th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers.map((user, index) => (
                        <tr key={index} onMouseEnter={() => setHoveredRow(index)} onMouseLeave={() => setHoveredRow(null)}>
                            <td>{user.username}</td>
                            <td>{user.role}</td>
                            {auth.user.role === 'siteadmin' && <td>{user.security_level}</td>}
                            <td className="actions">
                                {hoveredRow === index && (
                                    <>
                                        {/* Edit button visibility */}
                                        {(auth.user.id === user.id ||
                                            auth.user.role === 'siteadmin' ||
                                            (auth.user.role === 'admin' && ROLE_HIERARCHY[user.role] < ROLE_HIERARCHY[auth.user.role])) && (
                                            <Link to={`/admin/users/edit-user/${user.id}`}>
                                                <button className="edit-user-btn">
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </button>
                                            </Link>
                                        )}
                                        {/* Delete button visibility */}
                                        {auth.user.id !== user.id &&
                                            (auth.user.role === 'siteadmin' ||
                                            (auth.user.role === 'admin' && ROLE_HIERARCHY[user.role] < ROLE_HIERARCHY[auth.user.role])) && (
                                                <button className="delete-user-btn" onClick={() => deleteUser(user.id, user.security_level)}>
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </button>
                                            )}
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {auth.user.role === 'siteadmin' && (
                <>
                    <h2>Currently Logged-In Users</h2>
                    <table className="users-table">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Role</th>
                                <th className="actions-column"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loggedInUsers.map((user, index) => (
                                <tr key={index} onMouseEnter={() => setHoveredRow(index)} onMouseLeave={() => setHoveredRow(null)}>
                                    <td>{user.username}</td>
                                    <td>{user.role}</td>
                                    <td className="actions">
                                        {hoveredRow === index && auth.user.id !== user.id && (
                                            <button className="logout-user-btn" onClick={() => logoutUser(user.id)}>
                                                <i className="fa-solid fa-sign-out-alt"></i>
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};

export default AdminUsers;
