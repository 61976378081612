import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from '../api/axios';

const ContactForm = ({ ready }) => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const recaptchaToken = useRef(null); // Use useRef for reCAPTCHA token
    const [formStatus, setFormStatus] = useState({ success: null, message: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [recaptchaVisible, setRecaptchaVisible] = useState(false); // Controls reCAPTCHA visibility
    const [formReady, setFormReady] = useState(false); // Tracks if the form is ready for submission

    const handleChange = (e) => {
        const updatedFormData = { ...formData, [e.target.id.replace("message-", "")]: e.target.value };
        setFormData(updatedFormData);

        // Set reCAPTCHA visibility if all fields are filled
        const allFieldsFilled = Object.values(updatedFormData).every(field => field.trim() !== '');
        setRecaptchaVisible(allFieldsFilled);
        setFormReady(allFieldsFilled && recaptchaToken.current !== null); // Update form readiness
    };

    const handleRecaptchaChange = (token) => {
        recaptchaToken.current = token;
        // Update form readiness when reCAPTCHA is completed
        const allFieldsFilled = Object.values(formData).every(field => field.trim() !== '');
        setFormReady(allFieldsFilled && token !== null); // Update form readiness
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!recaptchaToken.current) {
            setFormStatus({ success: false, message: 'Please complete the reCAPTCHA.' });
            return;
        }
        setIsSubmitting(true);
        try {
            await axios.post('/api/contact-message', { ...formData, recaptchaToken: recaptchaToken.current });
            setFormStatus({ success: true, message: 'Message sent successfully!' });
            setFormData({ name: '', email: '', message: '' });
            recaptchaToken.current = null; // Reset reCAPTCHA token
            setRecaptchaVisible(false); // Hide reCAPTCHA
            setFormReady(false); // Reset form readiness
        } catch (error) {
            console.error("Submission error:", error);
            setFormStatus({ success: false, message: 'Failed to send message. Please try again.' });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className='contact-form-container'>
            <h2>Send Us A Message</h2>
            <div className='contact-form'>
                <form onSubmit={handleSubmit}>
                    {/* Input Fields */}
                    <div className='input-wrapper'>
                        <label htmlFor="message-name">NAME</label>
                        <input id="message-name" type='text' value={formData.name} onChange={handleChange} required />
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="message-email">EMAIL ADDRESS</label>
                        <input id="message-email" type='email' value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className='input-wrapper'>
                        <label htmlFor="message-message">MESSAGE</label>
                        <textarea id="message-message" value={formData.message} onChange={handleChange} required />
                    </div>

                    {/* Conditional reCAPTCHA */}
                    {recaptchaVisible && ready && (
                        <div className='captcha-wrapper'>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                onChange={handleRecaptchaChange}
                                onErrored={() => setFormStatus({ success: false, message: 'reCAPTCHA failed to load. Please refresh.' })}
                            />
                        </div>
                    )}
                    
                    {/* Submission and Status Messages */}
                    {formStatus.message && (
                        <p className={`form-status ${formStatus.success ? 'success' : 'error'}`}>{formStatus.message}</p>
                    )}
                    <div className='submit-button-wrapper'>
                        <button type="submit" disabled={isSubmitting || !formReady}>
                            {isSubmitting ? 'Sending Message' : 'Send Message'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;
