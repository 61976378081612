import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from '../../api/axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './styles/AdminDailyReadings.css';

function AddEditAnnouncement() {
  const { id } = useParams();
  const navigate = useNavigate();
  const editMode = Boolean(id);

  const [subject, setSubject] = useState('');
  const [announcement, setAnnouncement] = useState('');
  const [expires, setExpires] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Fetch existing data if editing
  useEffect(() => {
    if (editMode) {
      setIsLoading(true);
      axios.get(`/api/announcements/${id}`)
        .then(response => {
          const data = response.data;
          setSubject(data.subject || ''); 
          setAnnouncement(data.announcement);
          setExpires(!!data.expires); // Ensure expires is boolean
          setExpirationDate(data.expiration_date || '');
        })
        .catch(error => console.error("Failed to fetch announcement data:", error))
        .finally(() => setIsLoading(false));
    }
  }, [id, editMode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when starting submission
  
    const formData = {
      subject,
      announcement,
      expires: Boolean(expires),
      expiration_date: expires ? expirationDate : null,
    };
  
    try {
      const url = editMode ? `/api/announcements/${id}` : '/api/announcements';
      const method = editMode ? 'put' : 'post';
      await axios({
        method,
        url,
        data: formData,
        headers: { 'Content-Type': 'application/json' },
      });
      navigate("/admin/announcements");
    } catch (error) {
      console.error("Error submitting announcement:", error.response?.data || error.message);
    } finally {
      setIsLoading(false); // Reset loading state after submission
    }
  };
  

  return (
    <>
      <div className="add-new-button form">
        <Link to="/admin/announcements">Cancel</Link>
      </div>
      <div className="add-edit-wrapper">
        <div className="add-edit-form">
          <h2>{editMode ? "Edit Announcement" : "Create New Announcement"}</h2>
          <form onSubmit={handleSubmit}>

            {/* Subject Field */}
            <div className="admin-input-wrapper">
              <label>Subject</label>
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
                maxLength={100}
              />
            </div>

            <div className="admin-input-wrapper">
              <label>Announcement Content</label>
              <CKEditor
                editor={ClassicEditor}
                data={announcement}
                onChange={(event, editor) => setAnnouncement(editor.getData())}
              />
            </div>

            <div className="admin-input-wrapper">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={expires}
                  onChange={() => setExpires(!expires)}
                />
                <label className="checkbox-label">Expires</label>
              </div>
              {expires && (
                <input
                  type="date"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                  required
                />
              )}
            </div>

            <button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddEditAnnouncement;
