import React from 'react';
import './styles/ChildcareFees.css';

const ChildcareFees = () => {
    return (
        <section className='staff-section-wrapper fees' aria-labelledby="available-programs">
            <div className='section-header-wrapper'>
                <h2 id="available-programs" style={{ margin: 0 }}>Available Programs</h2>
                <p style={{ marginTop: '0.5rem' }}>The following programs are offered through Cross Roads UMC Childcare.</p>
                <p>(A non-refundable $25 registration fee is required.)</p>
            </div>
            <div className='staff-row-wrapper'>
                {[
                    { age: "<2", description: "(6 weeks - 24 months)", price: "$145" },
                    { age: "2y", description: "(2-year-old class)", price: "$135" },
                    { age: "3y", description: "(3-year-old class)", price: "$125" },
                    { age: "4y", description: "(4-year-old class)", price: "$115" }
                ].map((program, index) => (
                    <div key={index} className='offered-program-wrapper'>
                        <h3>{program.age}</h3>
                        <p style={{ margin: 0 }}>{program.description}</p>
                        <p><b>{program.price}</b> per week</p>

                    </div>
                ))}
            </div>
    
            <div className='section-row-separator'>
                <hr />
            </div>
    
            <div className='staff-row-wrapper'>
                <div className='offered-program-wrapper'>
                    <h3>Pre-K</h3>
                    <p style={{ margin: 0 }}><b>$105</b> per week</p>
                    <p style={{ marginTop: 0 }}>(During Pre-K School Year)</p>
                </div>
                <div className='offered-program-wrapper'>
                    <h3>School-Age</h3>
                    <p style={{ margin: 0, textDecoration: 'underline' }}><b>During School Year</b></p>
                    <p style={{margin: 0}}><b>$70</b> per week</p>
                    <p style={{ marginTop: 0 }}>(For children attending Explorer Academy and Hite Saunders Elementary)</p>
                    <p style={{ margin: 0, textDecoration: 'underline' }}><b>During Summer</b></p>
                    <p style={{ margin: 0 }}><b>$150</b> per week
                        <br />
                        <i><b>$120</b> per additional child in the family</i>
                    </p>
                    <br />

                </div>

            </div>
        </section>
    );
    
};

export default ChildcareFees;