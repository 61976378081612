import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from '../../api/axios';
import './styles/AdminChristmasChild.css';

function AddEditChurchStaff() {
  const { id } = useParams();
  const navigate = useNavigate();
  const editMode = Boolean(id);
  const baseURL = process.env.REACT_APP_API_URL;
  const [isSubmitting, setIsSubmitting] = useState(false);


  const [name, setName] = useState('');
  const [roleTitle, setRoleTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [existingImageFileName, setExistingImageFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const [includeEmail, setIncludeEmail] = useState(true);
  const [includePhone, setIncludePhone] = useState(true);
  const [includeImage, setIncludeImage] = useState(true);

  useEffect(() => {
    if (editMode) {
      setIsLoading(true);
      axios.get(`/api/church-staff/${id}`)
        .then(response => {
          const data = response.data;
          setName(data.name || '');
          setRoleTitle(data.role_title || '');
          setEmail(data.email || "none");
          setPhone(data.phone || "none");
          setExistingImageFileName(data.image || "");
          setIncludeEmail(data.email !== "none");
          setIncludePhone(data.phone !== "none");
          setIncludeImage(!!data.image);
        })
        .catch(error => console.error("Failed to fetch church staff data:", error))
        .finally(() => setIsLoading(false));
    }
  }, [id, editMode]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 8 * 1024 * 1024) {
        alert("File size should be under 8MB.");
        return;
      }
      if (!selectedFile.type.startsWith("image/")) {
        alert("Only image files are allowed.");
        return;
      }
      setImageFile(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Start loading
    setIsLoading(true);
  
    const sanitizedPhone = phone.replace(/\D/g, ''); // Remove non-digit characters
    const formData = new FormData();
    formData.append('name', name);
    formData.append('role_title', roleTitle);
    formData.append('email', includeEmail ? email : "none");
    formData.append('phone', includePhone ? sanitizedPhone : "none");
  
    // Append the image file if selected
    if (includeImage && imageFile) {
      formData.append('image', imageFile);
    } else if (!includeImage) {
      formData.append('image', "none");
    }
  
    try {
      const url = editMode ? `/api/church-staff/${id}` : '/api/church-staff';
      const method = editMode ? 'put' : 'post';
      await axios({
        method,
        url,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      // Navigate or show success message
      navigate("/admin/church-staff");
    } catch (error) {
      console.error("Error submitting form:", error.response?.data || error.message);
    } finally {
      // Stop loading
      setIsLoading(false);
    }
  };
  



  return (
    <>
      <div className="add-new-button form">
        <Link to="/admin/church-staff">Cancel</Link>
      </div>
      <div className="add-edit-wrapper">
        <div className="add-edit-form">
          <h2>{editMode ? "Edit Church Staff" : "Add New Church Staff"}</h2>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="admin-input-wrapper">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="role-title">Role/Title</label>
              <input
                type="text"
                id="role-title"
                value={roleTitle}
                onChange={(e) => setRoleTitle(e.target.value)}
                required
              />
            </div>

            <div className="admin-input-wrapper">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={includeEmail}
                  onChange={() => setIncludeEmail(!includeEmail)}
                />
                <label>Email</label>
              </div>
              {includeEmail && (
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              )}
            </div>

            <div className="admin-input-wrapper">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={includePhone}
                  onChange={() => setIncludePhone(!includePhone)}
                />
                <label>Phone</label>
              </div>
              {includePhone && (
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              )}
            </div>

            <div className="admin-input-wrapper">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={includeImage}
                  onChange={() => setIncludeImage(!includeImage)}
                />
                <label>Upload Image</label>
              </div>
              {includeImage && (
                <div className="file-input-preview-wrapper">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  {editMode && existingImageFileName && !imageFile && (
                    <div className="existing-image-preview">
                      <img src={`${baseURL}/uploads/church-staff/${existingImageFileName}`} alt="Existing" />
                    </div>
                  )}
                </div>
              )}
            </div>

            <button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddEditChurchStaff;
