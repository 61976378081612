import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from './../api/axios';
import './styles/DailyReadings.css';

const DailyReadings = () => {
    const [readings, setReadings] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchReadings = async () => {
            try {
                const response = await axios.get('/api/daily-readings');
                setReadings(response.data);
            } catch (error) {
                console.error("Error fetching daily readings:", error);
                setError("Could not load daily readings. Please try again later.");
            }
        };

        fetchReadings();
    }, []);

    const formatDate = (dateString) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    // Ensure todayDate is in 'YYYY-MM-DD' format
    const todayDate = new Date().toISOString().split('T')[0];

    // Calculate yesterday's date
    const yesterday = new Date(todayDate);
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayDate = yesterday.toISOString().split('T')[0];

    // Filter out future readings
    const filteredReadings = readings.filter(reading => {
        const readingDate = new Date(reading.reading_date).toISOString().split('T')[0];
        return readingDate <= todayDate; // Include only readings up to today
    });

    const displayedReadings = filteredReadings.slice(0, 3); // Limit to the most recent 3

    // Find the next closest future reading
    const futureReadings = readings.filter(reading => new Date(reading.reading_date) > new Date());
    const sortedFutureReadings = futureReadings.sort((a, b) => new Date(a.reading_date) - new Date(b.reading_date));
    const nextUpcomingReading = sortedFutureReadings.length > 0 ? sortedFutureReadings[0] : null;

    return (
        <div className='hero-content-wrapper'>
            <div className='hero-content'>
                <div className='hero-description' aria-labelledby="daily-readings-title">
                    <h2 id="daily-readings-title">Daily Bible Readings</h2>
                    {error ? (
                        <p className="error" role="alert">{error}</p>
                    ) : (
                        <div className='bible-readings-wrapper'>
                            {readings.length === 0 ? (
                                // No readings available
                                <p>No readings at this time. Please check back later.</p>
                            ) : displayedReadings.length === 0 && nextUpcomingReading ? (
                                // Show the next upcoming reading if no past readings exist
                                <div className="bible-reading-wrapper upcoming">
                                    <h3>Upcoming</h3>
                                    <div className="bible-reading">
                                        <h4>{nextUpcomingReading.scripture}</h4>
                                        <h5>{formatDate(nextUpcomingReading.reading_date)}</h5>
                                    </div>
                                </div>
                            ) : (
                                displayedReadings.map((reading, index) => {
                                    // Parse reading_date to match 'YYYY-MM-DD' format
                                    const readingDate = new Date(reading.reading_date).toISOString().split('T')[0];

                                    return (
                                        <div
                                            key={reading.id}
                                            className={`bible-reading-wrapper ${index === 0 ? 'today' : ''}`}
                                            aria-label={index === 0 ? "Today's reading" : `Reading for ${formatDate(reading.reading_date)}`}
                                        >
                                            {index === 0 && (
                                                <h3>
                                                    {readingDate === todayDate
                                                        ? 'Today'
                                                        : readingDate === yesterdayDate
                                                        ? 'Yesterday'
                                                        : 'Most Recent'}
                                                </h3>
                                            )}
                                            <div className='bible-reading'>
                                                <h4>{reading.scripture}</h4>
                                                <h5>{formatDate(reading.reading_date)}</h5>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    )}
                </div>
                <Link 
                    to='/daily-readings' 
                    className='view-archive' 
                    aria-label="View past readings archive"
                >
                    View Past Readings Archive <i className="fa-solid fa-arrow-right" aria-hidden="true"></i>
                </Link>
            </div>
        </div>
    );
};

export default DailyReadings;
