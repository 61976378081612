import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from './../api/axios';
import './styles/PastSessions.css';
import { Helmet } from 'react-helmet-async';

const PastSessions = () => {
    const [sessions, setSessions] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSessions = async () => {
            try {
                const response = await axios.get('/api/past-services');
                setSessions(response.data);
            } catch (err) {
                console.error("Error fetching past sessions:", err);
                setError("Failed to load past sessions.");
            }
        };
        fetchSessions();
    }, []);

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "PlaceOfWorship",
        "name": "Cross Roads United Methodist Church",
        "url": "https://crossroadswv.com",
        "logo": "%PUBLIC_URL%/logo512.png",
        "sameAs": ["https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"],
        "contactPoint": [
            {
                "@type": "ContactPoint",
                "telephone": "+1-304-522-7434",
                "contactType": "Church Support",
                "areaServed": "US",
                "email": "crossroadsumc@comcast.net"
            },
            {
                "@type": "ContactPoint",
                "telephone": "+1-304-525-6547",
                "contactType": "Childcare Services",
                "areaServed": "US",
                "email": "crccm@comcast.net",
                "description": "Contact Cross Roads Daycare and Preschool for enrollment and program details."
            }
        ],
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "3146 Saltwell Rd",
            "addressLocality": "Huntington",
            "addressRegion": "WV",
            "postalCode": "25705",
            "addressCountry": "US"
        },
        "hasMap": "https://www.google.com/maps/place/Crossroads+UMC/@38.4024952,-82.3881641,15z/data=!4m2!3m1!1s0x0:0x4b99b1521962cec5?sa=X&ved=1t:2428&ictx=111",
        "description": "Join us at Cross Roads United Methodist Church, a welcoming community of faith in Huntington offering worship, outreach, and daily readings.",
        "foundingDate": "1892",
        "keywords": ["church", "faith community", "worship", "United Methodist Church", "daycare", "preschool"],
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": "38.4024952",
            "longitude": "-82.3881641"
        },
        "department": [
            {
                "@type": "Organization",
                "name": "Cross Roads Daycare and Preschool",
                "sameAs": "https://crossroadswv.com/childcare-preschool",
                "description": "Our daycare and preschool program provides a nurturing environment for young children to explore, learn, and grow within a community of care.",
                "serviceType": "Preschool and Childcare Services"
            }
        ],
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://crossroadswv.com/past-services"
        },
    };

        // Add itemListElement only if sessions are loaded
        if (sessions.length > 0) {
            structuredData.itemListElement = sessions.map((session, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "url": `https://crossroadswv.com/past-session/${session.id}`,
                "name": `${session.service_type} on ${new Date(session.service_date).toLocaleDateString()}`,
                "description": session.description || "Past service at Cross Roads United Methodist Church"
            }));
        }

    return (
        <>
            <Helmet>
                <title>Past Services - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Explore past worship services at Cross Roads United Methodist Church, including sermon details and service types."
                />
                <meta property="og:title" content="Past Services - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Catch up on past worship services at Cross Roads United Methodist Church. View details and rewatch sermons."
                />
                <meta property="og:url" content="https://crossroadswv.com/past-services" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/past-services" />

                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            
            <div className='page-wrapper past-sessions'>
                <div className='page-title'>
                    <h1>PAST SERVICES</h1>
                    <hr/>
                </div>
        
                {error ? (
                    <p className="error-message">{error}</p>
                ) : (
                    <section >
                        <div className='past-worship-sessions-wrapper'>

                        
                        {sessions.length > 0 ? (
                            sessions.map((session) => (
                                <Link to={`/past-session/${session.id}`} key={session.id} className='past-session-wrapper'>
                                    <div className='past-session'>
                                        <h3>{new Date(session.service_date).toLocaleDateString()}</h3>
                                        <h4>{session.service_type}</h4>
                                        <h5>{session.description}</h5>
                                        <div className='go-past-session' aria-label={`Go to details for ${session.service_type} on ${new Date(session.service_date).toLocaleDateString()}`}>
                                            GO <i className="fa-solid fa-arrow-right"></i>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <p className="no-sessions-message">No services to display</p>
                        )}
                        </div>
                    </section>
                )}
            </div>
        </>
    );  
}

export default PastSessions;
