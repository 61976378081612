import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import axios from '../../api/axios';
import './styles/AdminChristmasChild.css';

function AddEditUser() {
    const { id } = useParams();
    const navigate = useNavigate();
    const isEdit = Boolean(id);
    const { auth } = useAuth();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const formTitle = isEdit ? "Edit User" : "Add New User";

    const roleOptions = auth.user.role === 'siteadmin'
        ? ["admin", "user", "church", "childcare"]
        : ["user", "church", "childcare"];
    

    useEffect(() => {
        if (isEdit) {
            setIsLoading(true);
            axios.get(`/api/get-user/${encodeURIComponent(id)}`)
                .then(response => {
                    const data = response.data;
                    setUsername(data.username);
                    setRole(data.role);
                })
                .catch(error => console.error("Error fetching user:", error))
                .finally(() => setIsLoading(false));
        }
    }, [id, isEdit]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        const userData = { username, role };
        if (password) userData.password = password;

        if (auth.user.role === 'admin' && role === 'siteadmin') {
            alert("Admins cannot assign the siteadmin role. Only siteadmins can do this.");
            return;
        }

        const url = isEdit ? `/api/edit-user/${encodeURIComponent(id)}` : '/api/add-user';
        const method = isEdit ? 'put' : 'post';

        try {
            setIsLoading(true);
            await axios({
                method,
                url,
                data: userData,
                headers: { 'Content-Type': 'application/json' },
            });
            navigate("/admin/users");
        } catch (error) {
            console.error("Error submitting form:", error.response?.data || error.message);
            setError(error.response?.data?.error || "An unexpected error occurred. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="add-new-button form">
                <Link to="/admin/users">Cancel</Link>
            </div>
            <div className="add-edit-wrapper">
                <div className="add-edit-form">
                    <h2>{formTitle}</h2>
                    {error && <p className="error-message">{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <div className="admin-input-wrapper">
                            <label htmlFor="username">Username</label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter username"
                                required
                            />
                        </div>

                        <div className="admin-input-wrapper">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={isEdit ? "Enter new password (optional)" : "Enter password"}
                                required={!isEdit}
                            />
                        </div>

                        <div className="admin-input-wrapper">
                            <label htmlFor="role">Role</label>
                            <select
                                id="role"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select role</option>
                                {roleOptions.map((option) => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>

                        <button type="submit" disabled={isLoading}>
                            {isLoading ? "Saving..." : isEdit ? 'Update' : 'Create'}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default AddEditUser;
