import React, { useState, useEffect } from "react";
import axios from './../../api/axios';
import './styles/AdminMessages.css';

const AdminMessages = () => {
    const [messages, setMessages] = useState([]);
    const [prayerRequests, setPrayerRequests] = useState([]);
    const [directoryRequests, setDirectoryRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [expandedRow, setExpandedRow] = useState(null);

    useEffect(() => {
        fetchMessages();
        fetchPrayerRequests();
        fetchDirectoryRequests();
    }, []);

    const fetchMessages = async () => {
        try {
            const response = await axios.get('/api/get-contact-messages');
            setMessages(response.data);
        } catch (error) {
            console.error('Error fetching contact messages:', error);
        }
    };

    const fetchPrayerRequests = async () => {
        try {
            const response = await axios.get('/api/get-prayer-requests');
            setPrayerRequests(response.data);
        } catch (error) {
            console.error('Error fetching prayer requests:', error);
        }
    };

    const fetchDirectoryRequests = async () => {
        try {
            const response = await axios.get('/api/get-directory-requests');
            setDirectoryRequests(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching directory requests:', error);
            setLoading(false);
        }
    };

    const combinedMessages = [
        ...messages.map(msg => ({ type: 'Contact Message', name: msg.name, submitTime: msg.submitTime, content: msg.message, id: msg.id })),
        ...prayerRequests.map(req => ({ type: 'Prayer Request', name: req.name, submitTime: req.submitTime, content: req.message, id: req.id })),
        ...directoryRequests.map(req => ({
            type: 'Directory Request',
            name: `${req.firstName} ${req.lastName}`,
            submitTime: req.submitTime,
            fields: {
                firstName: req.firstName,
                lastName: req.lastName,
                address: req.address,
                city: req.city,
                zip: req.zip,
                birthday: req.birthday
            },
            id: req.id
        }))
    ].sort((a, b) => new Date(b.submitTime) - new Date(a.submitTime));

    const handleViewDetailsClick = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const handleDelete = async (id, type) => {
        try {
            // Make a DELETE request depending on the type of message
            await axios.delete(`/api/delete-${type.toLowerCase().replace(" ", "-")}/${id}`);
            
            // Update the list in the UI after deletion
            if (type === "Contact Message") {
                setMessages(messages.filter(msg => msg.id !== id));
            } else if (type === "Prayer Request") {
                setPrayerRequests(prayerRequests.filter(req => req.id !== id));
            } else if (type === "Directory Request") {
                setDirectoryRequests(directoryRequests.filter(req => req.id !== id));
            }
        } catch (error) {
            console.error(`Error deleting ${type.toLowerCase()}:`, error);
        }
    };

    return (
        <div className="admin-page">
            <h2>All Submitted Messages</h2>
            {loading ? (
                <p>Loading messages...</p>
            ) : (
                <table className="admin-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Submitted Time</th>
                            <th className="actions-column">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {combinedMessages.length > 0 ? (
                            combinedMessages.map((entry, index) => (
                                <React.Fragment key={index}>
                                    <tr
                                        onMouseEnter={() => setHoveredRow(index)}
                                        onMouseLeave={() => setHoveredRow(null)}
                                    >
                                        <td>{entry.name}</td>
                                        <td>{entry.type}</td>
                                        <td>{new Date(entry.submitTime).toLocaleString()}</td>
                                        <td className="actions">
                                            {hoveredRow === index && (
                                                <button
                                                className="view-details-btn"
                                                onClick={() => handleViewDetailsClick(index)}
                                                >
                                                {expandedRow === index ? (
                                                    <>
                                                    <i className="fa-solid fa-eye-slash" aria-hidden="true"></i> Hide Details
                                                    </>
                                                ) : (
                                                    <>
                                                    <i className="fa-solid fa-eye" aria-hidden="true"></i> View Details
                                                    </>
                                                )}
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                    {expandedRow === index && (
                                        <tr className={`details-row ${expandedRow === index ? 'expanded' : ''}`}>
                                            <td colSpan="4">
                                                <div className="message-content-wrapper">
                                                    <p className="message-header">
                                                        New {entry.type} from {entry.type === "Directory Request" ? entry.fields.firstName : entry.name}
                                                    </p>
                                                    {entry.type === "Directory Request" ? (
                                                        <div className="directory-fields">
                                                            <p><strong>First Name:</strong> {entry.fields.firstName}</p>
                                                            <p><strong>Last Name:</strong> {entry.fields.lastName}</p>
                                                            <p><strong>Address:</strong> {entry.fields.address}</p>
                                                            <p><strong>City:</strong> {entry.fields.city}</p>
                                                            <p><strong>Zip:</strong> {entry.fields.zip}</p>
                                                            <p><strong>Birthday:</strong> {entry.fields.birthday}</p>
                                                        </div>
                                                    ) : (
                                                        <p>{entry.content}</p>
                                                    )}
                                                </div>
                                                <button
                                                    className="delete-button"
                                                    onClick={() => handleDelete(entry.id, entry.type)}
                                                >
                                                    <i className="fa-solid fa-trash-can"></i> Delete {entry.type}
                                                </button>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">No messages found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AdminMessages;
