import React from 'react';
import './styles/Baptism.css';
import { Helmet } from 'react-helmet-async';

import GBH1 from './../assets/images/Gbh1.jpg';
import GBH2 from './../assets/images/Gbh2.jpg';

const GBH = () => {

    return (
        <>
            <Helmet>
                <title>God's Big House - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="God's Big House is a ministry for children K–5th grade at Cross Roads United Methodist Church that helps kids grow in their faith through lessons and activities."
                />
                <meta property="og:title" content="God's Big House - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="God's Big House is a ministry for children at Cross Roads United Methodist Church, designed to help kids in grades K–5 grow spiritually through engaging lessons and activities."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/gods-big-house" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="%PUBLIC_URL%/recovery.jpg" />
                <link rel="canonical" href="https://www.crossroadswv.com/gods-big-house" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "name": "God's Big House",
                        "description": "A Sunday ministry for children in K–5th grade to grow spiritually through fun, age-appropriate lessons and activities at Cross Roads United Methodist Church.",
                        "url": "https://www.crossroadswv.com/gods-big-house",
                        "location": {
                            "@type": "Place",
                            "name": "Cross Roads United Methodist Church",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "3146 Saltwell Rd",
                                "addressLocality": "Huntington",
                                "addressRegion": "WV",
                                "postalCode": "25705",
                                "addressCountry": "US"
                            }
                        },
                        "image": "%PUBLIC_URL%/recovery.jpg",
                        "organizer": {
                            "@type": "Organization",
                            "name": "Cross Roads United Methodist Church",
                            "url": "https://www.crossroadswv.com"
                        },
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ]
                    })}
                </script>
            </Helmet>


            <div className='page-wrapper first-responders'>
                <div className='page-title'>
                    <h1>GOD'S BIG HOUSE</h1>
                    <hr/>
                </div>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>About God's Big House</h2>
                            <div className='text-content'>
                                <p>God's Big House is a ministry to children K–5th grade every Sunday from 10:15–10:45AM that helps children grow in their faith through lessons and activities that are age-appropriate and supportive, while also having fun and building friendships.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={GBH1} alt="A brightly colored children's room with a table set for activity, featuring six chairs and vibrant placemats. The walls are decorated with tropical-themed artwork, and a window provides natural light." />
                        </div>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper' style={{alignItems: 'center'}}>
                        <div className='content-text-wrapper'>
                            <h2>Join Us on Sundays</h2>
                            <div className='text-content'>
                                <p>God's Big House is held upstairs <b>every Sunday at 10:15–10:45AM</b>. All kids grades <b>K–5th</b> are welcome!</p>
                                <p>If you would like to learn more or have any questions, please contact us at <a href="mailto:crossroadsumc@comcast.net">crossroadsumc@comcast.net</a> or call us at <a href="tel:304-522-7434">(304)-522-7434</a>.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={GBH2} alt=" young girl with a playful expression, sitting on a chair wearing a colorful dress, with a plastic cap covered in whipped cream and orange snack balls as part of a fun activity during a children's event." />
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default GBH;