
import React from 'react';
import './styles/Worship.css'
import { Helmet } from 'react-helmet-async';

import image from './../assets/images/entrance1.webp';

const Worship = () => {


    return (
        <>
            <Helmet>
                <title>Worship Information - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Join us for worship services at Cross Roads United Methodist Church. We offer Sunday services with childcare and Wednesday programs for children and youth."
                />
                <meta property="og:title" content="Worship Information - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Worship with us on Sundays and Wednesdays. Sunday worship includes a nursery, and Wednesday programs engage children and youth with faith and fellowship."
                />
                <meta property="og:url" content="https://crossroadswv.com/worship" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/worship" />
                

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "name": "Sunday and Wednesday Worship Services",
                        "location": {
                            "@type": "Place",
                            "name": "Cross Roads United Methodist Church",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "3146 Saltwell Rd",
                                "addressLocality": "Huntington",
                                "addressRegion": "WV",
                                "postalCode": "25705",
                                "addressCountry": "US"
                            },
                            "hasMap": "https://www.google.com/maps/place/Crossroads+UMC/@38.4024952,-82.3881641,15z/data=!4m2!3m1!1s0x0:0x4b99b1521962cec5?sa=X&ved=1t:2428&ictx=111"
                        },
                        "description": "Join Cross Roads United Methodist Church for Sunday and Wednesday worship services. Sunday includes Sunday School, morning worship, and a nursery. Wednesdays offer programs for children and youth.",
                        "foundingDate": "1892",
                        "eventSchedule": [
                            {
                                "@type": "Schedule",
                                "name": "Sunday Worship Service",
                                "repeatFrequency": "P1W", // Every week
                                "byDay": "SU", // Sunday
                                "eventAttendanceMode": "MixedEventAttendanceMode",
                                "location": {
                                    "@type": "Place",
                                    "name": "Cross Roads United Methodist Church",
                                    "address": "3146 Saltwell Rd, Huntington, WV 25705"
                                },
                                "eventStatus": "https://schema.org/EventScheduled",
                                "offers": {
                                    "@type": "Offer",
                                    "price": "0",
                                    "priceCurrency": "USD"
                                },
                                "subEvent": [
                                    {
                                        "@type": "Event",
                                        "name": "Sunday School",
                                        "startTime": "11:15",
                                        "endTime": "12:00",
                                        "audience": {
                                            "@type": "Audience",
                                            "name": "All Ages"
                                        }
                                    },
                                    {
                                        "@type": "Event",
                                        "name": "Sunday Worship",
                                        "startTime": "10:00",
                                        "endTime": "11:15",
                                        "audience": {
                                            "@type": "Audience",
                                            "name": "All Ages"
                                        },
                                        "offers": {
                                            "@type": "Offer",
                                            "name": "Nursery Available",
                                            "description": "Nursery for children from birth to 2 years during Sunday worship.",
                                            "price": "0",
                                            "priceCurrency": "USD"
                                        }
                                    },
                                    {
                                        "@type": "Event",
                                        "name": "God's Big House (K – 5th grade)",
                                        "startTime": "10:15",
                                        "endTime": "10:45",
                                        "audience": {
                                            "@type": "Audience",
                                            "name": "Kindergarten to 5th Grade"
                                        }
                                    }
                                ]
                            },
                            {
                                "@type": "Schedule",
                                "name": "Wednesday Programs",
                                "repeatFrequency": "P1W", // Every week
                                "byDay": "WE", // Wednesday
                                "eventAttendanceMode": "OfflineEventAttendanceMode",
                                "location": {
                                    "@type": "Place",
                                    "name": "Cross Roads United Methodist Church",
                                    "address": "3146 Saltwell Rd, Huntington, WV 25705"
                                },
                                "eventStatus": "https://schema.org/EventScheduled",
                                "subEvent": [
                                    {
                                        "@type": "Event",
                                        "name": "Children's Program",
                                        "startTime": "18:30",
                                        "endTime": "19:30",
                                        "audience": {
                                            "@type": "Audience",
                                            "name": "Preschool to 5th Grade"
                                        },
                                        "description": "Children’s program with music, Bible study, and activities for preschool through 5th grade."
                                    },
                                    {
                                        "@type": "Event",
                                        "name": "Youth Program",
                                        "startTime": "18:30",
                                        "endTime": "19:30",
                                        "audience": {
                                            "@type": "Audience",
                                            "name": "6th to 12th Grade"
                                        },
                                        "description": "Youth fellowship program for grades 6-12, including music, small groups, and worship."
                                    }
                                ]
                            }
                        ],
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://crossroadswv.com/worship"
                        }
                    })}
                </script>
            </Helmet>



            <div className='page-wrapper worship'>
                <div className='page-title'>
                    <h1>WORSHIP INFO</h1>
                    <hr/>
                </div>
                <section className='page-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='section-text-content'>
                            {/*
                            <div className='section-text-block'>
                                <p>You have many choices when it comes to places to worship, connect, grow, and serve Jesus Christ.</p>
                                <p>So we are honored that you are exploring Forrest Burdette.</p>
                                <p>We sincerely hope that you will consider visiting us for worship on Sunday or for one of the many weekday programs offered for children, youth, and adults.</p>
                            </div>
                            */}



                            <h2>Sunday Services</h2>
                            <div className='info-text-wrapper'>
                                <h3>Times</h3>
                                <p style={{marginTop: 0}}>Our Sunday Morning Schedule is as follows:</p>
                                <p><b>Sunday School:</b> 11:15 AM – 12:00 PM NOON</p>
                                <p><b>Sunday Worship:</b> 10:00 AM – 11:15 AM (In Person + Online)</p>

                                <h3>Child Care Available</h3>
                                <p style={{marginTop: 0}}>We have a Nursery for babies and toddlers from birth to 2 years old. Nursery is provided during Sunday Worship and Sunday School, and Wednesdays nights during our adult classes.</p>
                                
                                
                                <p><b>God's Big House</b> (K – 5th grade): 10:15 AM – 10:45 AM </p>
                            </div>

                            <div className='text-separator'>
                                <hr/>
                            </div>

                            <h2>Wednesday Services</h2>
                            <div className='info-text-wrapper'>
                                <p style={{marginTop: 0}}>We meet on Wednesdays from <b>6:00 - 6:30 PM for dinner</b>. At 6:30 we break for bible study and individual groups.</p>
                                <p><b>Adult Bible Study:</b> 6:30 PM - 7:30 PM</p>
                                <h3>Children</h3>
                                <p style={{marginTop: 0}}>Children meet on Wednesdays for fun, music, and growing. Children learn how the Bible can help them every day, and learn to praise God through music.</p>
                                <p><b>TIME:</b> 6:30 PM – 7:30 PM</p>
                                <p><b>GRADE:</b> Preschool (Ages 2+), K – 5th grade.</p>
                                <h3 style={{marginTop: '2rem'}}>Youth</h3>
                                <p style={{marginTop: 0}}>Youth meets on Wednesdays to hang out, make friends, & play games. We worship God through music, God’s word, and through small groups.</p>
                                <p><b>TIME:</b> 6:30 PM – 7:30 PM</p>
                                <p><b>GRADES:</b> 6th – 12th grade</p>
                            </div>

                        </div>
                        
                        <div className='section-image-column'>
                            <img src={image} alt="Entrance to sanctuary of Cross Roads United Methodist Church" className="membership-image"/>
                        </div>

                    </div>
                </section>
            </div>
        </>
    );
}

export default Worship;
