
import React from 'react';
import './styles/Ministries.css';
import { Helmet } from 'react-helmet-async';


import occ from './../assets/images/operationchristmaschild2.webp';
import cards from './../assets/images/valentinesCards.webp';
import missionTrips from './../assets/images/Carpenter1.webp';
import commonGrounds from './../assets/images/commonGrounds0.webp';
import holidays from './../assets/images/santa.webp';
import VBS from './../assets/images/vbs.webp';
import scouts from './../assets/images/scouts.webp';
import prayersSquares from './../assets/images/prayersSquares.webp';
import uwFaith from './../assets/images/uwfaithLogo.webp';
import septemberClub2 from './../assets/images/septemberClub2.webp';
import wnk from './../assets/images/wnk2.jpg';
import recovery from './../assets/images/recovery.jpg'
import GBH from './../assets/images/Gbh1.jpg'
import wesley from './../assets/images/wesley1.jpg'

import { Link } from 'react-router-dom';

const Ministries = () => {


    return (
        <>
            <Helmet>
                <title>Ministries and Outreach - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Explore various ministries and outreach programs at Cross Roads United Methodist Church, dedicated to serving our community and spreading the love of Christ."
                />
                <meta property="og:title" content="Ministries and Outreach - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Discover our ministries and outreach programs at Cross Roads United Methodist Church, including Operation Christmas Child, Mission Trips, and more."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/ministries-outreach" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/ministries-outreach" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "PlaceOfWorship",
                        "name": "Cross Roads United Methodist Church",
                        "url": "https://www.crossroadswv.com",
                        "logo": "%PUBLIC_URL%/logo512.png",
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ],
                        "contactPoint": [
                            {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-522-7434",
                                "contactType": "Church Support",
                                "areaServed": "US",
                                "availableLanguage": "English",
                                "email": "crossroadsumc@comcast.net"
                            },
                            {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-525-6547",
                                "contactType": "Childcare Services",
                                "areaServed": "US",
                                "availableLanguage": "English",
                                "description": "Contact Cross Roads Daycare and Preschool for enrollment and program details.",
                                "email": "crccm@comcast.net"
                            }
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "3146 Saltwell Rd",
                            "addressLocality": "Huntington",
                            "addressRegion": "WV",
                            "postalCode": "25705",
                            "addressCountry": "US"
                        },
                        "hasMap": "https://www.google.com/maps/place/Crossroads+UMC/@38.4024952,-82.3881641,15z/data=!4m2!3m1!1s0x0:0x4b99b1521962cec5?sa=X&ved=1t:2428&ictx=111",
                        "description": "Explore the ministries and outreach programs of Cross Roads United Methodist Church, designed to serve the community and share Christ's love.",
                        "foundingDate": "1892",
                        "keywords": [
                            "church",
                            "faith community",
                            "worship",
                            "ministries",
                            "outreach programs",
                            "Christian community"
                        ],
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "38.4024952",
                            "longitude": "-82.3881641"
                        },
                        "department": [
                            {
                                "@type": "Organization",
                                "name": "Operation Christmas Child",
                                "description": "Operation Christmas Child delivers shoeboxes filled with essentials and toys to children in need worldwide, sharing hope and the Gospel.",
                                "url": "https://www.crossroadswv.com/operation-christmas-child"
                            },
                            {
                                "@type": "Organization",
                                "name": "First Responders Support",
                                "description": "Our church supports local first responders and educators by delivering treats, gift bags, and handmade cards to show gratitude.",
                                "url": "https://www.crossroadswv.com/first-responders"
                            },
                            {
                                "@type": "Organization",
                                "name": "Mission Trips",
                                "description": "Our church organizes mission trips to serve communities, build connections, and share Christ’s love locally and abroad.",
                                "url": "https://www.crossroadswv.com/mission-trips"
                            },
                            {
                                "@type": "Organization",
                                "name": "Common Grounds",
                                "description": "Common Grounds is a program that provides meals for those in need, creating a space for connection and community support.",
                                "url": "https://www.crossroadswv.com/common-grounds"
                            },
                            {
                                "@type": "Organization",
                                "name": "Cub and Boy Scouts",
                                "description": "We charter Boy Scout Troop 25 and Cub Scout Pack 25, offering great fun, leadership, and learning opportunities for all ages.",
                                "url": "https://www.crossroadswv.com/cub-boy-scouts"
                            },
                            {
                                "@type": "Organization",
                                "name": "Holidays Ministry",
                                "description": "We celebrate major holidays with festive gatherings, including Christmas events, Easter egg hunts, and Halloween Trunk or Treat.",
                                "url": "https://www.crossroadswv.com/holidays"
                            },
                            {
                                "@type": "Organization",
                                "name": "Vacation Bible School",
                                "description": "Vacation Bible School offers children a week of learning, laughter, and fellowship, teaching them about the Bible and God's love.",
                                "url": "https://www.crossroadswv.com/vacation-bible-school"
                            },
                            {
                                "@type": "Organization",
                                "name": "Prayers and Squares",
                                "description": "Prayers & Squares combines the gift of prayer with the gift of a hand-tied quilt, promoting prayer through the use of quilts.",
                                "url": "https://www.prayerquilt.org/about-us",
                                "sameAs": "https://www.prayerquilt.org/about-us"
                            },
                            {
                                "@type": "Organization",
                                "name": "United Women in Faith",
                                "description": "United Women in Faith nurtures women through Christian spiritual formation, leadership, fellowship, and education.",
                                "url": "https://nineriverswvumc.org/united-women-in-faith/",
                                "sameAs": "https://nineriverswvumc.org/united-women-in-faith/"
                            },
                            {
                                "@type": "Organization",
                                "name": "30 Hour Famine",
                                "description": "The 30 Hour Famine is a youth retreat involving Bible studies, games, and inspirational videos, to raise money for the hungry.",
                                "url": "https://30hourfamine.worldvision.org/",
                                "sameAs": "https://30hourfamine.worldvision.org/"
                            },
                            {
                                "@type": "Organization",
                                "name": "September Club",
                                "description": "September Club meets at noon on the first Wednesday of each month for lunch and fellowship.",
                                "url": "https://www.crossroadswv.com/september-club"
                            },
                            {
                                "@type": "Organization",
                                "name": "Grief Support Group",
                                "description": "The Grief Support Group offers a safe and compassionate space for those who have experienced loss. We help one another heal and navigate the challenges of grief.",
                                "url": "https://www.crossroadswv.com/grief-support"
                            },
                            {
                                "@type": "Organization",
                                "name": "Recovery Alive",
                                "description": "Recovery Alive is a church-based program that connects the broken to the POWER, PEOPLE, and PROCESS of the Christ-centered 12 steps.",
                                "url": "https://www.crossroadswv.com/recovery-alive"
                            },
                            {
                                "@type": "Organization",
                                "name": "God's Big House",
                                "description": "God's Big House is a ministry to children K–5th grade every Sunday from 10:15–10:45AM that helps children grow in their faith.",
                                "url": "https://www.crossroadswv.com/gods-big-house"
                            },
                            {
                                "@type": "Organization",
                                "name": "Marshall Wesley",
                                "description": "Marshall Wesley is a ministry to the campus & students of Marshall University focused on loving God, loving others, and loving a world in need.",
                                "url": "https://www.crossroadswv.com/marshall-wesley"
                            },
                            {
                                "@type": "Organization",
                                "name": "Wednesday Night Kids",
                                "description": "Wednesday Night Kids is a ministry for children and youth every Wednesday night at 6:30PM, focusing on spiritual formation and building relationships with Christ.",
                                "url": "https://www.crossroadswv.com/wednesday-night-kids"
                            }
                        ],
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com/ministries-outreach"
                        }
                    })}
                </script>

            </Helmet>

            <div className='page-wrapper ministries'>
                <div className='page-title'>
                    <h1>MINISTRIES AND OUTREACH</h1>
                    <hr />
                </div>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Our Ministries and Outreach</h2>
                            <div className='text-content'>
                                <p>Our ministries and outreach programs are dedicated to serving both our church family and the wider community. From providing meals and support to those in need, to creating thank you cards for first responders, we strive to create spaces where everyone feels valued and connected. Whether through local service projects, community events, or ongoing programs, we are committed to sharing Christ's love and making a positive impact wherever we can.</p>
                                <br/>
                                <div className='vbs-date-wrapper'>
                                    <div className='vbs-icon-wrapper'>
                                        <i className="fa-solid fa-clock"></i>
                                        <span>Times</span>
                                    </div>
                                    <div className='ministries-times-wrapper'>
                                        <p><b>God's Big House</b> meets <b> every Sunday</b> from <b>10:15–10:45AM</b>.</p>
                                        <p><b>Wednesday Night Kids (WNK)</b> meets <b> every Wednesday</b> at <b>6:30PM</b>.</p>
                                        <p><b>Grief Support Group</b> meets on the <b>3rd Tuesday</b> of each month at <b>1:30PM</b>.</p>
                                        <p><b>Marshall Wesley</b> meets on <b>Thursdays at 8:00PM</b>.</p>
                                        <p><b>September Club</b> meets on the <b>first Wednesday</b> of each month at <b>12:00PM NOON</b>.</p>
                                        <p><b>Cub Scouts</b> meet on <b>Mondays at 6:30 PM</b>.</p>
                                        <p><b>Boy Scouts</b> meet on <b>Thursdays at 7:00 PM</b>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='ministries-cards-wrapper'>
                            <Link to='/operation-christmas-child' aria-label="Learn more about Operation Christmas Child" className='ministries-card-wrapper'>
                                <h3>Operation Christmas Child</h3>
                                <div className='ministries-card'>
                                    <img src={occ} loading='eager' alt='Smiling child holding a green Operation Christmas Child shoebox, ready to be gifted to children in need, with Samaritan’s Purse logo in the corner.' />
                                    <p><span>Operation Christmas Child delivers shoeboxes filled with essentials and toys to children in need worldwide, sharing hope and the Gospel.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/first-responders' aria-label="Learn more about support for First Responders" className='ministries-card-wrapper'>
                                <h3>First Responders</h3>
                                <div className='ministries-card'>
                                    <img src={cards} loading='eager' alt='Handmade thank-you cards from children, addressed to police, firefighters, and ambulance workers, showing appreciation for their service.' />
                                    <p><span>Our church supports local first responders and educators by delivering treats, gift bags, and handmade cards to show our gratitude for their service and dedication.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/mission-trips' aria-label="Learn more about Mission Trips" className='ministries-card-wrapper'>
                                <h3>Mission Trips</h3>
                                <div className='ministries-card'>
                                    <img src={missionTrips} loading='eager' alt='A group of young people standing side by side on a suspension bridge, looking away from the camera. They are on a mission trip, symbolizing their unity and purpose in serving others.' />
                                    <p><span>Our church organizes mission trips to serve communities, build connections, and share Christ’s love locally and abroad.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/cub-boy-scouts' aria-label="Learn more about Cub and Boy Scouts at Cross Roads" className='ministries-card-wrapper'>
                                <h3>Cub and Boy Scouts</h3>
                                <div className='ministries-card'>
                                    <img src={scouts} loading='eager' alt='Boy Scouts and Cub Scouts logos' />
                                    <p><span>We are proud to charter both Boy Scout Troop 25 and Cub Scout Pack 25, providing great fun and learning opportunities for all ages.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>

                            <Link  to='/common-grounds' aria-label="Learn more about Common Grounds" className='ministries-card-wrapper'>
                                <h3>Common Grounds</h3>
                                <div className='ministries-card'>
                                    <img src={commonGrounds} loading='lazy' alt='A group of volunteers in a kitchen smiling and holding bowls of food. They are part of the Common Grounds program, providing meals and creating a welcoming community space.' />
                                    <p><span>Common Grounds is a program that provides meals for those in need, creating a space for connection and community support.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/september-club' aria-label="Learn more about September Club" className='ministries-card-wrapper'>
                                <h3>September Club</h3>
                                <div className='ministries-card'>
                                    <img src={septemberClub2} loading='lazy' alt="A group of people gathered at a restaurant for a September Club lunch. They are sitting around a long table, mostly older adults, engaged in conversation and enjoying their meal in a cozy, warmly lit environment." />
                                    <p><span>September Club meets at noon on the first Wednesday of each month for lunch and fellowship.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/holidays' aria-label="Learn more about Holidays at Cross Roads" className='ministries-card-wrapper'>
                                <h3>Holidays</h3>
                                <div className='ministries-card'>
                                    <img src={holidays} loading='lazy' alt='A group of volunteers in a kitchen smiling and holding bowls of food. They are part of the Common Grounds program, providing meals and creating a welcoming community space.' />
                                    <p><span>We do all kinds of fun activities around the various holidays, and are always sure to remember the reason for the season.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/vacation-bible-school' aria-label="Learn more about VBS" className='ministries-card-wrapper'>
                                <h3>VBS</h3>
                                <div className='ministries-card'>
                                    <img src={VBS} loading='lazy' alt='A group of volunteers in a kitchen smiling and holding bowls of food. They are part of the Common Grounds program, providing meals and creating a welcoming community space.' />
                                    <p><span>Vacation Bible School is a fun-filled week of learning, crafts, and games, teaching kids about God’s love while creating lasting memories.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/grief-support' aria-label="Learn more about Grief Support Group" className='ministries-card-wrapper'>
                                <h3>Grief Support Group</h3>
                                <div className='ministries-card'>
                                    <div className='ministries-icon-wrapper' aria-label="Icon of a hand holding a heart, symbolizing the Grief Support Group">
                                        <i className="fa-solid fa-hand-holding-heart" aria-hidden="true"></i>
                                    </div>
                                    <p><span>The Grief Support Group offers a safe and compassionate space for those who have experienced loss. We help one another heal and navigate the challenges of grief.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>




                            <Link to='/recovery-alive' aria-label="Learn more about Recovery Alive" className='ministries-card-wrapper'>
                                <h3>Recovery Alive</h3>
                                <div className='ministries-card'>
                                    <img src={recovery} loading='lazy' alt="Logo of the Recovery Alive program, featuring a vibrant sun rising over the words 'Recovery Alive!' with the tagline 'Raising Hope From The Dead' and 'Cross Roads UMC Huntington' below." />
                                    <p>(Beginning Spring 2025)</p>
                                    <p><span>Recovery Alive is a church-based program that connects the broken to the POWER, PEOPLE, and PROCESS of the Christ-centered 12 steps.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/gods-big-house' aria-label="Learn more about God's Big House" className='ministries-card-wrapper'>
                                <h3>God's Big House</h3>
                                <div className='ministries-card'>
                                    <img src={GBH} loading='lazy' alt="A brightly colored children's room with a table set for activity, featuring six chairs and vibrant placemats. The walls are decorated with tropical-themed artwork, and a window provides natural light." />
                                    <p><span>God's Big House is a minsitry to children K–5th grade every Sunday from 10:15–10:45AM that helps children grow in their faith.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/marshall-wesley' aria-label="Learn more about Marshall Wesley" className='ministries-card-wrapper'>
                                <h3>Marshall Wesley</h3>
                                <div className='ministries-card'>
                                    <img src={wesley} loading='lazy' alt='Marshall University Marshall Wesley Logo.' />
                                    <p><span>Marshall Wesley is a ministry to the campus & students of Marshall University focused on loving God, loving others, loving a world in need.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>

                            <Link to='/wednesday-night-kids' aria-label="Learn more about Wednesday Night Kids (WNK)" className='ministries-card-wrapper'>
                                <h3>Wednesday Night Kids (WNK)</h3>
                                <div className='ministries-card'>
                                    <img src={wnk} loading='lazy' alt="A group of children and adults standing together in a classroom, posing with smiles, during a Wednesday Night Kids gathering." />
                                    <p><span>Wednesday Night Kids is a ministry for children and youth every Wednesday night at 6:30PM, focusing on spiritual formation and growing their relationship with Christ.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-arrow-right"></i>
                                    </div>
                                </div>
                            </Link>

                            <a href='https://www.prayerquilt.org/about-us' target="_blank" rel="noopener noreferrer" aria-label="Learn more about Prayers and Squares on an external site" className='ministries-card-wrapper'>
                                <h3>Prayers and Squares</h3>
                                <div className='ministries-card'>
                                    <img src={prayersSquares} loading='lazy' alt='A woman holding a large, handwoven mat made from recycled plastic bags, created to support the homeless as part of the Mats for the Homeless initiative.' />
                                    <p><span>Prayers & Squares is an interfaith outreach organization that combines the gift of prayer with the gift of a hand-tied quilt. The purpose of Prayers & Squares is to promote prayer through the use of quilts.</span></p>
                                    <p><span>Learn more on the <b>Prayer Quilt website</b> here.</span></p>
                                    
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-up-right-from-square" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </a>

                            <a href='https://nineriverswvumc.org/united-women-in-faith/' target="_blank" rel="noopener noreferrer" aria-label="Learn more about United Women in Faith on an external site" className='ministries-card-wrapper'>
                                <h3>United Women in Faith</h3>
                                <div className='ministries-card'>
                                    <img src={uwFaith} loading='lazy' alt='Logo of United Women in Faith, featuring a multicolored cross symbol with blue, green, yellow, and orange segments representing unity and diversity.' />
                                    <p><span>United Women in Faith seeks to connect and nurture women through Christian spiritual formation, leadership development, creative fellowship, and education.</span></p>
                                    <p><span>Learn more on the <b>United Women in Faith page</b> here.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-up-right-from-square" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </a>

                            <a href='https://30hourfamine.worldvision.org/' target="_blank" rel="noopener noreferrer" aria-label="Learn more about 30 Hour Famine on an external site" className='ministries-card-wrapper'>
                                <h3>30 Hour Famine</h3>
                                <div className='ministries-card'>
                                    <div className='ministries-icon-wrapper' aria-label="Icon of a hand holding a heart, symbolizing the 30 Hour Famine initiative for hunger relief">
                                        <i className="fa-solid fa-wheat-awn-circle-exclamation"></i>
                                    </div>
                                    <p><span>The 30 Hour Famine consists of virtual youth group retreat, thought-provoking Bible studies, games, inspirational videos, and more, to raise money for the hungry.</span></p>
                                    <p><span>Learn more on <b>30 Hour Famine website</b> here.</span></p>
                                    <div className='go-ministries'>
                                        Learn more <i className="fa-solid fa-up-right-from-square" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </a>

                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Ministries;
