
import React from 'react';
import './styles/Home.css'
import { Helmet } from 'react-helmet-async';

import Hero from '../components/Hero';
import Announcements from '../components/Announcements';
import DailyReadings from '../components/DailyReadings';
import OurHistory from '../components/OurHistory';

import sanctuaryFull from '../assets/images/sanctuaryFull.webp';

const Home = () => {


    return (
        <>
            <Helmet>
                <title>Cross Roads United Methodist Church - Welcome Home</title>
                <meta
                    name="description"
                    content="Welcome to Cross Roads United Methodist Church, a place of worship, community, and support for people of all ages. Join us for our weekly worship services, announcements, and inspiring daily Bible readings."
                />
                <meta name="keywords" content="church, faith, worship, announcements, daily readings, community" />
                <meta property="og:title" content="Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Explore the latest announcements, events, and daily Bible readings at Cross Roads United Methodist Church. Join us for worship and community activities."
                />
                <meta property="og:url" content="https://www.crossroadswv.com" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com" />

                {/* JSON-LD Structured Data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "PlaceOfWorship",
                        "name": "Cross Roads United Methodist Church",
                        "url": "https://www.crossroadswv.com",
                        "logo": "%PUBLIC_URL%/logo512.png",
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ],
                        "contactPoint": [
                            {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-522-7434",
                                "contactType": "Church Support",
                                "areaServed": "US",
                                "email": "crossroadsumc@comcast.net"
                            },
                            {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-525-6547",
                                "contactType": "Childcare Services",
                                "areaServed": "US",
                                "email": "crccm@comcast.net",
                                "description": "Contact Cross Roads Daycare and Preschool for enrollment and program details."
                            }
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "3146 Saltwell Rd",
                            "addressLocality": "Huntington",
                            "addressRegion": "WV",
                            "postalCode": "25705",
                            "addressCountry": "US"
                        },
                        "hasMap": "https://www.google.com/maps/place/Crossroads+UMC/@38.4024952,-82.3881641,15z/data=!4m2!3m1!1s0x0:0x4b99b1521962cec5?sa=X&ved=1t:2428&ictx=111",
                        "description": "Join us at Cross Roads United Methodist Church, a welcoming community of faith in Huntington offering worship, outreach, and daily readings.",
                        "foundingDate": "1892",
                        "keywords": ["church", "faith community", "worship", "United Methodist Church", "daycare", "preschool"],
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "38.4024952",
                            "longitude": "-82.3881641"
                        },
                        "department": [
                            {
                                "@type": "Organization",
                                "name": "Cross Roads Daycare and Preschool",
                                "sameAs": "https://www.crossroadswv.com/childcare-preschool",
                                "description": "Our daycare and preschool program provides a nurturing environment for young children to explore, learn, and grow within a community of care.",
                                "serviceType": "Preschool and Childcare Services"
                            }
                        ],
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com"
                        },
                        "article": [
                            {
                                "@type": "NewsArticle",
                                "headline": "Church Announcements",
                                "description": "Catch up on the latest news and announcements from Cross Roads United Methodist Church.",
                                "author": {
                                    "@type": "Organization",
                                    "name": "Cross Roads United Methodist Church"
                                },
                                "publisher": {
                                    "@type": "Organization",
                                    "name": "Cross Roads United Methodist Church",
                                    "logo": {
                                        "@type": "ImageObject",
                                        "url": "%PUBLIC_URL%/logo512.png"
                                    }
                                }
                            }
                        ]
                    })}
                </script>

            </Helmet>
            
            <div className='page-wrapper home'>

                
                <section className='hero' aria-labelledby="hero-title">
                    <h2 id="hero-title" className="sr-only">Hero Section</h2>
                    <Hero/>
                </section>

                <section className='history' aria-labelledby="daily-readings-title">
                    <h2 id="daily-readings-title" className="sr-only">History</h2>
                    <OurHistory />
                </section>

                <section className='announcements' aria-labelledby="announcements-title">
                    <h2 id="announcements-title" className="sr-only">Latest Announcements</h2>
                    <Announcements/>
                </section>

                <section className='daily-readings' aria-labelledby="daily-readings-title">
                    <h2 id="daily-readings-title" className="sr-only">Daily Bible Readings</h2>
                    <DailyReadings/>
                </section>


            </div>
        </>
    );
}

export default Home;
