
import React from 'react';
import './styles/Beliefs.css'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';



const Beliefs = () => {


    return (
        <>

            <Helmet>
                <title>Our Basic Beliefs - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Learn about the core beliefs of Cross Roads United Methodist Church, including salvation by faith, universal atonement, the witness of the spirit, and perfect love."
                />
                <meta property="og:title" content="Basic Beliefs - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Explore the United Methodist beliefs at Cross Roads UMC. Discover our understanding of universal atonement, salvation, the Holy Spirit, and Christian love."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/beliefs" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/beliefs" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Basic Beliefs",
                        "url": "https://www.crossroadswv.com/beliefs",
                        "mainEntity": {
                            "@type": "Article",
                            "headline": "Our Core Beliefs",
                            "description": "This page explores Cross Roads United Methodist Church's basic beliefs, covering key concepts such as salvation, atonement, and the witness of the spirit.",
                            "author": {
                                "@type": "Organization",
                                "name": "Cross Roads United Methodist Church",
                                "url": "https://www.crossroadswv.com"
                            },
                            "publisher": {
                                "@type": "Organization",
                                "name": "Cross Roads United Methodist Church",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "%PUBLIC_URL%/logo512.png"
                                }
                            },
                            "keywords": ["United Methodist beliefs", "salvation by faith", "witness of the spirit", "Christian love"],
                            "about": [
                                {
                                    "@type": "Thing",
                                    "name": "Salvation by Faith",
                                    "description": "The belief that salvation is obtained through faith in Jesus Christ alone."
                                },
                                {
                                    "@type": "Thing",
                                    "name": "Universal Atonement",
                                    "description": "The belief that God's love and atonement are inclusive for all."
                                },
                                {
                                    "@type": "Thing",
                                    "name": "Witness of the Spirit",
                                    "description": "The inner assurance that one belongs to God, confirmed through the Holy Spirit."
                                },
                                {
                                    "@type": "Thing",
                                    "name": "Perfect Love",
                                    "description": "A life characterized by love, following God's example."
                                },
                                {
                                    "@type": "Thing",
                                    "name": "Baptism",
                                    "description": "A sacrament symbolizing God's grace and initiation into the church."
                                },
                                {
                                    "@type": "Thing",
                                    "name": "Communion",
                                    "description": "A sacrament representing unity with Christ and the community."
                                }
                            ],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "https://www.crossroadswv.com/beliefs"
                            }
                        }
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper beliefs'>
                <div className='page-title'>
                    <h1>BASIC BELIEFS</h1>
                    <hr />
                </div>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>United Methodist Basic Beliefs</h2>
                            <div className='text-content'>
                                <p>With so many different denominations of churches, it can often be challenging to understand the differences and what each one stands for. While all Christian churches share foundational beliefs, each denomination expresses its faith in unique ways. To help clarify, we’ve broken it down into four simple pillars. These pillars represent the core values that guide our faith, worship, and service to the community.</p>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='pillar-rows-wrapper'>
                            <div className='pillars-wrapper'>
                                <div className='pillar'>
                                    <div className='pillar-icon-wrapper'>
                                        <i className="fa-solid fa-child-reaching"></i>
                                    </div>
                                    <h2>Universal Atonement</h2>
                                    <div className='pillar-desc-wrapper'>
                                        <p>(God's love includes everyone.)</p>
                                    </div>
                                    <div className='pillar-verse-wrapper'>
                                        <i>“For God so loved the world, that he gave his only begotten Son, that whoever believes in him should not perish, but have eternal life.” (John 3:16)</i>
                                    </div>
                                    <br />
                                    <div className='pillar-desc-wrapper'>
                                        <p>This is the most important truth in the world – God loves each of us, whoever we are, whatever our lot in life! Every one of us can say, “I am loved by the Creator God.”</p>
                                    </div>
                                </div>
                                
                                <div className='pillar'>
                                    <div className='pillar-icon-wrapper'>
                                        <i className="fa-solid fa-cross"></i>
                                    </div>
                                    <h2>Salvation By Faith</h2>
                                    <div className='pillar-desc-wrapper'>
                                        <p>(Though tragically separated from God by sin, we can be saved through faith in Jesus Christ!)</p>
                                    </div>
                                    <div className='pillar-verse-wrapper'>
                                        <i>“and you are to name him Jesus, for he will save his people from their sins.” (Matthew 1:21)</i>
                                    </div>
                                    <br />
                                    <div className='pillar-desc-wrapper'>
                                        <strong>Steps to Salvation from Sin:</strong>
                                        <ol>
                                            <li>Admit we have sinned – “For all have sinned and fall short of the glory of God” (Romans 3:23)</li>
                                            <li>Realize that we cannot save ourselves – “For by grace you have been saved through faith, and this is not your own doing: it is the gift of God – not the result of works, so that no one may boast.” (Ephesians 2:8-9)</li>
                                            <li>Acknowledge Jesus Christ as the only Savior – “Jesus said, ‘I am the way, and the truth, and the life. No one comes to the Father except through me.’” (John 14:6)</li>
                                            <li>Receive Christ by faith as our personal Savior – “But to all who received him, who believed in his name, he gave power to become children of God.” (John 1:12)</li>
                                        </ol>
                                    </div>
                                </div>

                                <div className='pillar'>
                                    <div className='pillar-icon-wrapper'>
                                        <i className="fa-solid fa-link"></i>
                                    </div>
                                    <h2>Witness of the Spirit</h2>
                                    <div className='pillar-desc-wrapper'>
                                        <p>(God's spirit brings assurance that we belong to him!)</p>
                                    </div>
                                    <div className='pillar-verse-wrapper'>
                                        <i>“It is that very Spirit bearing witness with our spirit that we are children of God.” (Romans 8:15)</i>
                                    </div>
                                    <br />
                                    <div className='pillar-desc-wrapper'>
                                        <p>For some, this confidence dawns gradually. For others, it is a sudden discovery at the moment of faith. Each of us can be assured of our acceptance with God! The “Witness of the Spirit” is more than an emotion that we feel, or a voice that we hear. It is an inner confidence that God is faithful, and that God does indeed keep his promise with us! This inward confidence is confirmed by outward change – i.e., a new style of life.</p>
                                    </div>
                                    <div className='pillar-verse-wrapper'>
                                        <i>“We know we have passed from death to life because we love one another.” (I John 3:14)</i>
                                    </div>
                                </div>
                                
                                <div className='pillar'>
                                    <div className='pillar-icon-wrapper'>
                                        <i className="fa-solid fa-heart"></i>
                                    </div>
                                    <h2>Perfect Love</h2>
                                    <div className='pillar-desc-wrapper'>
                                        <p>(A life dominated by perfect love is the goal of Christian experience.)</p>
                                    </div>
                                    <div className='pillar-verse-wrapper'>
                                        <i>“Be perfect, therefore, as your heavenly Father is perfect.” (Matthew 5:48)</i>
                                    </div>
                                    <br />
                                    <div className='pillar-desc-wrapper'>
                                        <p>To live in “perfect love” is to seek God’s will in every situation, and to desire the best for every individual. It is not a physical attraction, nor a mere friendly feeling. It is unending goodwill, even to the most unlovable. “Perfect love” is both an experience of the heart and a way of life in the world. The Holy Spirit desires to perfect God’s love in us and through us.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Baptism</h2>
                            <div className='text-content'>
                                <p>The United Methodist Church views baptism as a sacrament and a means of divine grace, signifying God’s covenant with humanity and the work of the Holy Spirit in one’s life. It serves as an act of initiation into the universal church, regardless of age or denomination, and can be performed through sprinkling, pouring, or immersion. While baptism itself does not guarantee salvation, it represents God’s promise to be present in the believer's life, and is offered to infants, children, and adults alike, recognizing the need for personal faith and growth in Christ as believers mature.</p>
                                <br />
                                <p>To read more about baptism, click <Link to='/baptism'>here</Link>.</p>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Communion</h2>
                            <div className='text-content'>
                                <p>Holy Communion in the United Methodist Church is one of two sacraments, symbolizing God’s grace and our connection with Christ. Known by various names such as the Lord’s Supper, Holy Communion, and Eucharist, it is a sacred meal that unites believers with Jesus and one another, representing his body and blood through bread and juice. The church practices "open communion," inviting all, including children, to participate without restriction, reflecting the inclusive nature of Christ's table and the spiritual transformation it brings.</p>
                                <br />
                                <p>To read more about communion, click <Link to='/communion'>here</Link>.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
}

export default Beliefs;
