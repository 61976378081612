import React, { useEffect, useState } from 'react';
import axios from './../api/axios';
import './styles/Announcements.css';
import { Helmet } from 'react-helmet-async';

const Announcements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAnnouncements = async () => {
            try {
                const response = await axios.get('/api/announcements');
                setAnnouncements(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching announcements:", error);
                setError("Could not load announcements. Please try again later.");
                setLoading(false);
            }
        };

        fetchAnnouncements();
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <>
            <Helmet>
                <title>Announcements - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Stay updated with the latest announcements, events, and news from Cross Roads United Methodist Church. Discover upcoming gatherings, services, and community news."
                />
                <meta property="og:title" content="Announcements - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Catch up on the latest announcements from Cross Roads United Methodist Church, including news, events, and opportunities for fellowship."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/announcements" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/announcements" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "CollectionPage",
                        "name": "Announcements - Cross Roads United Methodist Church",
                        "description": "Latest announcements, updates, and community events from Cross Roads United Methodist Church.",
                        "url": "https://www.crossroadswv.com/announcements",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com/announcements"
                        },
                        "mainEntity": announcements.map(announcement => ({
                            "@type": "Article",
                            "headline": announcement.subject,
                            "datePublished": new Date(announcement.submitted_at).toISOString(),
                            "dateModified": new Date(announcement.updated_at || announcement.submitted_at).toISOString(),
                            "articleBody": announcement.announcement,
                            "expirationDate": announcement.expires ? new Date(announcement.expiration_date).toISOString() : undefined
                        })),
                        "publisher": {
                            "@type": "Organization",
                            "name": "Cross Roads United Methodist Church",
                            "url": "https://www.crossroadswv.com",
                            "logo": "%PUBLIC_URL%/logo512.png",
                            "sameAs": ["https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"]
                        },
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+1-304-522-7434",
                            "contactType": "Church Support",
                            "areaServed": "US",
                            "availableLanguage": "English",
                            "email": "crossroadsumc@comcast.net"
                        },
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "3146 Saltwell Rd",
                            "addressLocality": "Huntington",
                            "addressRegion": "WV",
                            "postalCode": "25705",
                            "addressCountry": "US"
                        }
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper announcements-page'>
                <div className='page-title'>
                    <h1>ANNOUNCEMENTS</h1>
                    <hr />
                </div>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <div className='text-content'>
                                <p>Welcome to the announcements page. Here you’ll find the latest updates, events, and news from our church community. Stay connected with upcoming services, volunteer opportunities, and special gatherings designed to bring us together in faith and fellowship. We’re glad you’re here, and we look forward to sharing in worship and service with you!</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='announcements-wrapper'>
                            {loading ? (
                                <p>Loading announcements...</p>
                            ) : error ? (
                                <p className="error-message" role="alert">{error}</p>
                            ) : (
                                announcements.length > 0 ? (
                                    announcements.map((announcement) => (
                                        <div key={announcement.id} className='announcement'>
                                            <figure className='announcement-date-wrapper'>
                                                <i className="fa-solid fa-calendar-days"></i>
                                                <figcaption>Posted on {formatDate(announcement.submitted_at)}</figcaption>
                                            </figure>
                                            <div className='announcement-content-wrapper'>
                                                <div className='announcement-text'>
                                                    <h2>{announcement.subject}</h2>
                                                </div>
                                                <div 
                                                    className='announcement-text'
                                                    dangerouslySetInnerHTML={{ __html: announcement.announcement }}
                                                />
                                                {announcement.expires && announcement.expiration_date ? (
                                                    <div className='announcement-expiration'>
                                                        <small>Expires on: {formatDate(announcement.expiration_date)}</small>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p aria-live="polite">No announcements at this time.</p>
                                )
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Announcements;
