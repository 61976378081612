import React, { useEffect, useState } from 'react';
import axios from './../api/axios';
import './styles/ChildcareStaff.css';

const ChildcareStaff = () => {
    const [staffList, setStaffList] = useState([]);
    const [error, setError] = useState(null);

    const teamIcons = {
        "Infant Care Team": "fa-solid fa-baby-carriage",
        "Toddler Care Team": "fa-solid fa-child-reaching",
        "2-year-old Care Team": "fa-solid fa-2",
        "3-year-old Care Team": "fa-solid fa-3",
        "Pre-K Care Team": "fa-solid fa-children",
        "School-age Care Team": "fa-solid fa-school",
        "Kitchen Staff": "fa-solid fa-kitchen-set",
    };

    useEffect(() => {
        const fetchStaff = async () => {
            try {
                const response = await axios.get('/api/childcare-staff');
                setStaffList(response.data);
            } catch (error) {
                console.error("Error fetching childcare staff:", error);
                setError("Could not load childcare staff. Please try again later.");
            }
        };
        fetchStaff();
    }, []);

    // Separate Director and Assistant Director from the rest of the staff
    const director = staffList.find(staff => staff.team === "Director") || { name: "Could not load. Please try again later." };
    const assistantDirector = staffList.find(staff => staff.team === "Assistant Director") || { name: "Could not load. Please try again later." };
    const filteredStaffList = staffList.filter(staff => !["Director", "Assistant Director"].includes(staff.team));

    // Group remaining staff by team
    const groupedStaff = filteredStaffList.reduce((acc, staff) => {
        if (!acc[staff.team]) {
            acc[staff.team] = [];
        }
        acc[staff.team].push(staff);
        return acc;
    }, {});

    return (
        <section className='staff-section-wrapper childcare' aria-labelledby="childcare-staff-header">
            <div className='childcare-staff-heading-wrapper'>
                <h2 id="childcare-staff-header" className='childcare-staff-header'>Our Childcare Staff</h2>
                <p>Director: <b>{director.name}</b></p>
                <p>Assistant Director: <b>{assistantDirector.name}</b></p>
            </div>
    
            {error ? (
                <p className="error-message" role="alert">{error}</p>
            ) : (
                <div className='staff-row-wrapper'>
                    {Object.entries(groupedStaff).map(([team, members]) => (
                        <div key={team} className='staff-member-wrapper'>
                            <div className='staff-member'>
                                <div className='staff-member-image' aria-hidden="true">
                                    <i className={teamIcons[team] || "fa-solid fa-user"}></i>
                                </div>
                                <div className='staff-member-info-wrapper'>
                                    <div className='staff-member-info'>
                                        <div className='info-line'>
                                            <h3>{team}</h3>
                                        </div>
                                        <div className='info-line'>
                                            {members.map(member => (
                                                <p key={member.id}>{member.name}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </section>
    );    
};

export default ChildcareStaff;
