import React from 'react';
import './styles/Baptism.css';
import { Helmet } from 'react-helmet-async';

import vbs from './../assets/images/vbs.webp';


const VBS = () => {

    return (
        <>
            <Helmet>
                <title>Vacation Bible School - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Join Cross Roads United Methodist Church for our annual Vacation Bible School. A week of fun, learning, and spiritual growth for children and families."
                />
                <meta property="og:title" content="Vacation Bible School - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Vacation Bible School at Cross Roads UMC offers children a week of Bible stories, games, crafts, and fellowship in a supportive and engaging environment."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/vacation-bible-school" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/vacation-bible-school" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "name": "Vacation Bible School",
                        "description": "A fun-filled program to teach children about the Bible through stories, songs, crafts, and games. Held every summer at Cross Roads United Methodist Church.",
                        "url": "https://www.crossroadswv.com/vacation-bible-school",
                        "location": {
                            "@type": "Place",
                            "name": "Cross Roads United Methodist Church",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "3146 Saltwell Rd",
                                "addressLocality": "Huntington",
                                "addressRegion": "WV",
                                "postalCode": "25705",
                                "addressCountry": "US"
                            }
                        },
                        "image": "%PUBLIC_URL%/vbs.webp",
                        "organizer": {
                            "@type": "Organization",
                            "name": "Cross Roads United Methodist Church",
                            "url": "https://www.crossroadswv.com"
                        },
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ]
                    })}
                </script>
            </Helmet>


            <div className='page-wrapper first-responders'>
                <div className='page-title'>
                    <h1>VACATION BIBLE SCHOOL</h1>
                    <hr/>
                </div>

                
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>What is VBS?</h2>
                            <div className='text-content'>
                                <p>Vacation Bible School (VBS) is a fun-filled program we hold each summer to teach children about the Bible and God's love through stories, songs, crafts, and games. It's an exciting way for kids to make friends, learn valuable lessons, and build lasting memories in a supportive environment.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={vbs} alt=''/>
                        </div>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Join Us This Summer!</h2>
                            <div className='text-content'>
                                <p>We invite all children and families to join us for a week of learning, laughter, and fellowship. Each day, kids will explore different Bible stories, engage in themed activities, and enjoy snacks. Our dedicated volunteers ensure a safe and engaging experience for everyone.</p>
                                <p>To register or find out more about the upcoming Vacation Bible School, please contact us at <a href="mailto:crossroadsumc@comcast.net">crossroadsumc@comcast.net</a> or call us at <a href="tel:304-522-7434">(304)-522-7434</a>.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default VBS;