import React from 'react';
import './styles/OurHistory.css';

import exteriorBW from './../assets/images/exteriorBW.webp';

import { Link } from 'react-router-dom';

const OurHistory = () => {

    const CurrentYear = new Date().getFullYear();
    const yearsOfWorship = CurrentYear - 1892;

    return (
        <>
        <div className='text-content-section'>
            <div className='section-content-wrapper'>
                <div className='content-text-wrapper'>
                    <h2>An Extensive History</h2>
                    <div className='text-content'>
                        <p>
                            It is March of 1891. Snow is giving way to mud, in some places ankle-deep, but this does not hinder the group of men laying the foundation for the new church at Cross Roads. The hill-top site was cleared and made ready several weeks ago; then came the snow and the work was delayed. The lumber is being brought from town by horse and wagon — four horses to a wagon. One wagon is driven by John Hall, the other by George Ferguson. Men are all walking behind the wagons to chock, for the hills are steep and still a little slick.
                        </p>
                        <br/>
                        <p>
                            This was a church born of cottage prayer and meetings. Houses were scattered and people walked or rode, most often by lantern light, to meet together to pray and talk about their Lord and the Christian life.
                        </p>
                        <br/>
                        <p>
                            The ministers had no easy path in those days.  They received very little pay and had to do lots of walking, as many could not afford even a horse to ride. Cross Roads was one of several churches on a circuit, and visiting the sick, officiating at funerals and performing marriages for four or five parishes was an unending task. It was a calling from God, indeed.
                        </p>
                        <br/>
                        <p>
                            <b>We are beyond thankful for all of our ministers, and we are beyond grateful for our heritage. We trust that our purpose shall always be the mission Christ has given us.</b>
                        </p> 
                        <br/>
                        <p>
                            Read more about our history <Link to='/our-history'>here <i className="fa-solid fa-arrow-right" aria-hidden="true"></i></Link>
                        </p>
                    </div>
                </div>
                <div className='section-image-placeholder'>
                    <img src={exteriorBW} alt="Black and white of the original Cross Roads United Methodist Church building."/>
                    <p className='worship-years-wrapper'>{yearsOfWorship} years and counting!</p>
                </div>
            </div>    
        </div>
        </>
    );
};

export default OurHistory;
