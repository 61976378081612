import React from 'react';
import './styles/ChildcareForms.css';

import attendanceSchedule from './../assets/childcare_forms/childAttendanceSchedule.pdf';
import parentalAgreement from './../assets/childcare_forms/childcareParentalAgreement.pdf';
import disciplineRegulations from './../assets/childcare_forms/childcareDisciplineRegulations.pdf';
import emergencyReleaseForm from './../assets/childcare_forms/childcareEmergencyReleaseForm.pdf';
import paymentAgreement from './../assets/childcare_forms/childcarePaymentAgreement.pdf';
import childEmergencyCard from './../assets/childcare_forms/childEmergencyCard.pdf';
import childInformationForm from './../assets/childcare_forms/childInformationForm.pdf';
import childMedicalRecord from './../assets/childcare_forms/childMedicalRecordForm.pdf';
import parentPolicyHandbook from './../assets/childcare_forms/childcareParentPolicyHandbook.pdf';


const ChildcareForms = () => {

    return (
        <div className='childcare-forms'>
            <h3 style={{ color: 'rgba(72, 65, 65, 1)', fontFamily: '"Titillium Web", "Titillium Web Fallback"', fontSize: '30px' }}>
                Child Enrollment Forms
            </h3>
            
            <div className='childcare-form-buttons-wrapper'>
                <a href={attendanceSchedule} target="_blank" rel="noopener noreferrer" aria-label="Open PDF: Child Attendance Schedule">
                    <span className="open-pdf-button"><i className="fa-solid fa-file-pdf"></i> Child Attendance Schedule</span>
                </a>
                <a href={childEmergencyCard} target="_blank" rel="noopener noreferrer" aria-label="Open PDF: Child Emergency Card">
                    <span className="open-pdf-button"><i className="fa-solid fa-file-pdf"></i> Child Emergency Card</span>
                </a>
                <a href={emergencyReleaseForm} target="_blank" rel="noopener noreferrer" aria-label="Open PDF: Emergency Release Form">
                    <span className="open-pdf-button"><i className="fa-solid fa-file-pdf"></i> Emergency Release Form</span>
                </a>
                <a href={childInformationForm} target="_blank" rel="noopener noreferrer" aria-label="Open PDF: Child Information Form">
                    <span className="open-pdf-button"><i className="fa-solid fa-file-pdf"></i> Child Information Form</span>
                </a>
                <a href={childMedicalRecord} target="_blank" rel="noopener noreferrer" aria-label="Open PDF: Child Medical Record">
                    <span className="open-pdf-button"><i className="fa-solid fa-file-pdf"></i> Child Medical Record</span>
                </a>
                <a href={parentalAgreement} target="_blank" rel="noopener noreferrer" aria-label="Open PDF: Parental Agreement">
                    <span className="open-pdf-button"><i className="fa-solid fa-file-pdf"></i> Parental Agreement</span>
                </a>
                <a href={paymentAgreement} target="_blank" rel="noopener noreferrer" aria-label="Open PDF: Payment Agreement">
                    <span className="open-pdf-button"><i className="fa-solid fa-file-pdf"></i> Payment Agreement</span>
                </a>
                <a href={disciplineRegulations} target="_blank" rel="noopener noreferrer" aria-label="Open PDF: Discipline Regulations">
                    <span className="open-pdf-button"><i className="fa-solid fa-file-pdf"></i> Discipline Regulations</span>
                </a>
                <a href={parentPolicyHandbook} target="_blank" rel="noopener noreferrer" aria-label="Open PDF: Parent Policy Handbook">
                    <span className="open-pdf-button"><i className="fa-solid fa-file-pdf"></i> Parent Policy Handbook</span>
                </a>
                <a className='external-pdf' href='https://wvde.us/wp-content/uploads/2024/07/FY2024-2025-FreeRed-CACFP_SFSP.pdf' target="_blank" rel="noopener noreferrer" aria-label="Open external link: Dept. of Education Free/Reduced Price Household Form">
                    <span className="open-pdf-button external">Dept. of Education Free/Reduced Price Household <i className="fa-solid fa-up-right-from-square" aria-hidden="true"></i></span>
                </a>
                <a className='external-pdf' href='https://wvde.us/child-nutrition/child-and-adult-care-food-program/forms-and-reference-tools/' target="_blank" rel="noopener noreferrer" aria-label="Open external link: Other Dept. of Education Forms">
                    <span className="open-pdf-button external">Other Dept. of Education Forms <i className="fa-solid fa-up-right-from-square" aria-hidden="true"></i></span>
                </a>
            </div>
        </div>
    );
};

export default ChildcareForms;
