import React, { useState, useEffect } from 'react';
import axios from './../api/axios';
import ImageGalleryComponent from '../components/ImageGalleryComponent';
import './styles/FirstResponders.css';
import { Helmet } from 'react-helmet-async';

import communion from './../assets/images/valentinesCards.webp';
import holymeal from './../assets/images/firstResponder10.webp';


const FirstResponders = () => {
    const [galleryImages, setGalleryImages] = useState([]);
    const baseURL = process.env.REACT_APP_API_URL;

    // Fetch images for the First Responders gallery
    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get('/api/first-responders/images');
                const images = response.data.map((filename) => ({
                    original: `${baseURL}/uploads/first-responders/${filename}`,
                    thumbnail: `${baseURL}/uploads/first-responders/${filename}`,
                }));
                setGalleryImages(images);
            } catch (error) {
                console.error("Failed to load images:", error);
            }
        };
        fetchImages();
    }, [baseURL]);

    return (
        <>
            <Helmet>
                <title>First Responders Support - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Support our local first responders with Cross Roads United Methodist Church. Join us in showing appreciation through treats, cards, and gift bags."
                />
                <meta property="og:title" content="First Responders Support - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Our church expresses gratitude to first responders and educators with treats and gift bags. Learn how you can get involved in supporting them."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/first-responders" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/first-responders" />
                

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "PlaceOfWorship",
                        "name": "Cross Roads United Methodist Church",
                        "url": "https://www.crossroadswv.com",
                        "logo": "%PUBLIC_URL%/logo512.png",
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ],
                        "contactPoint": [
                            {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-522-7434",
                                "contactType": "Church Support",
                                "areaServed": "US",
                                "availableLanguage": "English",
                                "email": "crossroadsumc@comcast.net"
                            }
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "3146 Saltwell Rd",
                            "addressLocality": "Huntington",
                            "addressRegion": "WV",
                            "postalCode": "25705",
                            "addressCountry": "US"
                        },
                        "description": "Cross Roads United Methodist Church is dedicated to supporting local first responders and educators through acts of gratitude, such as delivering treats and cards.",
                        "foundingDate": "1892",
                        "department": {
                            "@type": "Organization",
                            "name": "First Responders Support Program",
                            "description": "Our program shows appreciation to local first responders and educators through treats, handmade cards, and gift bags.",
                            "url": "https://www.crossroadswv.com/first-responders",
                            "additionalType": "Nonprofit Organization",
                            "parentOrganization": {
                                "@type": "PlaceOfWorship",
                                "name": "Cross Roads United Methodist Church"
                            }
                        },
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com/first-responders"
                        }
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper first-responders'>
                <div className='page-title'>
                    <h1>FIRST RESPONDERS</h1>
                    <hr/>
                </div>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Supporting Our First Responders</h2>
                            <div className='text-content'>
                                <p>Our church is committed to showing appreciation and support for the first responders and educators who serve our community. Through various ministries and outreach programs, we regularly deliver treats, gift bags, and handmade cards as small tokens of gratitude to local firemen, police officers, nurses, teachers, and others who dedicate their lives to helping others. Whether it's special Valentine’s goodies or gift bags for Teacher’s Week, we aim to express our heartfelt thanks and share the love of Christ with those who make a difference every day.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={holymeal} alt="Bags of cookies labeled 'Teacher Appreciation Week' from Cross Roads United Methodist Church, thanking teachers for being the 'apple of someone's eye."/>
                        </div>
                    </div>
                </section>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>How You Can Help</h2>
                            <div className='text-content'>
                                <p>If you'd like to get involved in supporting our first responders, there are plenty of opportunities, especially around the holidays. We prepare and deliver treats, cards, and gift bags during special events like Valentine's Day, Teacher's Week, Christmas, and other holidays to show our gratitude. You can join us by attending a Wednesday service or simply stopping by during these special times to help with assembling and delivering gifts. Your presence and participation are all that’s needed to make a difference in the lives of those who serve our community.</p>
                            </div>
                        </div>
                        <figure className='section-image-placeholder'>
                            <img src={communion} alt="Handmade thank-you cards from children, addressed to police, firefighters, and ambulance workers, showing appreciation for their service." />
                            <figcaption>These cards were made by the Wednesday night children's group!</figcaption>
                        </figure>
                    </div>
                </section>

                <section className='gallery-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Some Highlights</h2>
                            <div className='text-content'>
                            <p>Our church is committed to showing appreciation and support for the first responders and educators who serve our community. Through various ministries and outreach programs, we regularly deliver treats, gift bags, and handmade cards as small tokens of gratitude to local firemen, police officers, nurses, teachers, and others who dedicate their lives to helping others. Whether it's special Valentine’s goodies or gift bags for Teacher’s Week, we aim to express our heartfelt thanks and share the love of Christ with those who make a difference every day.</p>
                            </div>
                            <div className='gallery-wrapper' aria-label="Gallery of First Responders support images" aria-live="polite">
                                {/* ImageGalleryComponent renders the fetched gallery images */}
                                <ImageGalleryComponent images={galleryImages} />
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    );
}

export default FirstResponders;