import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './styles/NavBar.css';

const NavBar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [nestedDropdownOpen, setNestedDropdownOpen] = useState(null);
  const [focusedMainItem, setFocusedMainItem] = useState(null); 
  const [focusedDropdownItem, setFocusedDropdownItem] = useState(null);

  const handleDropdownOpen = (name) => setOpenDropdown(name);
  const handleDropdownClose = () => {
    setOpenDropdown(null);
    setNestedDropdownOpen(null);
  };

  const handleNestedDropdownOpen = (name) => setNestedDropdownOpen(name);
  const handleNestedDropdownClose = () => setNestedDropdownOpen(null);

  const handleMainFocus = (item) => setFocusedMainItem(item);
  const handleMainBlur = (e, item) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setFocusedMainItem(null);
      if (item === 'info') handleDropdownClose();
    }
  };

  const handleDropdownFocus = (item) => setFocusedDropdownItem(item);
  const handleDropdownBlur = (e, item) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setFocusedDropdownItem(null);
      if (item === 'beliefs' || item === 'worship' || item === 'ministries-outreach') {
        // Close nested dropdown only if focus is moving outside the nested dropdown menu
        if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
          handleNestedDropdownClose();
        }
      }
    }
  };

  const handleKeyDown = (e, menuType) => {
    if (e.key === 'Escape') {
      if (menuType === 'main') handleDropdownClose();
      if (menuType === 'nested') handleNestedDropdownClose();
    }
  };

  return (
    <>
      <a className="skip-to-content-link" href="#main-content">
        Skip to main content
      </a>
      <div className="navbar-wrapper" role="navigation" aria-label="Main navigation">
        <div className="navbar-container">
          <div className="navlinks-wrapper">
            <nav className="navlinks">
              <ul className="navlinks-list">
                {/* Home Item */}
                <li 
                  className={`navlink-item ${focusedMainItem === 'home' ? 'focused' : ''}`}
                  onFocus={() => handleMainFocus('home')}
                  onBlur={(e) => handleMainBlur(e, 'home')}
                >
                  <NavLink to="/" className="navlink" activeclassname="active">
                    <span className="navlink-text">Home</span>
                  </NavLink>
                </li>

                {/* Info Dropdown */}
                <li
                  className={`navlink-item ${focusedMainItem === 'info' ? 'focused' : ''}`}
                  aria-controls="info-menu"
                  onMouseEnter={() => handleDropdownOpen('info')}
                  onMouseLeave={handleDropdownClose}
                  onFocus={() => {
                    handleDropdownOpen('info');
                    handleMainFocus('info');
                  }}
                  onBlur={(e) => handleMainBlur(e, 'info')}
                  onKeyDown={(e) => handleKeyDown(e, 'main')}
                >
                  <NavLink to="/about" className="navlink" activeclassname="active">
                    <span className="navlink-text">
                      Info
                      <i className="fa-solid fa-chevron-down" aria-hidden="true" />
                    </span>
                  </NavLink>
                  <ul 
                    id="info-menu"
                    className={`navlink-dropdown-menu ${openDropdown === 'info' ? 'visible' : ''}`}
                    role="menu"
                  >
                    <li 
                      className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'about' ? 'focused' : ''}`}
                      role="menuitem"
                      onFocus={() => handleDropdownFocus('about')}
                      onBlur={(e) => handleDropdownBlur(e, 'about')}
                    >
                      <NavLink 
                        to="/about" 
                        activeclassname="active" 
                        tabIndex={openDropdown === 'info' ? '0' : '-1'}
                      >
                        <span>General Info</span>
                      </NavLink>
                    </li>

                    <li
                        className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'ministries-outreach' ? 'focused' : ''}`}

                        onMouseEnter={() => handleNestedDropdownOpen('ministries-outreach')}
                        onMouseLeave={handleNestedDropdownClose}
                        onFocus={() => {
                            handleNestedDropdownOpen('ministries-outreach');
                            handleDropdownFocus('ministries-outreach');
                        }}
                        onBlur={(e) => handleDropdownBlur(e, 'ministries-outreach')}
                        onKeyDown={(e) => handleKeyDown(e, 'nested')}
                        role="menuitem"
                        >
                        <NavLink to="/ministries-outreach" activeclassname="active" tabIndex={openDropdown === 'info' ? '0' : '-1'}>
                            <span>
                            Ministries and Outreach
                            <i className="fa-solid fa-chevron-down" aria-hidden="true" />
                            </span>
                        </NavLink>
                        <ul 
                            className={`navlink-dropdown-menu-item-dropdown ${nestedDropdownOpen === 'ministries-outreach' ? 'visible' : ''}`}
                            role="menu"
                        >
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'operation-christmas-child' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('operation-christmas-child')}
                            onBlur={(e) => handleDropdownBlur(e, 'operation-christmas-child')}
                            >
                            <NavLink 
                                to="/operation-christmas-child" 
                                activeclassname="active" 
                                tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                            >
                                <span>Operation Christmas Child</span>
                            </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'first-responders' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('first-responders')}
                            onBlur={(e) => handleDropdownBlur(e, 'first-responders')}
                            >
                            <NavLink 
                                to="/first-responders" 
                                activeclassname="active" 
                                tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                            >
                                <span>First Responders</span>
                            </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'mission-trips' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('mission-trips')}
                            onBlur={(e) => handleDropdownBlur(e, 'mission-trips')}
                            >
                            <NavLink 
                                to="/mission-trips" 
                                activeclassname="active" 
                                tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                            >
                                <span>Mission Trips</span>
                            </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'cub-boy-scouts' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('cub-boy-scouts')}
                            onBlur={(e) => handleDropdownBlur(e, 'cub-boy-scouts')}
                            >
                              <NavLink 
                                  to="/cub-boy-scouts" 
                                  activeclassname="active" 
                                  tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                              >
                                  <span>Cub/Boy Scouts</span>
                              </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'common-grounds' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('common-grounds')}
                            onBlur={(e) => handleDropdownBlur(e, 'common-grounds')}
                            >
                            <NavLink 
                                to="/common-grounds" 
                                activeclassname="active" 
                                tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                            >
                                <span>Common Grounds</span>
                            </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'september-club' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('september-club')}
                            onBlur={(e) => handleDropdownBlur(e, 'september-club')}
                            >
                              <NavLink 
                                  to="/september-club" 
                                  activeclassname="active" 
                                  tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                              >
                                  <span>September Club</span>
                              </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'holidays' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('holidays')}
                            onBlur={(e) => handleDropdownBlur(e, 'holidays')}
                            >
                              <NavLink 
                                  to="/holidays" 
                                  activeclassname="active" 
                                  tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                              >
                                  <span>Holidays</span>
                              </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'gods-big-house' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('gods-big-house')}
                            onBlur={(e) => handleDropdownBlur(e, 'gods-big-house')}
                            >
                              <NavLink 
                                  to="/gods-big-house" 
                                  activeclassname="active" 
                                  tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                              >
                                  <span>God's Big House</span>
                              </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'marshall-wesley' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('marshall-wesley')}
                            onBlur={(e) => handleDropdownBlur(e, 'marshall-wesley')}
                            >
                              <NavLink 
                                  to="/marshall-wesley" 
                                  activeclassname="active" 
                                  tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                              >
                                  <span>Marshall Wesley</span>
                              </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'wednesday-night-kids' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('wednesday-night-kids')}
                            onBlur={(e) => handleDropdownBlur(e, 'wednesday-night-kids')}
                            >
                              <NavLink 
                                  to="/wednesday-night-kids" 
                                  activeclassname="active" 
                                  tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                              >
                                  <span>Wednesday Night Kids (WNK)</span>
                              </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'recovery-alive' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('recovery-alive')}
                            onBlur={(e) => handleDropdownBlur(e, 'recovery-alive')}
                            >
                              <NavLink 
                                  to="/recovery-alive" 
                                  activeclassname="active" 
                                  tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                              >
                                  <span>Recovery Alive</span>
                              </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'grief-support' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('grief-support')}
                            onBlur={(e) => handleDropdownBlur(e, 'grief-support')}
                            >
                              <NavLink 
                                  to="/grief-support" 
                                  activeclassname="active" 
                                  tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                              >
                                  <span>Grief Support</span>
                              </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'vacation-bible-school' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('vacation-bible-school')}
                            onBlur={(e) => handleDropdownBlur(e, 'vacation-bible-school')}
                            >
                              <NavLink 
                                  to="/vacation-bible-school" 
                                  activeclassname="active" 
                                  tabIndex={nestedDropdownOpen === 'ministries-outreach' ? '0' : '-1'}
                              >
                                  <span>VBS</span>
                              </NavLink>
                            </li>
                        </ul>
                        </li>
                    <li
                        className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'beliefs' ? 'focused' : ''}`}
                        onMouseEnter={() => handleNestedDropdownOpen('beliefs')}
                        onMouseLeave={handleNestedDropdownClose}
                        onFocus={() => {
                            handleNestedDropdownOpen('beliefs');
                            handleDropdownFocus('beliefs');
                        }}
                        onBlur={(e) => handleDropdownBlur(e, 'beliefs')}
                        onKeyDown={(e) => handleKeyDown(e, 'nested')}
                        role="menuitem"
                        >
                        <NavLink to="/beliefs" activeclassname="active" tabIndex={openDropdown === 'info' ? '0' : '-1'}>
                            <span>
                            Basic Beliefs
                            <i className="fa-solid fa-chevron-down" aria-hidden="true" />
                            </span>
                        </NavLink>
                        <ul 
                            className={`navlink-dropdown-menu-item-dropdown ${nestedDropdownOpen === 'beliefs' ? 'visible' : ''}`}
                            role="menu"
                        >
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'our-beliefs' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('our-beliefs')}
                            onBlur={(e) => handleDropdownBlur(e, 'our-beliefs')}
                            >
                            <NavLink 
                                to="/beliefs" 
                                activeclassname="active" 
                                tabIndex={nestedDropdownOpen === 'beliefs' ? '0' : '-1'}
                            >
                                <span>Our Beliefs</span>
                            </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'membership' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('membership')}
                            onBlur={(e) => handleDropdownBlur(e, 'membership')}
                            >
                            <NavLink 
                                to="/membership" 
                                activeclassname="active" 
                                tabIndex={nestedDropdownOpen === 'beliefs' ? '0' : '-1'}
                            >
                                <span>Church Membership</span>
                            </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'baptism' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('baptism')}
                            onBlur={(e) => handleDropdownBlur(e, 'baptism')}
                            >
                            <NavLink 
                                to="/baptism" 
                                activeclassname="active" 
                                tabIndex={nestedDropdownOpen === 'beliefs' ? '0' : '-1'}
                            >
                                <span>Baptism</span>
                            </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'communion' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('communion')}
                            onBlur={(e) => handleDropdownBlur(e, 'communion')}
                            >
                            <NavLink 
                                to="/communion" 
                                activeclassname="active" 
                                tabIndex={nestedDropdownOpen === 'beliefs' ? '0' : '-1'}
                            >
                                <span>Communion</span>
                            </NavLink>
                            </li>
                            {/* Additional nested items here */}
                        </ul>
                    </li>
                    <li
                        className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'worship' ? 'focused' : ''}`}
                        onMouseEnter={() => handleNestedDropdownOpen('worship')}
                        onMouseLeave={handleNestedDropdownClose}
                        onFocus={() => {
                            handleNestedDropdownOpen('worship');
                            handleDropdownFocus('worship');
                        }}
                        onBlur={(e) => handleDropdownBlur(e, 'worship')}
                        onKeyDown={(e) => handleKeyDown(e, 'nested')}
                        role="menuitem"
                        >
                        <NavLink to="/worship" activeclassname="active" tabIndex={openDropdown === 'info' ? '0' : '-1'}>
                            <span>
                            Worship
                            <i className="fa-solid fa-chevron-down" aria-hidden="true" />
                            </span>
                        </NavLink>
                        <ul 
                            className={`navlink-dropdown-menu-item-dropdown ${nestedDropdownOpen === 'worship' ? 'visible' : ''}`}
                            role="menu"
                        >
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'worship-info' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('worship-info')}
                            onBlur={(e) => handleDropdownBlur(e, 'worship-info')}
                            >
                            <NavLink 
                                to="/worship" 
                                activeclassname="active" 
                                tabIndex={nestedDropdownOpen === 'worship' ? '0' : '-1'}
                            >
                                <span>Worship Info</span>
                            </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'past-sessions' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('past-sessions')}
                            onBlur={(e) => handleDropdownBlur(e, 'past-sessions')}
                            >
                            <NavLink 
                                to="/past-sessions" 
                                activeclassname="active" 
                                tabIndex={nestedDropdownOpen === 'worship' ? '0' : '-1'}
                            >
                                <span>Past Services</span>
                            </NavLink>
                            </li>
                            <li 
                            className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'daily-readings' ? 'focused' : ''}`}
                            role="menuitem"
                            onFocus={() => handleDropdownFocus('daily-readings')}
                            onBlur={(e) => handleDropdownBlur(e, 'daily-readings')}
                            >
                            <NavLink 
                                to="/daily-readings" 
                                activeclassname="active" 
                                tabIndex={nestedDropdownOpen === 'worship' ? '0' : '-1'}
                            >
                                <span>Daily Readings</span>
                            </NavLink>
                            </li>
                        </ul>
                    </li>

                    <li 
                      className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'about' ? 'focused' : ''}`}
                      role="menuitem"
                      onFocus={() => handleDropdownFocus('our-history')}
                      onBlur={(e) => handleDropdownBlur(e, 'our-history')}
                    >
                      <NavLink 
                        to="/our-history" 
                        activeclassname="active" 
                        tabIndex={openDropdown === 'info' ? '0' : '-1'}
                      >
                        <span>Our History</span>
                      </NavLink>
                    </li>

                    <li 
                      className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'announcements' ? 'focused' : ''}`}
                      role="menuitem"
                      onFocus={() => handleDropdownFocus('announcements')}
                      onBlur={(e) => handleDropdownBlur(e, 'announcements')}
                    >
                      <NavLink 
                        to="/announcements" 
                        activeclassname="active" 
                        tabIndex={openDropdown === 'info' ? '0' : '-1'}
                      >
                        <span>Announcements</span>
                      </NavLink>
                    </li>
                    <li 
                      className={`navlink-dropdown-menu-item ${focusedDropdownItem === 'childcare-preschool-dropdown' ? 'focused' : ''}`}
                      role="menuitem"
                      onFocus={() => handleDropdownFocus('childcare-preschool-dropdown')}
                      onBlur={(e) => handleDropdownBlur(e, 'childcare-preschool-dropdown')}
                    >
                      <NavLink 
                        to="/childcare-preschool" 
                        activeclassname="active" 
                        tabIndex={openDropdown === 'info' ? '0' : '-1'}
                      >
                        <span>Childcare & Pre-K</span>
                      </NavLink>
                    </li>


                  </ul>
                </li>

                

                {/* Contact Item */}
                <li 
                  className={`navlink-item ${focusedMainItem === 'contact' ? 'focused' : ''}`}
                  onFocus={() => handleMainFocus('contact')}
                  onBlur={(e) => handleMainBlur(e, 'contact')}
                >
                  <NavLink to="/contact" className="navlink" activeclassname="active">
                    <span className="navlink-text">Contact Us</span>
                  </NavLink>
                </li>
                <li 
                    className={`navlink-item ${focusedMainItem === 'childcare-preschool' ? 'focused' : ''}`}
                    onFocus={() => handleMainFocus('childcare-preschool')}
                    onBlur={(e) => handleMainBlur(e, 'childcare-preschool')}
                    >
                    <NavLink to="/childcare-preschool" className="navlink" activeclassname="active">
                        <span className="navlink-text">Childcare & Pre-K</span>
                    </NavLink>
                </li>
                <li className='navbar-button-wrapper give'>
                  <a href='https://tithe.ly/give_new/www/#/tithely/give-one-time/474438' target='_blank' rel='noopener noreferrer' className='give-link' aria-label="Make an offering (opens in a new tab)">
                    <span>Offering <i className="fa-solid fa-up-right-from-square" aria-hidden="true"></i></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
