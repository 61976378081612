import React, { useState, useEffect } from 'react';
import axios from '../api/axios';
import './styles/DailyReadings.css';
import { Helmet } from 'react-helmet-async';

const DailyReadings = () => {
    const [todayReading, setTodayReading] = useState(null);
    const [yesterdayReading, setYesterdayReading] = useState(null);
    const [upcomingReading, setUpcomingReading] = useState(null);  // Add state for upcoming reading
    const [previousReading, setPreviousReading] = useState(null);  // Add state for previous reading
    const [archiveReadings, setArchiveReadings] = useState([]);
    const [error, setError] = useState(null);

    const today = new Date();
    const todayDate = today.toISOString().split('T')[0];

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const yesterdayDate = yesterday.toISOString().split('T')[0];

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const tomorrowDate = tomorrow.toISOString().split('T')[0];


    useEffect(() => {
        console.log(todayDate);
        console.log(yesterdayDate);
        console.log(tomorrowDate);
    
        const fetchReadings = async () => {
            try {
                const response = await axios.get('/api/daily-readings');
                const readings = response.data;
    
                const normalizedReadings = readings.map((reading) => ({
                    ...reading,
                    normalized_date: new Date(reading.reading_date).toISOString().split('T')[0],
                }));
    
                const sortedReadings = normalizedReadings.sort((a, b) =>
                    new Date(b.normalized_date) - new Date(a.normalized_date)
                );
    
                const todayReadingData = sortedReadings.find(
                    (reading) => reading.normalized_date === todayDate
                );
    
                // Get the most recent reading before today for 'Previous'
                const previousReadingData = sortedReadings.find(
                    (reading) => new Date(reading.reading_date) < new Date(todayDate)
                );
    
                const yesterdayReadingData = sortedReadings.find(
                    (reading) => reading.normalized_date === yesterdayDate
                );
    
                // Get the future readings excluding today's date
                const futureReadings = sortedReadings.filter(
                    (reading) => new Date(reading.reading_date) > new Date(todayDate) // Only future dates
                );
    
                // Filter out today's reading from the future readings
                const futureReadingsWithoutToday = futureReadings.filter(
                    (reading) => reading.normalized_date !== todayDate
                );
    
                // Sort the remaining future readings by the closest date and pick the closest future reading
                const closestFutureReading = futureReadingsWithoutToday.sort((a, b) => 
                    new Date(a.reading_date) - new Date(b.reading_date)  // Sort to get the closest reading
                )[0]; // Take the closest one
    
                setTodayReading(todayReadingData || null);
                setYesterdayReading(yesterdayReadingData || null);
                setUpcomingReading(closestFutureReading || null); // This should now correctly show the closest future reading
                setPreviousReading(previousReadingData || null);
    
                // Filter out today's, yesterday's, and upcoming readings from the archive
                const archive = sortedReadings.filter(
                    (reading) =>
                        reading.normalized_date !== todayReadingData?.normalized_date &&
                        reading.normalized_date !== yesterdayReadingData?.normalized_date &&
                        reading.normalized_date !== closestFutureReading?.normalized_date &&
                        new Date(reading.reading_date) < new Date() // Only include past readings
                );
    
                setArchiveReadings(archive);
            } catch (err) {
                console.error("Error fetching readings:", err);
                setError("Could not load daily readings. Please try again later.");
            }
        };
    
        fetchReadings();
    }, [todayDate, yesterdayDate, tomorrowDate]);    
    
    
    
    

    return (
        <>
            <Helmet>
                <title>Daily Scripture Readings - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Discover daily scripture readings at Cross Roads United Methodist Church, featuring passages for today, yesterday, tomorrow, and an archive of past readings."
                />
                <meta property="og:title" content="Daily Scripture Readings - Cross Roads UMC" />
                <meta
                    property="og:description"
                    content="Explore the daily scripture readings at Cross Roads United Methodist Church. Read passages from today, yesterday, tomorrow, and review past readings in the archive."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/daily-readings" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.crossroadswv.com/daily-readings" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Collection",
                        "name": "Daily Scripture Readings",
                        "description": "Daily scripture readings provided by Cross Roads United Methodist Church.",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com/daily-readings"
                        },
                        "hasPart": [
                            todayReading && {
                                "@type": "Book",
                                "name": "Today’s Scripture Reading",
                                "bookEdition": "Bible",
                                "datePublished": todayReading.reading_date,
                                "text": todayReading.scripture,
                                "isAccessibleForFree": true
                            },
                            yesterdayReading && {
                                "@type": "Book",
                                "name": "Yesterday’s Scripture Reading",
                                "bookEdition": "Bible",
                                "datePublished": yesterdayReading.reading_date,
                                "text": yesterdayReading.scripture,
                                "isAccessibleForFree": true
                            },
                            upcomingReading && {
                                "@type": "Book",
                                "name": upcomingReading.reading_date === tomorrowDate ? "Tomorrow's Scripture Reading" : "Upcoming Scripture Reading",
                                "bookEdition": "Bible",
                                "datePublished": upcomingReading.reading_date,
                                "text": upcomingReading.scripture,
                                "isAccessibleForFree": true
                            },
                            ...archiveReadings.map((reading) => ({
                                "@type": "Book",
                                "name": `Reading on ${new Date(reading.reading_date).toISOString().split('T')[0]}`,
                                "bookEdition": "Bible",
                                "datePublished": reading.reading_date,
                                "text": reading.scripture,
                                "isAccessibleForFree": true
                            }))
                        ].filter(Boolean)
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper dailyreadings'>
                <div className='page-title'>
                    <h1>DAILY READINGS</h1>
                    <hr />
                </div>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='daily-reading-wrapper'>
                            {error ? (
                                <p className="error-message">{error}</p>
                            ) : (
                                <>
                                    <div className='reading-wrapper'>
                                        <h2>{yesterdayReading ? "Yesterday" : previousReading ? "Previous" : "No Previous Reading"}</h2>
                                        <div className='alt-reading'>
                                            <p>
                                                {yesterdayReading
                                                    ? yesterdayReading.scripture
                                                    : previousReading
                                                    ? previousReading.scripture
                                                    : "No reading available"}
                                            </p>
                                        </div>
                                    </div>

                                    <div className='reading-wrapper today'>
                                        <h2>{"Today"}</h2>
                                        <div className='todays-reading'>
                                            <p>{todayReading ? todayReading.scripture : "No reading available"}</p>
                                        </div>
                                    </div>

                                    <div className='reading-wrapper'>
                                        <h2>{upcomingReading && new Date(upcomingReading.reading_date).toISOString().split('T')[0] === tomorrowDate ? "Tomorrow" : "Upcoming"}</h2>
                                        <div className='alt-reading'>
                                            <p>{upcomingReading ? upcomingReading.scripture : "No reading available"}</p>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Past Readings Archive</h2>
                            <div className='archive-wrapper'>
                                {archiveReadings.length === 0 ? (
                                    <p>No past readings available.</p>
                                ) : (
                                    archiveReadings.map((reading) => (
                                        <div key={reading.id} className='archive-item'>
                                            <time dateTime={reading.reading_date}>
                                                <b>{new Date(reading.reading_date).toLocaleDateString()}</b>
                                            </time>{" "}
                                            — {reading.scripture}
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default DailyReadings;

