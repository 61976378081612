
import React, { useEffect, useState } from 'react';
import './styles/Contact.css'
import { Helmet } from 'react-helmet-async';
import DirectorySection from '../components/DirectorySection';

import OurStaff from '../components/OurStaff';
import ContactForm from '../components/ContactForm';
import PrayerRequestForm from '../components/PrayerRequestForm';


const Contact = () => {

    const [recaptchaReady, setRecaptchaReady] = useState(false);

    useEffect(() => {
        const loadRecaptchaScript = () => {
            // Check if reCAPTCHA script already exists to avoid duplicate loading
            if (!document.querySelector('#recaptcha-script')) {
                const script = document.createElement('script');
                script.id = 'recaptcha-script';
                script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
                script.async = true;
                script.defer = true;
                script.onload = () => setRecaptchaReady(true);
                document.body.appendChild(script);
            } else {
                setRecaptchaReady(true); // If already loaded, set ready state
            }
        };

        loadRecaptchaScript();

        // Set up dynamic links based on device
        const isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        const destination = '38.40265580978193, -82.38822698103574';
        const mapsLink = document.getElementById('open-maps');
        mapsLink.href = isIphone ? `maps://?daddr=${destination}` : `https://maps.google.com/?daddr=${destination}`;

        return () => {
            // Optional cleanup for reCAPTCHA script on unmount
            const script = document.getElementById('recaptcha-script');
            if (script) script.remove();
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Contact Cross Roads United Methodist Church - Reach Out to Us</title>
                <meta
                    name="description"
                    content="Contact Cross Roads United Methodist Church for general inquiries, prayer requests, daycare information, and more. Find our location and connect with our staff."
                />
                <meta property="og:title" content="Contact Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Reach out to Cross Roads United Methodist Church for general inquiries, support, and more. Visit us or send a message through our contact form."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/contact" />
                <meta property="og:type" content="website" />
                
                <link rel="canonical" href="https://www.crossroadswv.com/contact" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "PlaceOfWorship",
                        "name": "Cross Roads United Methodist Church",
                        "url": "https://www.crossroadswv.com/contact",
                        "logo": "%PUBLIC_URL%/logo512.png",
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ],
                        "contactPoint": [
                            {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-522-7434",
                                "contactType": "Church Support",
                                "areaServed": "US",
                                "email": "crossroadsumc@comcast.net"
                            },
                            {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-525-6547",
                                "contactType": "Childcare Services",
                                "areaServed": "US",
                                "email": "crccm@comcast.net",
                                "description": "Contact Cross Roads Daycare and Preschool for enrollment and program details."
                            }
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "3146 Saltwell Rd",
                            "addressLocality": "Huntington",
                            "addressRegion": "WV",
                            "postalCode": "25705",
                            "addressCountry": "US"
                        },
                        "hasMap": "https://www.google.com/maps/place/Crossroads+UMC/@38.4024952,-82.3881641,15z/data=!4m2!3m1!1s0x0:0x4b99b1521962cec5?sa=X&ved=1t:2428&ictx=111",
                        "description": "Contact Cross Roads United Methodist Church for information on services, prayer requests, childcare, and support.",
                        "foundingDate": "1892",
                        "keywords": ["church", "contact", "support", "childcare", "United Methodist Church"],
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "38.4024952",
                            "longitude": "-82.3881641"
                        },
                        "department": [
                            {
                                "@type": "Organization",
                                "name": "Cross Roads Daycare and Preschool",
                                "sameAs": "https://www.crossroadswv.com/childcare-preschool",
                                "description": "Providing a safe and nurturing environment for children to learn and grow.",
                                "serviceType": "Preschool and Childcare Services"
                            }
                        ],
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com/contact"
                        }
                    })}
                </script>
            </Helmet>

        <div className='page-wrapper'>
            <div className='page-title'>
                <h1>CONTACT US</h1>
                <hr/>
            </div>
            <section className='page-content-section'>
                <div className='section-content-wrapper'>
                    <div className='contact-info-wrapper'>
                        <div className='text-block-wrapper'>
                            <h2>Where to reach us</h2>
                        </div>
                        <div className='text-block-wrapper'>
                            <div className='text-block'>
                                <p style={{fontWeight: '600', marginTop: '0'}}>
                                    You can reach us at any of the contacts below between 8am and 4pm on weekdays and 8am and 8pm on Sundays and Wednesdays. To reach a specific staff member, please call the main office and request to speak to who you want.
                                    <br/>
                                </p>
                            </div>
                        </div>
                        <div className='text-block-wrapper'>
                            <div className='text-block'>
                                <p>
                                    <span style={{textDecoration: 'underline'}}>Daycare, Preschool, and School-Age</span>
                                    <br/>
                                    <b>PHONE:</b> <a href="tel:304-525-6547">304-525-6547</a>
                                    <br/>
                                    <b>EMAIL:</b> <a href="mailto:crccm@comcast.net">crccm@comcast.net</a>
                                    <br/><br/>
                                    <span style={{textDecoration: 'underline'}}>Main Church Office</span>
                                    <br/>
                                    <b>PHONE:</b> <a href="tel:304-522-7434">304-522-7434</a>
                                    <br/>
                                    <b>EMAIL:</b> <a href="mailto:crossroadsumc@comcast.net">crossroadsumc@comcast.net</a>
                                </p>
                            </div>
                        </div>
                        <div className='text-block-wrapper'>
                            <div className='text-block'>
                                <p>
                                    3146 Saltwell Road
                                    <br/>
                                    Huntington, WV 25705
                                    <br/>
                                    <a 
                                        id='open-maps'
                                        href='/'
                                        aria-label='Get directions to Cross Roads United Methodist Church at 3146 Saltwell Road, Huntington, WV 25705'
                                        target='_blank'
                                    >
                                        (Click here to open directions in Maps)
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div style={{ border: '0', margin: '0', padding: '0' }}>
                            <iframe 
                                title="Map showing location of Cross Roads United Methodist Church" 
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12506.746333748948!2d-82.3881641!3d38.4024952!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8845fd54ce69b05f%3A0x4b99b1521962cec5!2sCrossroads%20UMC!5e0!3m2!1sen!2sus!4v1728971627703!5m2!1sen!2sus"
                                style={{ border: '0', marginBottom: '-0.3rem', padding: '0' }} 
                                width="100%" 
                                height="545"  
                                allowFullScreen="" 
                                loading="lazy" 
                                referrerPolicy="no-referrer-when-downgrade"
                                aria-label="Map of Cross Roads United Methodist Church location"
                            />
                        </div>
                    </div>
                    <div className='contact-form-wrapper'>
                        <ContactForm ready={recaptchaReady} />
                        <PrayerRequestForm ready={recaptchaReady} />
                    </div>
                </div>
            </section>

            <DirectorySection />
            
            <OurStaff />
        </div>
    </>
);

}

export default Contact;
