import React, { useState, useEffect } from "react";
import axios from './../../api/axios';
import './styles/AdminChristmasChild.css';
import { Link } from "react-router-dom";

const AdminChristmasChild = () => {
    const [entries, setEntries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const baseURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchEntries = async () => {
            try {
                const response = await axios.get('/api/operation-christmas-child');
                setEntries(response.data);
            } catch (error) {
                console.error("Failed to fetch entries:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchEntries();
    }, []);

    const handleDelete = async (id) => {
        if (!window.confirm("Are you sure you want to delete this entry?")) return;

        try {
            await axios.delete(`/api/operation-christmas-child/${id}`);
            setEntries((prevEntries) => prevEntries.filter((entry) => entry.id !== id));
        } catch (error) {
            console.error("Failed to delete entry:", error);
        }
    };

    return (
        <>
            <div className="add-new-button">
                <Link to="/admin/operation-christmas-child/create-new">Add New</Link>
            </div>
            <div className="admin-christmas-child">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    entries.map((entry) => (
                        <div className='occ-highlight-wrapper' key={entry.id}>
                            <div className='highlight-year-wrapper'>
                                <h3>{entry.occ_year}</h3>
                            </div>
                            <div className='occ-highlight'>
                                <div className='highlight-image-wrapper'>
                                    <img src={`${baseURL}/uploads/operation_christmas_child/${entry.image_file_name}`} alt={`${entry.occ_year} OCC`} />
                                </div>
                                <div className='highlight-boxes-wrapper'>
                                    <h5>{entry.boxes_filled}</h5>
                                    <p>boxes filled and sent</p>
                                </div>
                                <div className='highlight-desc-wrapper'>
                                    <p>{entry.description}</p>
                                </div> 
                                <div className='actions-wrapper'>
                                    <Link to={`/admin/operation-christmas-child/edit/${entry.id}`}>
                                        Edit
                                    </Link>
                                    <button onClick={() => handleDelete(entry.id)} className="delete-button">Delete</button>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </>
    );
}

export default AdminChristmasChild;
