import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from '../../api/axios';
import './styles/AdminChristmasChild.css';

function AddEditMissionTrip() {
  const { id } = useParams();
  const navigate = useNavigate();
  const editMode = Boolean(id);
  const baseURL = process.env.REACT_APP_API_URL;

  // State for form fields
  const [tripLocation, setTripLocation] = useState('');
  const [initialTripLocation, setInitialTripLocation] = useState(''); // For static image path
  const [tripDate, setTripDate] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [existingImageFileName, setExistingImageFileName] = useState('');
  const [description, setDescription] = useState('');
  const [story, setStory] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Fetch existing data if editing
  useEffect(() => {
        if (editMode) {
            setIsLoading(true);
            axios.get(`/api/mission-trips/${id}`)
                .then(response => {
                    const data = response.data;
                    setTripLocation(data.location);
                    setInitialTripLocation(data.location);

                    // Correct the date formatting to avoid time zone adjustments
                    const date = new Date(data.trip_date);
                    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
                    setTripDate(formattedDate);

                    setDescription(data.description);
                    setStory(data.story || '');
                    setExistingImageFileName(data.image);
                })
                .catch(error => console.error("Failed to fetch mission trip data:", error))
                .finally(() => setIsLoading(false));
        }
    }, [id, editMode]);


  // Handle image selection and preview display
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setImageFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => setImagePreview(reader.result);
      reader.readAsDataURL(selectedFile);
    } else {
      setImagePreview('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state when submission starts
  
    const formData = new FormData();
    formData.append('location', tripLocation);
    formData.append('trip_date', tripDate); // Use `YYYY-MM-DD` format
    formData.append('description', description);
    formData.append('story', story);
    if (imageFile) formData.append('image', imageFile);
  
    try {
      const url = editMode ? `/api/mission-trips/${id}` : '/api/mission-trips';
      const method = editMode ? 'put' : 'post';
      await axios({
        method,
        url,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      navigate("/admin/mission-trips");
    } catch (error) {
      console.error("Error submitting form:", error.response?.data || error.message);
    } finally {
      setIsLoading(false); // Reset loading state after submission
    }
  };
  



  return (
    <>
      <div className="add-new-button form">
        <Link to="/admin/mission-trips">Cancel</Link>
      </div>
      <div className="add-edit-wrapper">
        <div className="add-edit-form">
          <h2>{editMode ? "Edit Mission Trip" : "Add New Mission Trip"}</h2>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="admin-input-wrapper">
              <label htmlFor="trip-location">Title/Location</label>
              <input
                type="text"
                id="trip-location"
                value={tripLocation}
                onChange={(e) => setTripLocation(e.target.value)}
                required
              />
            </div>

            <div className="admin-input-wrapper">
                <label htmlFor="trip-image">Upload Image</label>
                <div className="file-input-preview-wrapper">
                    <input
                    type="file"
                    id="trip-image"
                    accept="image/*"
                    onChange={handleFileChange}
                    />
                    {/* Show new preview if a new image is selected; otherwise, show existing image */}
                    {imagePreview ? (
                    <img src={imagePreview} alt="New preview" className="image-preview" />
                    ) : (
                    editMode && existingImageFileName && (
                        <img
                        src={`${baseURL}/uploads/mission_trips/${initialTripLocation.replace(/[^a-z0-9]/gi, '_').toLowerCase()}/${existingImageFileName}`}
                        alt="Existing entry"
                        className="image-preview"
                        />
                    )
                    )}
                </div>
            </div>


            <div className="admin-input-wrapper">
              <label htmlFor="trip-date">Trip Date</label>
              <input
                type="date"
                id="trip-date"
                value={tripDate}
                onChange={(e) => setTripDate(e.target.value)}
                required
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="trip-description">Description</label>
              <textarea
                id="trip-description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="4"
                required
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="trip-story">Story</label>
              <CKEditor
                editor={ClassicEditor}
                data={story}
                onChange={(event, editor) => setStory(editor.getData())}
              />
            </div>

            <button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : editMode ? 'Update' : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddEditMissionTrip;
