import React, { useState, useEffect } from "react";
import axios from './../../api/axios';
import './styles/AdminDailyReadings.css';
import { Link } from "react-router-dom";

const AdminPastServices = () => {
    const [services, setServices] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get('/api/past-services');
                setServices(response.data);
            } catch (err) {
                console.error("Error fetching past services:", err);
                setError("Failed to load past services.");
            }
        };
        fetchServices();
    }, []);

    // Delete handler function
    const handleDelete = async (id) => {
        if (!window.confirm("Are you sure you want to delete this past service?")) return;

        try {
            await axios.delete(`/api/past-services/${id}`);
            // Remove the deleted service from the UI
            setServices((prevServices) => prevServices.filter((service) => service.id !== id));
        } catch (err) {
            console.error("Error deleting past service:", err);
            setError("Failed to delete the past service.");
        }
    };

    return (
        <>
            <div className="add-new-button">
                <Link to="/admin/past-sessions/create-new">Add New</Link>
            </div>
            {error ? (
                <p className="error-message">{error}</p>
            ) : (
                <section className='past-worship-sessions-wrapper admin'>
                    {services.map((service) => (
                        <div key={service.id} className='past-session-wrapper'>
                            <div className='past-session'>
                                <h3>{new Date(service.service_date).toLocaleDateString()}</h3>
                                <h4>{service.service_type}</h4>
                                <h5>
                                    {service.description.length > 25 
                                        ? service.description.slice(0, 25) + "..." 
                                        : service.description}
                                </h5>
                                <div className="actions-wrapper">
                                    <Link to={`/admin/past-sessions/edit/${service.id}`}>Edit</Link>
                                    <button 
                                        className="delete-button" 
                                        onClick={() => handleDelete(service.id)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </section>
            )}
        </>
    );
};

export default AdminPastServices;
