import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './styles/MobileNavBar.css';
import tempLogo from './../assets/images/umcLogo.webp';

const MobileNavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 785);

  

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    document.querySelector(".hamburger-toggle").focus();
  };

  // Handle keyboard interactions (Enter or Space)
  const handleKeyToggleMenu = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault(); // Prevent default space scroll behavior
      toggleMenu();
    }
  };

  // Close the menu when a NavLink is clicked
  const closeMenu = () => {
    setMenuOpen(false);
  };

  // Detect screen size changes to update `isMobile` state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 785);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {/* Placeholder to reserve space for the navbar */}
      <div className="navbar-placeholder"></div>

      <nav className="mobile-navbar" aria-label="Main navigation">
        <div className="mobile-navbar-wrapper">
          <div className="mobile-navbar-container">
            <div className="mobile-navbar-items">
              {/* Hamburger toggle */}
              <div className="hamburger-container">
                <div
                    className="hamburger-toggle"
                    aria-label="Toggle menu"
                    aria-expanded={menuOpen}
                    aria-controls="main-menu"
                    role="button"
                    aria-pressed={menuOpen}
                    onClick={toggleMenu}
                    onKeyDown={handleKeyToggleMenu}
                    tabIndex={0}
                >
                    <span className={`line top ${menuOpen ? 'open' : ''}`}></span>
                    <span className={`line middle ${menuOpen ? 'open' : ''}`}></span>
                    <span className={`line bottom ${menuOpen ? 'open' : ''}`}></span>
                </div>
              </div>

              {/* Logo */}
              <div className="mobile-navbar-logo-wrapper">
                <div className='logo-wrapper'>
                    <Link to='/' className='logo-link' aria-label="Home">
                        <img src={tempLogo} title='Go Back Home' alt='Cross Roads United Methodist Church Home' width={0.0} height={0.0}></img>
                    </Link>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* Navigation menu */}
        <div className={`mobile-navmenu-wrapper ${menuOpen ? 'open' : 'closed'}`} role="menu" aria-label="Main menu">
            <div className="mobile-socials-wrapper">
                    <div className="socials-container">
                        <div className="socials-row">
                        <a
                            href="https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                            className="social-icon facebook"
                            aria-label="Facebook"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={closeMenu}
                            tabIndex={menuOpen && isMobile ? 0 : -1} // Conditionally tabIndex
                        >
                            <i className="fa-brands fa-facebook-f" />
                        </a>
                        <a
                            href="https://www.google.com/maps/place/Crossroads+UMC/@38.4024952,-82.3881641,15z/data=!4m2!3m1!1s0x0:0x4b99b1521962cec5?sa=X&ved=1t:2428&ictx=111"
                            className="social-icon google"
                            aria-label="Google"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={closeMenu}
                            tabIndex={menuOpen && isMobile ? 0 : -1} // Conditionally tabIndex
                        >
                            <i className="fa-brands fa-google" />
                        </a>
                        </div>
                    </div>
                    </div>
          <div className="mobile-navmenu-container">
            <div className="mobile-navmenu">
              <div className="mobile-navmenu-items">


                {/* Nav links */}
                <div className="mobile-navmenu-navlinks-wrapper">
                <ul className="mobile-navmenu-navlinks">
                  <li className='navlink-item'>
                    <NavLink
                      to="/"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span className='navlink-text'>Home</span>
                    </NavLink>
                  </li>
                  <li className='navlink-item'>
                    <NavLink
                      to="/about"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span className='navlink-text'>Info</span>
                    </NavLink>
                  </li>
                  <li className='navlink-item'>
                    <NavLink
                      to="/beliefs"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span className='navlink-text'>Our Beliefs</span>
                    </NavLink>
                  </li>
                  <li className='navlink-item'>
                    <NavLink
                      to="/membership"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span className='navlink-text'>Church Membership</span>
                    </NavLink>
                  </li>
                  <li className='navlink-item'>
                    <NavLink
                      to="/worship"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span className='navlink-text'>Worship Info</span>
                    </NavLink>
                  </li>
                  <li className='navlink-item'>
                    <NavLink
                      to="/past-sessions"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                      
                    >
                      <span className='navlink-text'> Past Services</span>
                    </NavLink>
                  </li>
                  <li className='navlink-item'>
                    <NavLink
                      to="/daily-readings"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                      
                    >
                      <span className='navlink-text'> Daily Readings</span>
                    </NavLink>
                  </li>
                  <li className='navlink-item'>
                    <NavLink
                      to="/ministries-outreach"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span className='navlink-text'>Ministries/Outreach</span>
                    </NavLink>
                  </li>
                  <li className='navlink-item'>
                    <NavLink
                      to="/contact"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span className='navlink-text'>Contact Us</span>
                    </NavLink>
                  </li>
                  <li className='navlink-item'>
                    <NavLink
                      to="/childcare-preschool"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span className='navlink-text'>Childcare and Pre-K</span>
                    </NavLink>
                  </li>
                  <li className='navbar-button-wrapper give' style={{marginTop: '1rem'}}>
                        <a href='https://tithe.ly/give_new/www/#/tithely/give-one-time/474438' target='_blank' rel='noopener noreferrer' className='give-link'>
                            <span>Offering <i className="fa-solid fa-up-right-from-square"></i></span>
                        </a>
                  </li>
                </ul>

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Always render the overlay and control visibility with styles */}
        <div className={`overlay ${menuOpen ? 'visible' : ''}`} onClick={toggleMenu} role="presentation" aria-hidden={!menuOpen}></div>
      </nav>
    </>
  );
};

export default MobileNavBar;
