import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from '../../api/axios';
import './styles/AdminChristmasChild.css';

function AddEditChildcareStaff() {
  const { id } = useParams();
  const navigate = useNavigate();
  const editMode = Boolean(id);
  const [error, setError] = useState(null);


  // State for form fields
  const [name, setName] = useState('');
  const [team, setTeam] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const teamOptions = [
    "Infant Care Team",
    "Toddler Care Team",
    "2-year-old Care Team",
    "3-year-old Care Team",
    "Pre-K Care Team",
    "School-age Care Team",
    "Kitchen Staff",
    "Director",
    "Assistant Director"
  ];

  // Fetch existing data if editing
  useEffect(() => {
    if (editMode) {
      setIsLoading(true);
      axios.get(`/api/childcare-staff/${id}`)
        .then(response => {
          const data = response.data;
          setName(data.name);
          setTeam(data.team);
        })
        .catch(error => console.error("Failed to fetch childcare staff data:", error))
        .finally(() => setIsLoading(false));
    }
  }, [id, editMode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);  // Clear previous errors
    setIsLoading(true);  // Start loading
  
    const formData = { name, team };
  
    try {
        const url = editMode ? `/api/childcare-staff/${id}` : '/api/childcare-staff';
        const method = editMode ? 'put' : 'post';
        await axios({
            method,
            url,
            data: formData,
            headers: { 'Content-Type': 'application/json' },
        });
        navigate("/admin/childcare-staff");
    } catch (error) {
        console.error("Error submitting form:", error.response?.data || error.message);
        setError(error.response?.data?.error || "An unexpected error occurred. Please try again.");
    } finally {
        setIsLoading(false);  // Stop loading
    }
  };
  

  return (
    <>
      <div className="add-new-button form">
        <Link to="/admin/childcare-staff">Cancel</Link>
      </div>
      <div className="add-edit-wrapper">
        <div className="add-edit-form">
          <h2>{editMode ? "Edit Childcare Staff" : "Add New Childcare Staff"}</h2>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="admin-input-wrapper">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="team">Team/Role</label>
              <select
                id="team"
                value={team}
                onChange={(e) => setTeam(e.target.value)}
                required
              >
                <option value="">Select a team or role</option>
                {teamOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddEditChildcareStaff;
