import React, { useState, useEffect } from "react";
import axios from './../../api/axios';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './styles/AdminFirstResponders.css';

const AdminFirstResponders = () => {
    const [image, setImage] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [galleryImages, setGalleryImages] = useState([]);
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const baseURL = process.env.REACT_APP_API_URL;
    const [isUploading, setIsUploading] = useState(false);


    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get('/api/first-responders/images');
                const images = response.data.map((filename) => ({
                    original: `${baseURL}/uploads/first-responders/${filename}`,
                    thumbnail: `${baseURL}/uploads/first-responders/${filename}`,
                    filename
                }));
                setGalleryImages(images);
            } catch (error) {
                console.error("Failed to load images:", error);
            }
        };
        fetchImages();
    }, [baseURL]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        
        // Check if a file was selected before proceeding
        if (!selectedFile) {
            return; // Exit the function if no file is selected
        }
    
    
        if (!selectedFile.type.startsWith("image/")) {
            alert("Please select a valid image file.");
            return;
        }
        
        setImage(selectedFile);
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        if (!image) {
            alert("Please select an image to upload.");
            return;
        }
    
        const formData = new FormData();
        formData.append('image', image);
    
        setIsUploading(true); // Set uploading to true when starting
    
        try {
            const response = await axios.post('/api/first-responders/upload-image', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            const newImage = {
                original: `${baseURL}/uploads/first-responders/${response.data.fileName}`,
                thumbnail: `${baseURL}/uploads/first-responders/${response.data.fileName}`,
                filename: response.data.fileName
            };
            setGalleryImages((prevImages) => [...prevImages, newImage]);
            setUploadStatus('Image uploaded successfully!');
            setImage(null);
        } catch (error) {
            console.error("Failed to upload image:", error);
            setUploadStatus('Failed to upload image.');
        } finally {
            setIsUploading(false); // Reset uploading state when done
        }
    };
    

    const handleDelete = async (filename) => {
        if (!window.confirm("Are you sure you want to delete this image?")) return;

        try {
            await axios.delete(`/api/first-responders/delete-image`, { data: { filename } });
            setGalleryImages((prevImages) =>
                prevImages.filter((image) => image.filename !== filename)
            );
        } catch (error) {
            console.error("Failed to delete image:", error);
            setUploadStatus('Failed to delete image.');
        }
    };

    const openGallery = (index) => {
        setSelectedIndex(index);
        setIsGalleryOpen(true);
    };

    const closeGallery = () => setIsGalleryOpen(false);

    return (
        <div className="admin-page-wrapper">
            <h2>Upload First Responders Images</h2>
            <form onSubmit={handleUpload} className="upload-form">
                <input type="file" onChange={handleFileChange} accept="image/*" />
                <button type="submit" disabled={isUploading}>
                    {isUploading ? "Uploading Image..." : "Upload Image"}
                </button>
            </form>
            {uploadStatus && <p className="upload-status">{uploadStatus}</p>}

            <h3>Gallery</h3>
            <p>{galleryImages.length} {galleryImages.length === 1 ? 'image' : 'images'}</p>
            <div className="image-grid">
                {galleryImages.map((image, index) => (
                    <div key={index} className="image-item">
                        <div className="gallery-image-wrapper">
                            <img
                                src={image.thumbnail}
                                alt="Gallery thumbnail"
                                onClick={() => openGallery(index)}
                                className="gallery-thumbnail"
                            />
                        </div>
                        <button
                            onClick={() => handleDelete(image.filename)}
                            className="gallery-delete-button"
                        >
                            <i className="fa-solid fa-trash-can"></i>
                        </button>
                    </div>
                ))}
            </div>
            {isGalleryOpen && (
                <div className="gallery-modal" onClick={closeGallery}>
                    <div className="gallery-content" onClick={(e) => e.stopPropagation()}>
                        <ImageGallery
                            items={galleryImages}
                            startIndex={selectedIndex}
                            onSlide={(currentIndex) => setSelectedIndex(currentIndex)}
                            showThumbnails={false}
                            showPlayButton={false}
                            showFullscreenButton={false}
                            onClick={closeGallery}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminFirstResponders;
