import React from 'react';
import './styles/About.css';
import OurStaff from '../components/OurStaff';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import image from './../assets/images/sanctuaryFromPew.webp';

const About = () => {
    return (
        <>
            <Helmet>
                <title>About Cross Roads United Methodist Church - General Info, Services, and Staff</title>
                <meta
                    name="description"
                    content="Learn more about Cross Roads United Methodist Church, including service times, childcare, accessibility options, and our dedicated church staff."
                />
                <meta property="og:title" content="About Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Find information about Cross Roads United Methodist Church services, childcare options, accessibility, and meet our church staff."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/about" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/about" />
                

                {/* JSON-LD Structured Data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "PlaceOfWorship",
                        "name": "Cross Roads United Methodist Church",
                        "url": "https://www.crossroadswv.com/about",
                        "logo": "%PUBLIC_URL%/logo512.png",
                        "sameAs": ["https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"],
                        "contactPoint": [
                            {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-522-7434",
                                "contactType": "Church Support",
                                "areaServed": "US",
                                "email": "crossroadsumc@comcast.net"
                            },
                            {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-525-6547",
                                "contactType": "Childcare Services",
                                "areaServed": "US",
                                "email": "crccm@comcast.net",
                                "description": "Contact Cross Roads Daycare and Preschool for enrollment and program details."
                            }
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "3146 Saltwell Rd",
                            "addressLocality": "Huntington",
                            "addressRegion": "WV",
                            "postalCode": "25705",
                            "addressCountry": "US"
                        },
                        "hasMap": "https://www.google.com/maps/place/Crossroads+UMC/@38.4024952,-82.3881641,15z/data=!4m2!3m1!1s0x0:0x4b99b1521962cec5?sa=X&ved=1t:2428&ictx=111",
                        "description": "Learn more about Cross Roads United Methodist Church, including service times, childcare, accessibility options, and our dedicated church staff.",
                        "foundingDate": "1892",
                        "keywords": ["church", "faith community", "worship", "Sunday services", "accessibility", "childcare", "staff"],
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "38.4024952",
                            "longitude": "-82.3881641"
                        },
                        "department": [
                            {
                                "@type": "Organization",
                                "name": "Cross Roads Daycare and Preschool",
                                "sameAs": "https://www.crossroadswv.com/childcare-preschool",
                                "description": "Providing a safe and nurturing environment for children to learn and grow during Sunday services.",
                                "serviceType": "Preschool and Childcare Services"
                            }
                        ],
                        "amenityFeature": [
                            { "@type": "LocationFeatureSpecification", "name": "Wheelchair Accessible", "value": true },
                            { "@type": "LocationFeatureSpecification", "name": "Accessible Seating", "value": true }
                        ],
                        "staff": [
                            {
                                "@type": "Person",
                                "jobTitle": "Senior Pastor",
                                "telephone": "+1-304-522-7434",
                                "email": "crossroadsumc@comcast.net"
                            },
                            {
                                "@type": "Person",
                                "jobTitle": "Church Secretary and Treasurer",
                                "telephone": "+1-304-522-7434",
                                "email": "crossroadsumc@comcast.net"
                            },
                            {
                                "@type": "Person",
                                "jobTitle": "Director of Children's Ministries",
                                "telephone": "+1-304-522-7434",
                                "email": "crossroadsumc@comcast.net"
                            },
                            {
                                "@type": "Person",
                                "jobTitle": "Music Director",
                                "telephone": "+1-304-522-7434",
                                "email": "crossroadsumc@comcast.net"
                            },
                            {
                                "@type": "Person",
                                "jobTitle": "Nursery Attendant",
                                "telephone": "+1-304-522-7434",
                                "email": "crossroadsumc@comcast.net"
                            }
                        ],
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com/about"
                        }
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper'>
                <div className='page-title'>
                    <h1>GENERAL INFO</h1>
                    <hr />
                </div>

                <section className='page-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='section-text-content'>
                            <h2 id="service-times">Service Times</h2>
                            <div className='info-text-wrapper' aria-labelledby="service-times">
                                <p>Our Sunday Morning Schedule is as follows:</p>
                                <p><b>Sunday School:</b> 11:00 AM – 12:00 PM NOON</p>
                                <p><b>Sunday Worship:</b> 10:00 AM – 11:15 AM (In Person + Online)</p>
                            </div>

                            <h2 id="childcare-classes">Sunday Child Care</h2>
                            <div className='info-text-wrapper' aria-labelledby="childcare-classes">
                                <p>Nursery care is provided during Sunday morning worship. It begins at 9:15AM and is available for newborns up to Pre-K.</p>
                            </div>

                            <div className='info-text-wrapper'>
                                <p>For additional info regarding Sunday and Wednesday worship services, click <Link to='/worship'>here</Link>.</p>
                            </div>

                            <div className='text-separator'>
                                <hr />
                            </div>

                            <h2 id="more-info">More Info</h2>
                            <div className='info-icons-wrapper'>
                                <div className='info-pillar general'>
                                    <div className='info-icon' aria-hidden="true">
                                        <i className="fa-solid fa-circle-info"></i>
                                    </div>
                                    <div className='info-text'>
                                        <p>We aim for a meaningful experience with blended music (contemporary and traditional) all within a 65-70 minute time frame.</p>
                                    </div>
                                </div>
                                <div className='info-pillar'>
                                    <div className='info-icon' aria-hidden="true">
                                        <i className="fa-brands fa-accessible-icon"></i>
                                    </div>
                                    <div className='info-text'>
                                        <p>We provide a wheelchair ramp, accessible seating, and listening devices so everyone feels welcome.</p>
                                    </div>
                                </div>
                                <div className='info-pillar'>
                                    <div className='info-icon' aria-hidden="true">
                                        <i className="fa-solid fa-glass-water" />
                                    </div>
                                    <div className='info-text'>
                                        <p>Communion is celebrated on the first Sunday of each month and everyone is welcome to join.</p>
                                    </div>
                                </div>
                                <div className='info-pillar'>
                                    <div className='info-icon' aria-hidden="true">
                                        <i className="fa-solid fa-child-reaching" />
                                    </div>
                                    <div className='info-text'>
                                        <p>There is a nursery available for children 2 and under. The nursery is located off the narthex on the left. </p>
                                    </div>
                                </div>
                                <div className='info-pillar'>
                                    <div className='info-icon' aria-hidden="true">
                                        <i className="fa-solid fa-restroom"></i>
                                    </div>
                                    <div className='info-text'>
                                        <p>Restrooms are located down the hall on the right from the sanctuary entrance.</p>
                                    </div>
                                </div>
                                
                                <div className='info-pillar'>
                                    <div className='info-icon' aria-hidden="true">
                                        <i className="fa-solid fa-phone-volume"></i>
                                    </div>
                                    <div className='info-text'>
                                        <p>We kindly ask that you keep cellular devices on mute or off during the service. Thanks.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='text-separator'>
                                <hr />
                            </div>
                            <div className='info-text-wrapper'>
                                <p>If you're looking for info regarding our child-care and preschool programs, click <Link to='/childcare-preschool'>here</Link>.</p>
                            </div>
                        </div>

                        <div className='section-image-column'>
                            <img loading='eager' src={image} alt="Sanctuary of Cross Roads United Methodist Church" className="membership-image"/>
                        </div>
                    </div>
                </section>
                <OurStaff />
            </div>
        </>
    );
};

export default About;
