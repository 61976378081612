import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from '../../api/axios';
import './styles/AdminChristmasChild.css';

function AddEditChristmasChild() {
  const { id } = useParams();
  const navigate = useNavigate();
  const editMode = Boolean(id);
  const baseURL = process.env.REACT_APP_API_URL;

  // State for form fields
  const [occYear, setOccYear] = useState('');
  const [imageFile, setImageFile] = useState(null); 
  const [existingImageFileName, setExistingImageFileName] = useState(''); 
  const [boxesFilled, setBoxesFilled] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(''); 

  // Fetch existing data if editing
  useEffect(() => {
    if (editMode) {
      setIsLoading(true);
      axios.get(`/api/operation-christmas-child/${id}`)
        .then(response => {
          const data = response.data;
          setOccYear(data.occ_year);
          setBoxesFilled(data.boxes_filled);
          setDescription(data.description);
          setExistingImageFileName(data.image_file_name);
        })
        .catch(error => {
          console.error("Failed to fetch data:", error);
          setError('Failed to load data.');
        })
        .finally(() => setIsLoading(false));
    }
  }, [id, editMode]);

  // Validation before submission
  const validateInputs = () => {
    if (!occYear || occYear < 1901 || occYear > 2155) {
      setError('Please enter a year between 1901 and 2155.');
      return false;
    }
    if (!boxesFilled || boxesFilled <= 0) {
      setError('Please enter a positive number for boxes filled.');
      return false;
    }
    if (!description || description.length > 500) {
      setError('Please provide a description under 500 characters.');
      return false;
    }
    return true;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');  // Clear previous errors
    setIsLoading(true);  // Set loading state
  
    if (!validateInputs()) {
      setIsLoading(false);  // Reset loading state on validation failure
      return;
    }
  
    const formData = new FormData();
    formData.append('occ_year', occYear.toString());
    formData.append('boxes_filled', boxesFilled.toString());
    formData.append('description', description);
    if (imageFile) formData.append('image', imageFile);
  
    try {
      const url = editMode ? `/api/operation-christmas-child/${id}` : '/api/operation-christmas-child';
      const method = editMode ? 'put' : 'post';
      await axios({
        method,
        url,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      navigate("/admin/operation-christmas-child");
    } catch (error) {
      console.error("Error submitting form:", error.response?.data || error.message);
      setError("Failed to save entry. Please try again.");
    } finally {
      setIsLoading(false);  // Reset loading state after submission
    }
  };
  

  return (
    <>
      <div className="add-new-button form">
        <Link to="/admin/operation-christmas-child">Cancel</Link>
      </div>
      <div className="add-edit-wrapper">
        <div className="add-edit-form">
          <h2>{editMode ? "Edit Christmas Child Entry" : "Add New Christmas Child Entry"}</h2>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="admin-input-wrapper">
              <label htmlFor="occYear">Year</label>
              <input
                type="number"
                id="occYear"
                value={occYear}
                onChange={(e) => setOccYear(e.target.value)}
                required
                min="1900"
                max="2155"
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="imageFile">Upload Image</label>
              <div className="file-input-preview-wrapper">
                <input
                  type="file"
                  id="imageFile"
                  accept="image/*"
                  onChange={(e) => setImageFile(e.target.files[0])}
                />
                {editMode && existingImageFileName && !imageFile && (
                  <div className="existing-image-preview">
                    <img src={`${baseURL}/uploads/operation_christmas_child/${existingImageFileName}`} alt="Existing entry" />
                  </div>
                )}
              </div>
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="boxesFilled">Boxes Filled</label>
              <input
                type="number"
                id="boxesFilled"
                value={boxesFilled}
                onChange={(e) => setBoxesFilled(e.target.value)}
                required
                min="1"
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="4"
                maxLength="500"
                required
              />
            </div>

            <button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : 'Submit'}
            </button>

          </form>
        </div>
      </div>
    </>
  );
}

export default AddEditChristmasChild;
