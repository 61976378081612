import React, { useState, useEffect } from "react";
import axios from './../../api/axios';
import './styles/AdminAnnouncements.css';
import { Link } from "react-router-dom";

const AdminAnnouncements = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchAnnouncements = async () => {
            try {
                const response = await axios.get('/api/announcements');
                setAnnouncements(response.data);
            } catch (error) {
                console.error("Failed to fetch announcements:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchAnnouncements();
    }, []);

    const handleDelete = async (id) => {
        if (!window.confirm("Are you sure you want to delete this announcement?")) return;

        try {
            await axios.delete(`/api/announcements/${id}`);
            setAnnouncements((prevAnnouncements) => prevAnnouncements.filter((announcement) => announcement.id !== id));
        } catch (error) {
            console.error("Failed to delete announcement:", error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
    };

    const truncateText = (text, limit) => {
        return text.length > limit ? text.substring(0, limit) + "..." : text;
    };

    return (
        <>
            <div className="add-new-button">
                <Link to="/admin/announcements/create-new">Create New</Link>
            </div>
            <div className="announcements-wrapper admin">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    announcements.map((announcement) => (
                        <div className="announcement-item" key={announcement.id}>
                            <h3>{announcement.subject}</h3> {/* Displaying the subject */}
                            <p>{truncateText(announcement.announcement, 100)}</p>
                            <p><strong>Submitted:</strong> {formatDate(announcement.submitted_at)}</p>
                            {announcement.expires ? (
                                <p><strong>Expires:</strong> {formatDate(announcement.expiration_date)}</p>
                            ) : (
                                <p><strong>Expires?</strong> NO</p>
                            )}
                            <div className="actions-wrapper">
                                <Link to={`/admin/announcements/edit/${announcement.id}`}>Edit</Link>
                                <button onClick={() => handleDelete(announcement.id)} className="delete-button">Delete</button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </>
    );
};

export default AdminAnnouncements;
