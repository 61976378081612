import React, { useState, useEffect } from 'react';
import './styles/Baptism.css';
import { Helmet } from 'react-helmet-async';
import Slider from 'react-slick';
import axios from './../api/axios';


import santa from './../assets/images/santa.webp';
import lightUpNight1 from './../assets/images/lightUpNight1.webp';
import lightUpNight2 from './../assets/images/lightUpNight2.webp';

import childrenChristmasPlay from './../assets/images/childrenChristmasPlay.webp';
import parentChildDance1 from './../assets/images/parentChildDance1.webp';


import easter from './../assets/images/easter.webp';

import trunkOrTreat from './../assets/images/trunkOrTreat.webp';
import ImageGalleryComponent from '../components/ImageGalleryComponent';


const Holidays = () => {

    const [activeSlide, setActiveSlide] = useState(0);
    const [galleryImages, setGalleryImages] = useState([]);
    const baseURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get('/api/holidays/images');
                const images = response.data.map((filename) => ({
                    original: `${baseURL}/uploads/holidays/${filename}`,
                    thumbnail: `${baseURL}/uploads/holidays/${filename}`,
                }));
                setGalleryImages(images);
            } catch (error) {
                console.error("Failed to load images:", error);
            }
        };
        fetchImages();
    }, [baseURL]); // Added baseURL as a dependency

    const settings = {
        dots: true,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        arrows: false,
        beforeChange: (current, next) => setActiveSlide(next),
        afterChange: (current) => document.querySelector(`[data-index="${current}"] img`).focus()
    };

    const christmasImages = [santa, lightUpNight1, lightUpNight2, childrenChristmasPlay, parentChildDance1];


    const christmasCaptions = [
        "Santa visiting during our holiday festivities.",
        "Light Up Night: A magical evening at the church.",
        "Another beautiful moment from Light Up Night.",
        "Children's Christmas play spreading joy and laughter.",
        "A fun-filled evening at the parent-child dance."
    ];

    return (
        <>
            <Helmet>
                <title>Holiday Festivities - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Celebrate the joy of the holiday season with Cross Roads United Methodist Church. Join us for Christmas celebrations, Easter activities, and Halloween Trunk or Treat."
                />
                <meta property="og:title" content="Holiday Festivities - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Experience the joy and community of holiday festivities at Cross Roads UMC, including Christmas events, Easter egg hunts, and Halloween Trunk or Treat."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/holidays" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/holidays" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "name": "Holiday Festivities at Cross Roads UMC",
                        "description": "Celebrate the holiday season with Cross Roads United Methodist Church through Christmas events, Easter egg hunts, and Halloween Trunk or Treat.",
                        "url": "https://www.crossroadswv.com/holidays",
                        "startDate": "2024-12-01",
                        "endDate": "2024-12-31",
                        "location": {
                            "@type": "Place",
                            "name": "Cross Roads United Methodist Church",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "3146 Saltwell Rd",
                                "addressLocality": "Huntington",
                                "addressRegion": "WV",
                                "postalCode": "25705",
                                "addressCountry": "US"
                            }
                        },
                        "organizer": {
                            "@type": "Organization",
                            "name": "Cross Roads United Methodist Church",
                            "url": "https://www.crossroadswv.com"
                        },
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ]
                    })}
                </script>
            </Helmet>


            <div className='page-wrapper first-responders'>
                <div className='page-title'>
                    <h1>HOLIDAY FESTIVITIES</h1>
                    <hr/>
                </div>


                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Christmas</h2>
                            <div className='text-content'>
                                <p>At Cross Roads UMC, Christmas is a season filled with celebration, generosity, and community. We take part in a variety of activities that bring the spirit of the season to life. Our toy drives ensure children in need have gifts to brighten their holiday, and our outreach programs spread cheer to those who may be struggling during this time of year.</p>
                            </div>
                            <br/>
                            <div className='text-content'>
                                <p>In addition to giving back, we celebrate with festive gatherings, children’s Christmas plays, and music that fills the church with joy. Families come together to share in the warmth of the season through decorating, crafts, and storytelling, creating cherished memories.</p>
                            </div>
                            <br/>
                            <div className='text-content'>
                                <p>These moments of service and celebration reflect the true meaning of Christmas—spreading love, hope, and kindness to all.</p>
                            </div>
                        </div>
                        <figure className='section-image-placeholder'>
                            <Slider {...settings}>
                                {christmasImages.map((image, index) => (
                                    <div
                                        key={index}
                                        data-index={index}
                                        tabIndex={activeSlide === index ? 0 : -1} // Only focusable when active
                                        className="carousel-item"
                                    >
                                        <img
                                            src={image}
                                            alt={christmasCaptions[index]}
                                            style={{ pointerEvents: activeSlide === index ? 'auto' : 'none' }}
                                        />
                                        <figcaption className="carousel-caption">{christmasCaptions[index]}</figcaption>
                                    </div>
                                ))}
                            </Slider>
                        </figure>
                    </div>
                </section>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Easter</h2>
                            <div className='text-content'>
                                <p>At Cross Roads UMC, Easter is a time of celebration, reflection, and joyful activities for all ages. We come together as a church family to honor the resurrection of Christ through meaningful worship services and community fellowship.</p>
                            </div>
                            <br/>
                            <div className='text-content'>
                                <p>Our Easter traditions include Easter egg hunts for children, where they can enjoy the excitement of finding brightly colored eggs filled with treats, creating moments of joy and connection. Families gather for special events like crafts and storytelling that share the message of hope and renewal at the heart of the Easter season.</p>
                            </div>
                            <br/>
                            <div className='text-content'>
                                <p>Through these celebrations, we embrace the spirit of Easter, blending fun and faith to create a meaningful experience for our church and community. It’s a time to come together, rejoice, and reflect on the love and grace that Easter represents.</p>
                            </div>
                            
                            
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={easter} alt=""/>
                        </div>
                    </div>
                </section>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Halloween Trunk or Treat</h2>
                            <div className='text-content'>
                                <p>At Cross Roads UMC, Halloween is a time for creativity, community, and fun for all ages. Our Trunk or Treat event is a highlight of the season, where members decorate their car trunks with creative themes and hand out candy to excited children in a safe and welcoming environment. Families and kids come dressed in their favorite costumes, adding to the festive atmosphere.</p>
                            </div>
                            <br/>
                            <div className='text-content'>
                                <p>Beyond the candy and costumes, the event provides an opportunity for fellowship and connection with our community. From games and activities to moments of laughter and joy, we aim to create lasting memories for children and their families.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={trunkOrTreat} alt=""/>
                        </div>
                    </div>
                </section>
                <section className='gallery-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Holiday Highlights</h2>
                            <div className='gallery-wrapper' aria-label="Gallery of images showcasing Common Grounds events and meals" aria-live="polite">
                                <ImageGalleryComponent images={galleryImages} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Holidays;