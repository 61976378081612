import React, { useEffect, useState } from 'react';
import axios from './../api/axios';
import './styles/OurStaff.css';

const OurStaff = () => {
    const [staffMembers, setStaffMembers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStaff = async () => {
            try {
                const response = await axios.get('/api/church-staff');
                const sortedStaff = response.data.sort((a, b) => {
                    // Sort logic: "pastor" role comes first
                    if (a.role_title.toLowerCase() === 'pastor') return -1;
                    if (b.role_title.toLowerCase() === 'pastor') return 1;
                    return 0; // Maintain original order for others
                });
                setStaffMembers(sortedStaff);
            } catch (error) {
                console.error("Error fetching staff members:", error);
                setError("Could not load staff members. Please try again later.");
            }
        };
        fetchStaff();
    }, []);

    // Helper function to format phone number
    const formatPhoneNumber = (phone) => {
        if (!phone) return '';
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    };

    return (
        <section className='staff-section-wrapper' aria-labelledby="staff-heading">
            <h2 id="staff-heading">Our Church Staff</h2>
            <div className='staff-row-wrapper'>
                {error ? (
                    <p className="error-message" role="alert">{error}</p>
                ) : (
                    staffMembers.map((member) => (
                        <div key={member.id} className='staff-member-wrapper'>
                            <div className='staff-member'>
                                <div className='staff-member-image'>
                                    {member.image ? (
                                        <img 
                                            src={`${process.env.REACT_APP_API_URL}/uploads/church-staff/${member.image}`} 
                                            alt={`${member.name}, ${member.role_title}`}
                                        />
                                    ) : (
                                        <div className="staff-placeholder" aria-label={`${member.name ? member.name : 'Church staff member'}`}>
                                            {/* You can replace the text with an icon if preferred */}
                                            {member.name ? member.name.split(" ")[0] : "Staff"}
                                        </div>
                                    )}
                                </div>

                                <div className='staff-member-info-wrapper'>
                                    <div className='staff-member-info'>
                                        <div className='info-line'>
                                            <h3>{member.name}</h3>
                                        </div>
                                        <div className='info-line'>
                                            <p>{member.role_title}</p>
                                        </div>
                                        {member.email && member.email !== 'none' && (
                                            <div className='info-line'>
                                                <a href={`mailto:${member.email}`} aria-label={`Email ${member.name}`}>
                                                    {member.email}
                                                </a>
                                            </div>
                                        )}
                                        {member.phone && member.phone !== 'none' && (
                                            <div className='info-line'>
                                                <a href={`tel:${member.phone}`} aria-label={`Call ${member.name}`}>
                                                    {formatPhoneNumber(member.phone)}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </section>
    );
};

export default OurStaff;
