import React, { useState, useEffect } from 'react';
import axios from './../api/axios';
import './styles/CommonGrounds.css';
import { Helmet } from 'react-helmet-async';

import banner from './../assets/images/commonGrounds1.webp';
import ImageGalleryComponent from '../components/ImageGalleryComponent';

const CommonGrounds = () => {
    const [galleryImages, setGalleryImages] = useState([]);
    const baseURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get('/api/common-grounds/images');
                const images = response.data.map((filename) => ({
                    original: `${baseURL}/uploads/common-grounds/${filename}`,
                    thumbnail: `${baseURL}/uploads/common-grounds/${filename}`,
                }));
                setGalleryImages(images);
            } catch (error) {
                console.error("Failed to load images:", error);
            }
        };
        fetchImages();
    }, [baseURL]); // Added baseURL as a dependency

    return (
        <>
            <Helmet>
                <title>Common Grounds - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Common Grounds is a community outreach program by Cross Roads United Methodist Church, providing meals and fellowship to those in need. Join us in building a supportive and welcoming environment."
                />
                <meta property="og:title" content="Common Grounds - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Discover Common Grounds, a ministry by Cross Roads United Methodist Church that serves meals to those in need, offering a warm space for fellowship and connection."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/common-grounds" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="%PUBLIC_URL%/commonGrounds1.jpg" />

                <link rel="canonical" href="https://www.crossroadswv.com/common-grounds" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": ["Organization", "Service"],
                        "name": "Common Grounds Community Outreach",
                        "description": "Common Grounds, a ministry of Cross Roads United Methodist Church, provides meals and a welcoming space for fellowship to support individuals and families in need.",
                        "url": "https://www.crossroadswv.com/common-grounds",
                        "image": "%PUBLIC_URL%/commonGrounds1.jpg",
                        "location": {
                            "@type": "Place",
                            "name": "Cross Roads United Methodist Church",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "3146 Saltwell Rd",
                                "addressLocality": "Huntington",
                                "addressRegion": "WV",
                                "postalCode": "25705",
                                "addressCountry": "US"
                            }
                        },
                        "event": {
                            "@type": "SocialEvent",
                            "name": "Common Grounds Meal Service",
                            "description": "A community meal service that fosters fellowship and provides food for those in need.",
                            "location": {
                                "@type": "Place",
                                "name": "Cross Roads United Methodist Church",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "3146 Saltwell Rd",
                                    "addressLocality": "Huntington",
                                    "addressRegion": "WV",
                                    "postalCode": "25705",
                                    "addressCountry": "US"
                                }
                            }
                        },
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+1-304-522-7434",
                            "contactType": "Church Support",
                            "areaServed": "US",
                            "availableLanguage": "English",
                            "email": "crossroadsumc@comcast.net"
                        },
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com/common-grounds"
                        }
                    })}
                </script>
            </Helmet>
            
            <div className='page-wrapper common-grounds'>
                <div className='page-title'>
                    <h1>COMMON GROUNDS</h1>
                    <hr/>
                </div>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>About Common Grounds</h2>
                            <div className='text-content'>
                                <p>Common Grounds is our community outreach program designed to provide meals and fellowship to those in need. Whether it's a warm bowl of soup, a homemade meal, or a place to gather for conversation, Common Grounds offers a welcoming space for anyone seeking nourishment and connection. Our volunteers prepare and serve meals throughout the week, aiming to build a sense of community and support for individuals and families. It’s more than just food—it's about sharing God’s love through service and hospitality.</p>
                            </div>
                        </div>
                        <figure className='section-image-placeholder'>
                            <img src={banner} alt='Group of smiling volunteers standing in a kitchen, each holding a small coffee cup as they prepare to serve meals at the Common Grounds community outreach program.' />
                            <figcaption className='carousel-caption'>Volunteers preparing to serve meals as part of the Common Grounds outreach program.</figcaption>
                        </figure>
                    </div>
                </section>

                <section className='gallery-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Gallery + Highlights</h2>
                            <div className='gallery-wrapper' aria-label="Gallery of images showcasing Common Grounds events and meals" aria-live="polite">
                                <ImageGalleryComponent images={galleryImages} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default CommonGrounds;
