import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from '../../api/axios';
import './styles/AdminDailyReadings.css';

function AddEditDailyReadings() {
  const { id } = useParams();
  const navigate = useNavigate();
  const editMode = Boolean(id);

  // State for form fields
  const [readingDate, setReadingDate] = useState('');
  const [scripture, setScripture] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch existing data if editing
  useEffect(() => {
    if (editMode) {
      setIsLoading(true);
      axios.get(`/api/daily-readings/${id}`)
        .then(response => {
          const data = response.data;
          setReadingDate(new Date(data.reading_date).toISOString().split('T')[0]);
          setScripture(data.scripture);
        })
        .catch(error => {
          console.error("Failed to fetch data:", error);
          setErrorMessage('Failed to load reading data.');
        })
        .finally(() => setIsLoading(false));
    }
  }, [id, editMode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { reading_date: readingDate, scripture };
    setIsLoading(true);
    setErrorMessage('');

    try {
      const url = editMode ? `/api/daily-readings/${id}` : '/api/daily-readings';
      const method = editMode ? 'put' : 'post';
      await axios({
        method,
        url,
        data: formData,
        headers: { 'Content-Type': 'application/json' },
      });
      navigate("/admin/daily-readings");
    } catch (error) {
      console.error("Error submitting form:", error.response?.data || error.message);
      setErrorMessage('Failed to submit the form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="add-new-button form">
        <Link to="/admin/daily-readings">Cancel</Link>
      </div>
      <div className="add-edit-wrapper">
        <div className="add-edit-form">
          <h2>{editMode ? "Edit Daily Reading Entry" : "Add New Daily Reading Entry"}</h2>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <form onSubmit={handleSubmit}>
            <div className="admin-input-wrapper">
              <label htmlFor="reading-date">Reading Date</label>
              <input
                type="date"
                id="reading-date"
                value={readingDate}
                onChange={(e) => setReadingDate(e.target.value)}
                required
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="scripture">Scripture</label>
              <input
                type="text"
                id="scripture"
                value={scripture}
                onChange={(e) => setScripture(e.target.value)}
                required
              />
            </div>

            <button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddEditDailyReadings;