import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from '../api/axios';

const DirectorySection = () => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [directoryFormData, setDirectoryFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        zip: '',
        birthday: ''
    });
    const recaptchaToken = useRef(null); // useRef for reCAPTCHA token
    const [recaptchaVisible, setRecaptchaVisible] = useState(false); // Controls reCAPTCHA visibility
    const [isSubmittingDirectory, setIsSubmittingDirectory] = useState(false);
    const [directoryFormStatus, setDirectoryFormStatus] = useState({ success: null, message: '' });
    const [formReady, setFormReady] = useState(false); // Tracks form readiness

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    const handleDirectoryChange = (e) => {
        const { id, value } = e.target;
        const updatedFormData = { ...directoryFormData, [id]: formatInput(id, value) };
        setDirectoryFormData(updatedFormData);

        // Display reCAPTCHA when all fields are filled
        const allFieldsFilled = Object.values(updatedFormData).every(field => field.trim() !== '');
        setRecaptchaVisible(allFieldsFilled);
        setFormReady(allFieldsFilled && recaptchaToken.current !== null); // Update form readiness
    };

    const formatInput = (id, value) => {
        if (id === 'phone') {
            const numbers = value.replace(/[^\d]/g, '');
            if (numbers.length > 6) return `${numbers.slice(0, 3)}-${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
            if (numbers.length > 3) return `${numbers.slice(0, 3)}-${numbers.slice(3, 6)}`;
            return numbers.slice(0, 12);
        } else if (id === 'zip') {
            return value.replace(/[^\d]/g, '').slice(0, 5);
        }
        return value;
    };

    const handleDirectoryRecaptchaChange = (token) => {
        recaptchaToken.current = token;

        // Update form readiness when reCAPTCHA is completed
        const allFieldsFilled = Object.values(directoryFormData).every(field => field.trim() !== '');
        setFormReady(allFieldsFilled && token !== null);
    };

    const handleDirectorySubmit = async (e) => {
        e.preventDefault();
        if (!recaptchaToken.current) {
            setDirectoryFormStatus({ success: false, message: 'Please complete the reCAPTCHA.' });
            return;
        }
        setIsSubmittingDirectory(true);
        try {
            await axios.post('/api/directory-entry', { ...directoryFormData, recaptchaToken: recaptchaToken.current });
            setDirectoryFormStatus({ success: true, message: 'Directory entry submitted successfully!' });
            setDirectoryFormData({ firstName: '', lastName: '', phone: '', email: '', address: '', city: '', zip: '', birthday: '' });
            recaptchaToken.current = null; // Reset reCAPTCHA token
            setRecaptchaVisible(false); // Hide reCAPTCHA
            setFormReady(false); // Reset form readiness
        } catch (error) {
            console.error('Error submitting directory entry:', error);
            setDirectoryFormStatus({ success: false, message: 'Failed to submit directory entry. Please try again.' });
        } finally {
            setIsSubmittingDirectory(false);
        }
    };

    return (
        <>
            <h3 style={{ color: 'rgba(72, 65, 65, 1)', fontFamily: '"Titillium Web", "Titillium Web Fallback"' }}>
                Church Directory
            </h3>
            {/** 
            <a href='/' rel="noopener noreferrer" className='directory-button'>
                Church Directory PDF &nbsp;<i className="fa-solid fa-file-pdf"></i>
            </a>
            <br />
            */}

            <button
                onClick={toggleFormVisibility}
                className='show-directory-form'
                aria-expanded={isFormVisible}
                aria-controls="church-directory-form"
            >
                Click here to be added to the online church directory.
            </button>

            <section
                id="church-directory-form"
                className={`church-directory-form-section ${isFormVisible ? 'visible' : ''}`}
                aria-hidden={!isFormVisible}
            >
                <h4>Please complete the following to be included in our online church directory.</h4>
                <div className='church-directory-form-wrapper'>
                    <form onSubmit={handleDirectorySubmit}>
                        <div className='input-wrapper'>
                            <label htmlFor="firstName">NAME (First and Last)</label>
                            <div className='name-fields-wrapper'>
                                <input
                                    id="firstName"
                                    type='text'
                                    placeholder="First Name"
                                    value={directoryFormData.firstName}
                                    onChange={handleDirectoryChange}
                                    required
                                    tabIndex={isFormVisible ? 0 : -1}
                                />
                                <input
                                    id="lastName"
                                    type='text'
                                    placeholder="Last Name"
                                    value={directoryFormData.lastName}
                                    onChange={handleDirectoryChange}
                                    required
                                    tabIndex={isFormVisible ? 0 : -1}
                                />
                            </div>
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="phone">PHONE</label>
                            <input
                                id="phone"
                                type='text'
                                placeholder="e.g., 123-456-7890"
                                value={directoryFormData.phone}
                                onChange={handleDirectoryChange}
                                required
                                tabIndex={isFormVisible ? 0 : -1}
                            />
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="email">EMAIL</label>
                            <input
                                id="email"
                                type='email'
                                placeholder="example@domain.com"
                                value={directoryFormData.email}
                                onChange={handleDirectoryChange}
                                required
                                tabIndex={isFormVisible ? 0 : -1}
                            />
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="address">ADDRESS</label>
                            <input
                                id="address"
                                type='text'
                                placeholder="Street Address"
                                value={directoryFormData.address}
                                onChange={handleDirectoryChange}
                                required
                                tabIndex={isFormVisible ? 0 : -1}
                            />
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="city">CITY</label>
                            <input
                                id="city"
                                type='text'
                                placeholder="City"
                                value={directoryFormData.city}
                                onChange={handleDirectoryChange}
                                required
                                tabIndex={isFormVisible ? 0 : -1}
                            />
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="zip">ZIP</label>
                            <input
                                id="zip"
                                type='text'
                                placeholder="e.g., 12345"
                                value={directoryFormData.zip}
                                onChange={handleDirectoryChange}
                                required
                                tabIndex={isFormVisible ? 0 : -1}
                            />
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="birthday">BIRTHDAY</label>
                            <input
                                id="birthday"
                                type='date'
                                placeholder="MM-DD"
                                value={directoryFormData.birthday}
                                onChange={handleDirectoryChange}
                                required
                                tabIndex={isFormVisible ? 0 : -1}
                            />
                        </div>

                        {/* Conditional reCAPTCHA display */}
                        {recaptchaVisible && (
                            <div className='captcha-scale'>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                    onChange={handleDirectoryRecaptchaChange}
                                    tabIndex={isFormVisible ? 0 : -1}
                                />
                            </div>
                        )}

                        {/* Status Message */}
                        {directoryFormStatus.message && (
                            <p
                                className={`directory form-status ${
                                    directoryFormStatus.success ? 'success' : 'error'
                                }`}
                                tabIndex={isFormVisible ? 0 : -1}
                            >
                                {directoryFormStatus.message}
                            </p>
                        )}

                        {/* Submit Button */}
                        <div className='submit-button-wrapper directory'>
                            <button type="submit" disabled={isSubmittingDirectory || !formReady} tabIndex={isFormVisible ? 0 : -1}>
                                {isSubmittingDirectory ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </form>

                </div>
                <p className='directory-info-text'>
                    After you send your info and we add you to the directory,
                    get the <u><b>Instant Church Directory</b></u> app for your
                    Android, iPhone, or iPad and follow the instructions. Enjoy!
                </p>
            </section>
        </>
    );
};

export default DirectorySection;
