import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from '../../api/axios';
import './styles/AdminDailyReadings.css';

function AddEditVerseOfDay() {
  const { id } = useParams();
  const navigate = useNavigate();
  const editMode = Boolean(id);

  // State for form fields
  const [verseDate, setVerseDate] = useState('');
  const [scriptureLocation, setScriptureLocation] = useState('');
  const [verseText, setVerseText] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isPersistent, setIsPersistent] = useState(false); // New state for persistent
  const [isLoading, setIsLoading] = useState(false);

  // Fetch existing data if editing
  useEffect(() => {
    if (editMode) {
      setIsLoading(true);
      axios.get(`/api/verse-of-the-day/${id}`)
        .then(response => {
          const data = response.data;
          const formattedDate = new Date(data.date).toISOString().split("T")[0];
          setVerseDate(formattedDate);
          setScriptureLocation(data.scripture_location);
          setVerseText(data.verse_text);
          
          setIsActive(data.is_active === 1);
          setIsPersistent(data.persistent === 1);
        })
        .catch(error => console.error("Failed to fetch data:", error))
        .finally(() => setIsLoading(false));
    }
  }, [id, editMode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);  // Set loading to true when starting the submission
  
    const formData = {
      date: verseDate,
      scripture_location: scriptureLocation,
      verse_text: verseText,
      is_active: isActive,  // Sending as boolean
      persistent: isPersistent,  // New field for persistent status
    };
  
    try {
      const url = editMode ? `/api/verse-of-the-day/${id}` : '/api/verse-of-the-day';
      const method = editMode ? 'put' : 'post';
      await axios({
        method,
        url,
        data: formData,
        headers: { 'Content-Type': 'application/json' },
      });
      navigate("/admin/verse-of-the-day");
    } catch (error) {
      console.error("Error submitting form:", error.response?.data || error.message);
    } finally {
      setIsLoading(false);  // Reset loading state after submission
    }
  };
  

  return (
    <>
      <div className="add-new-button form">
        <Link to="/admin/verse-of-the-day">Cancel</Link>
      </div>
      <div className="add-edit-wrapper">
        <div className="add-edit-form">
          <h2>{editMode ? "Edit Verse Of The Day" : "Add New Verse Of The Day Entry"}</h2>
          <form onSubmit={handleSubmit}>
            <div className="admin-input-wrapper">
              <label htmlFor="verse-date">Date</label>
              <input
                type="date"
                id="verse-date"
                value={verseDate}
                onChange={(e) => setVerseDate(e.target.value)}
                required
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="scripture-location">Scripture Location</label>
              <input
                type="text"
                id="scripture-location"
                value={scriptureLocation}
                onChange={(e) => setScriptureLocation(e.target.value)}
                placeholder="e.g., John 3:16"
                required
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="verse-text">Verse Text</label>
              <textarea
                id="verse-text"
                value={verseText}
                onChange={(e) => setVerseText(e.target.value)}
                rows="4"
                required
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="is-active">Set as Active Verse of the Day</label>
              <input
                type="checkbox"
                id="is-active"
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
            </div>

            <div className="admin-input-wrapper">
              <label htmlFor="is-persistent">Set as Persistent Verse of the Day</label>
              <input
                type="checkbox"
                id="is-persistent"
                checked={isPersistent}
                onChange={() => setIsPersistent(!isPersistent)}
              />
            </div>

            <button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddEditVerseOfDay;
