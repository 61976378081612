import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from '../api/axios';

const PrayerRequestForm = ({ ready }) => {
    const [prayerFormData, setPrayerFormData] = useState({ name: '', contact: '', message: '' });
    const prayerRecaptchaToken = useRef(null); // Using useRef for the reCAPTCHA token
    const [prayerFormStatus, setPrayerFormStatus] = useState({ success: null, message: '' });
    const [isSubmittingPrayer, setIsSubmittingPrayer] = useState(false);
    const [prayerRecaptchaVisible, setPrayerRecaptchaVisible] = useState(false); // Controls reCAPTCHA visibility
    const [recaptchaError, setRecaptchaError] = useState(null); // Error handling for reCAPTCHA
    const [formReady, setFormReady] = useState(false); // Tracks if the form is ready for submission

    const handlePrayerChange = (e) => {
        const updatedFormData = { ...prayerFormData, [e.target.id.replace("prayer-", "")]: e.target.value };
        setPrayerFormData(updatedFormData);

        // Set reCAPTCHA visibility if all fields are filled
        const allFieldsFilled = Object.values(updatedFormData).every(field => field.trim() !== '');
        setPrayerRecaptchaVisible(allFieldsFilled);
        setFormReady(allFieldsFilled && prayerRecaptchaToken.current !== null); // Update form readiness
    };

    const handlePrayerRecaptchaChange = (token) => {
        prayerRecaptchaToken.current = token;
        setRecaptchaError(null); // Clear any previous errors

        // Check form readiness when reCAPTCHA is completed
        const allFieldsFilled = Object.values(prayerFormData).every(field => field.trim() !== '');
        setFormReady(allFieldsFilled && token !== null); // Update form readiness
    };

    const handlePrayerSubmit = async (e) => {
        e.preventDefault();
        if (!prayerRecaptchaToken.current) {
            setPrayerFormStatus({ success: false, message: 'Please complete the reCAPTCHA.' });
            return;
        }
        setIsSubmittingPrayer(true);
        try {
            await axios.post('/api/prayer-request', { ...prayerFormData, recaptchaToken: prayerRecaptchaToken.current });
            setPrayerFormStatus({ success: true, message: 'Prayer request submitted successfully!' });
            setPrayerFormData({ name: '', contact: '', message: '' });
            prayerRecaptchaToken.current = null; // Reset reCAPTCHA token
            setPrayerRecaptchaVisible(false); // Hide reCAPTCHA
            setFormReady(false); // Reset form readiness
        } catch (error) {
            console.error("Submission error:", error);
            setPrayerFormStatus({ success: false, message: 'Failed to submit prayer request. Please try again.' });
        } finally {
            setIsSubmittingPrayer(false);
        }
    };

    return (
        <>
            <h2>Submit a Prayer Request</h2>
            <div className='contact-form-container'>
                <div className='contact-form'>
                    <form onSubmit={handlePrayerSubmit}>
                        <div className='input-wrapper'>
                            <label htmlFor="prayer-name">NAME</label>
                            <input 
                                id="prayer-name" 
                                type='text' 
                                value={prayerFormData.name} 
                                onChange={handlePrayerChange} 
                                required 
                            />
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="prayer-contact">CONTACT (Email/Phone)</label>
                            <input 
                                id="prayer-contact" 
                                type='text' 
                                value={prayerFormData.contact} 
                                onChange={handlePrayerChange} 
                                required 
                            />
                        </div>
                        <div className='input-wrapper'>
                            <label htmlFor="prayer-message">JOY AND/OR CONCERN</label>
                            <textarea 
                                id="prayer-message" 
                                value={prayerFormData.message} 
                                onChange={handlePrayerChange} 
                                required 
                            />
                        </div>

                        {/* Conditional reCAPTCHA */}
                        {prayerRecaptchaVisible && ready && (
                            <div className='captcha-wrapper'>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                    onChange={handlePrayerRecaptchaChange}
                                    onErrored={() => setRecaptchaError('reCAPTCHA failed to load. Please try refreshing.')}
                                />
                            </div>
                        )}

                        {recaptchaError && (
                            <p className="error-message">{recaptchaError}</p>
                        )}
                        
                        {prayerFormStatus.message && (
                            <p className={`form-status ${prayerFormStatus.success ? 'success' : 'error'}`}>
                                {prayerFormStatus.message}
                            </p>
                        )}
                        <div className='submit-button-wrapper'>
                            <button type="submit" disabled={isSubmittingPrayer || !formReady}>
                                {isSubmittingPrayer ? 'Sending Prayer Request' : 'Send Prayer Request'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default PrayerRequestForm;
