import React, { useState, useRef } from 'react';
import './styles/Footer.css';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from '../api/axios';
import pastor from './../assets/images/pastor.webp';
import CreditLogoIcon from './../assets/icons/CreditLogoIcon';
import { NavLink } from 'react-router-dom';

const Footer = () => {

    const [footerFormData, setFooterFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const recaptchaToken = useRef(null); // Use useRef for reCAPTCHA token
    const [recaptchaVisible, setRecaptchaVisible] = useState(false); // Controls reCAPTCHA visibility
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formStatus, setFormStatus] = useState({ success: null, message: '' });
    const [formReady, setFormReady] = useState(false); // Tracks form readiness for submission

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        const key = id.replace('footer-', ''); // Strip the 'footer-' prefix to match state keys
        const updatedFormData = { ...footerFormData, [key]: value };
        setFooterFormData(updatedFormData);

        // Set reCAPTCHA visibility if all fields are filled
        const allFieldsFilled = Object.values(updatedFormData).every(field => field.trim() !== '');
        setRecaptchaVisible(allFieldsFilled);
        setFormReady(allFieldsFilled && recaptchaToken.current !== null); // Update form readiness
    };

    const handleRecaptchaChange = (token) => {
        recaptchaToken.current = token;

        // Update form readiness when reCAPTCHA is completed
        const allFieldsFilled = Object.values(footerFormData).every(field => field.trim() !== '');
        setFormReady(allFieldsFilled && token !== null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!recaptchaToken.current) {
            setFormStatus({ success: false, message: 'Please complete the reCAPTCHA.' });
            return;
        }
        setIsSubmitting(true);
        try {
            await axios.post('/api/contact-message', { ...footerFormData, recaptchaToken: recaptchaToken.current });
            setFormStatus({ success: true, message: 'Message sent successfully!' });
            setFooterFormData({ name: '', email: '', message: '' });
            recaptchaToken.current = null; // Reset reCAPTCHA token
            setRecaptchaVisible(false); // Hide reCAPTCHA
            setFormReady(false); // Reset form readiness
        } catch (error) {
            setFormStatus({ success: false, message: 'Failed to send message. Please try again.' });
            console.error('Error submitting form:', error);
        } finally {
            setIsSubmitting(false);
        }
    };


  return (
        <footer className="footer" role="contentinfo" aria-labelledby="footer-title">
            <h2 id="footer-title" className="sr-only">Cross Roads United Methodist Church Footer</h2>
            <div className='footer-wrapper'>
                <div className='footer-container'>
                    <div className='footer-content-wrapper'>
                        <div className='footer-content-columns-wrapper'>
                            <div className='footer-content-column'>
                                <div className='pastor-image-wrapper'>
                                    <img src={pastor} alt="Portrait of Reverend Dustin McCune, pastor of Cross Roads United Methodist Church" height='128' width='101' />
                                </div>
                                <div className='welcome-message-wrapper'>
                                    <p><span>We welcome you to Cross Roads United Methodist Church with open arms and can't wait to see you here!</span></p>
                                    <p><span></span></p>
                                    <p><span>~ Reverend Dustin McCune</span></p>
                                </div>
                            </div>
                            <div className='footer-content-column'>
                            
                            </div>
                            <div className='footer-content-column'>
                                <div className='footer-content-column-header'>
                                    <h3><span>Contact Us</span></h3>
                                </div>
                                <div className='footer-content-row-wrapper'>
                                    <div className='footer-content-row'>
                                        <p>
                                            <span>
                                                Office: <a href='tel:304-522-7434' aria-label="Call Cross Roads United Methodist Church Office">304-522-7434</a>
                                                <br/>
                                                Childcare/Pre-K: <a href='tel:304-525-6547' aria-label="Call Cross Roads United Methodist Church Childcare and Preschool">304-525-6547</a>
                                                <br/>
                                                Email: <a href='mailto:crossroadsumc@comcast.net' aria-label="Email Cross Roads United Methodist Church">crossroadsumc@comcast.net</a>
                                            </span>
                                            <br/>
                                            <br/>
                                            <span>
                                                3146 Saltwell Road
                                                <br/>
                                                Huntington, WV 25705
                                            </span>
                                            <br/>
                                            <br/>
                                            <a href="https://www.wvumc.org/" target="_blank" rel="noopener noreferrer" aria-label="Visit the West Virginia United Methodist Conference website">
                                                West Virginia United Methodist Conference&nbsp;
                                                <i className="fa-solid fa-up-right-from-square" aria-hidden="true" />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className='footer-social-icons-wrapper'>
                                    <div className='socials-wrapper'>
                                        <div className='socials-container'>
                                            <div className='socials-row'>
                                                <a href="https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/" className="social-icon" aria-label="Visit Cross Roads United Methodist Church on Facebook" target="_blank" rel="noopener noreferrer">
                                                    <i className="fa-brands fa-facebook-f" aria-hidden="true" />
                                                </a>
                                                <a href="https://www.google.com/maps/place/Crossroads+UMC/@38.4024952,-82.3881641,15z/data=!4m2!3m1!1s0x0:0x4b99b1521962cec5?sa=X&ved=1t:2428&ictx=111" className="social-icon" aria-label="Find Cross Roads United Methodist Church on Google Maps" target="_blank" rel="noopener noreferrer">
                                                    <i className="fa-brands fa-google" aria-hidden="true" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='footer-content-column' role="form" aria-labelledby="contact-form-title">
                                <div className='footer-content-column-header'>
                                    <h3 id="contact-form-title"><span>Send us a Message</span></h3>
                                </div>
                                <div className='footer-contact-form-wrapper'>
                                    <div className='footer-contact-form-container'>
                                        <form className='footer-contact-form' onSubmit={handleSubmit}>
                                            <div className='input-wrapper half'>
                                                <label htmlFor="footer-name">Name:</label>
                                                <input id="footer-name" type="text" value={footerFormData.name} onChange={handleInputChange} required />
                                            </div>
                                            <div className='input-wrapper half'>
                                                <label htmlFor="footer-email">Email:</label>
                                                <input id="footer-email" type="email" value={footerFormData.email} onChange={handleInputChange} required />
                                            </div>
                                            <div className='input-wrapper full'>
                                                <label htmlFor="footer-message">Personal Message</label>
                                                <textarea id="footer-message" value={footerFormData.message} onChange={handleInputChange} required></textarea>
                                            </div>

                                            {/* Conditional reCAPTCHA display */}
                                            {recaptchaVisible && (
                                                <div className='footer-captcha'>
                                                    <ReCAPTCHA 
                                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                                        onChange={handleRecaptchaChange}
                                                    />
                                                </div>
                                            )}

                                            {/* Form Status Message */}
                                            {formStatus.message && (
                                                <p className={`form-status ${formStatus.success ? 'success' : 'error'}`}>
                                                    {formStatus.message}
                                                </p>
                                            )}

                                            {/* Submit button */}
                                            <div className='submit-wrapper'>
                                                <button type='submit' disabled={isSubmitting || !formReady}>
                                                    {isSubmitting ? 'Sending...' : 'Send Message'}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footer-bottom-wrapper'>
                        <div className='footer-bottom-content-wrapper'>
                            <div className='footer-nav-wrapper'>
                                <div className='footer-nav-container'>
                                    <nav className='footer-navlinks' aria-label="Footer Navigation">
                                        <NavLink to='/' activeclassname='active' aria-label="Navigate to Welcome page">Welcome</NavLink>
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <NavLink to='/about' activeclassname='active' aria-label="Navigate to About Us page">About Us</NavLink>
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <NavLink to='/worship' activeclassname='active' aria-label="Navigate to Worship Info page">Worship Info</NavLink>
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <NavLink to='/beliefs' activeclassname='active' aria-label="Navigate to the Beliefs page">Our Beliefs</NavLink>
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <NavLink to='/ministries-outreach' activeclassname='active' aria-label="Navigate to Contact Us page">Ministries</NavLink>
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <NavLink to='/childcare-preschool' activeclassname='active' aria-label="Navigate to Contact Us page">Childcare</NavLink>
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <NavLink to='/contact' activeclassname='active' aria-label="Navigate to Contact Us page">Contact Us</NavLink>
                                    </nav>
                                </div>
                            </div>
                            <div className='footer-credit-wrapper'>
                                <div className='footer-credit' aria-label="Copyright and site credits">
                                    <h3>
                                        <span>Copyright © 2024. Cross Roads UMC. All Rights Reserved.&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                        <span><p>Website built and managed by &nbsp;<a href="https://www.wattswebworks.com" target="_blank" rel="noopener noreferrer" aria-label="WattsWebWorks, opens in a new tab">WattsWebWorks<CreditLogoIcon width='35px' height='35px' style={{marginBottom: '8px'}}/></a></p></span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  );
};

export default Footer;
