import React from 'react';
import { Link } from 'react-router-dom';
import './styles/ContactBar.css';

import tempLogo from './../assets/images/umcLogo.webp';

const ContactBar = () => {
  return (
    <div className='contactbar-wrapper'>
      <div className='contactbar-container'>
        <div className='contactbar-column-1'>
          <div className='socials-wrapper' role="navigation" aria-label="Social media links">
            <div className='socials-container'>
              <div className='socials-row'>
                <a href="https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/" 
                   className="social-icon" 
                   aria-label="Visit Cross Roads United Methodist Church on Facebook" 
                   target="_blank" 
                   rel="noopener noreferrer">
                  <i className="fa-brands fa-facebook-f" aria-hidden="true" />
                </a>
                <a href="https://www.google.com/maps/place/Crossroads+UMC/@38.4024952,-82.3881641,15z/data=!4m2!3m1!1s0x0:0x4b99b1521962cec5?sa=X&ved=1t:2428&ictx=111" 
                   className="social-icon" 
                   aria-label="Find Cross Roads United Methodist Church on Google Maps" 
                   target="_blank" 
                   rel="noopener noreferrer">
                  <i className="fa-brands fa-google" aria-hidden="true" />
                </a>
              </div> 
            </div>
          </div>
        </div>
        <div className='contactbar-column-2'>
          {/* Placeholder for future use */}
        </div>
        <div className='contactbar-column-3'>
          <div className='logo-wrapper'>
            <Link to='/' className='logo-link' aria-label="Navigate to Home">
              <img src={tempLogo} 
                   alt="Cross Roads United Methodist Church logo" 
                   width="50" 
                   height="50" />
            </Link>
            <div className='years-wrapper'>
              <p><i className="fa-solid fa-cross" style={{color: '#ec1c2dc7'}}></i> Cross Roads UMC </p>
            </div>
          </div>
        </div>
        <div className='contactbar-column-4'>
          {/* Placeholder for future use */}
        </div>
      </div>
    </div>
  );
};

export default ContactBar;
