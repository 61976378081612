import React from 'react';
import { Helmet } from 'react-helmet-async';

import exteriorBW from './../assets/images/exteriorBW.webp';
import exterior from './../assets/images/exterior2.webp';


const OurHistory = () => {

    const CurrentYear = new Date().getFullYear();
    const yearsOfWorship = CurrentYear - 1892;


    return (
        <>

            <Helmet>
                <title>Our History - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Explore the rich history of Cross Roads United Methodist Church, from its founding in 1891 to its ongoing mission to serve the community."
                />
                <meta property="og:title" content="Our History - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Discover the legacy of Cross Roads United Methodist Church, established in 1891, and its commitment to faith, worship, and service."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/our-history" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/our-history" />
                

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Our History",
                        "url": "https://www.crossroadswv.com/our-history",
                        "description": "Explore the rich history of Cross Roads United Methodist Church, from its founding in 1891 to its ongoing mission to serve the community.",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com/our-history"
                        },
                        "author": {
                            "@type": "Organization",
                            "name": "Cross Roads United Methodist Church",
                            "url": "https://www.crossroadswv.com"
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "Cross Roads United Methodist Church",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "%PUBLIC_URL%/logo512.png"
                            }
                        }
                    })}
                </script>
            </Helmet>


            <div className='page-wrapper baptism'>
                <div className='page-title'>
                    <h1>OUR HISTORY</h1>
                    <hr/>
                </div>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>An Extensive History</h2>
                            <div className='text-content'>
                                <p>
                                    It is March of 1891. Snow is giving way to mud, in some places ankle-deep, but this does not hinder the group of men laying the foundation for the new church at Cross Roads. The hill-top site was cleared and made ready several weeks ago; then came the snow and the work was delayed. The lumber is being brought from town by horse and wagon — four horses to a wagon. One wagon is driven by John Hall, the other by George Ferguson. Men are all walking behind the wagons to chock, for the hills are steep and still a little slick.
                                </p>
                                <br/>
                                <p>
                                    This was a church born of cottage prayer and meetings. Houses were scattered and people walked or rode, most often by lantern light, to meet together to pray and talk about their Lord and the Christian life.
                                </p>
                                <br/>
                                <p>
                                    The ministers had no easy path in those days.  They received very little pay and had to do lots of walking, as many could not afford even a horse to ride. Cross Roads was one of several churches on a circuit, and visiting the sick, officiating at funerals and performing marriages for four or five parishes was an unending task. It was a calling from God, indeed.
                                </p>
                                <br/>
                                <p>
                                    Revivals in those days were prayed down from heaven by people who really believed in the need for salvation of souls.
                                </p>
                                <br/>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={exteriorBW} alt="Black and white of the original Cross Roads United Methodist Church building."/>
                            <p className='worship-years-wrapper'>{yearsOfWorship} years and counting!</p>
                        </div>
                    </div>    
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Expanding to Serve the Community</h2>
                            <div className='text-content'>
                                <p>
                                    In 1960 our Kindergarten Program was established for four and five year olds under the direction of Mrs. Wiley Estep. Soon more teachers were added: in 1961 "Miss Wanda" Tenney and in 1964 "Miss Betty" Crouch. This was an active Kindergarten of exceptionally high standards and well respected in the community and public schools. Mrs. Estep retired as director in 1973 and "Miss Betty" moved into that position. The program continued as a preschool until the end of the school year in 1990 when both "Miss Wanda" and "Miss Betty" retired. Another ministry for latch-key children had begun in 1989 and it was felt there was a greater need for this, so the preschool was closed.
                                </p>
                                <br/>
                                <p>
                                    A grant application was submitted to the West Virginia United Methodist Conference, and with the grant the program was begun. It was designed to serve children from kindergarten through fifth grade, and the first students were received in September 1989. Connie Taylor served as director until February 1990, when the current director, Pam Demoss, was employed. The B.A.S.E. Program has continued to grow under Pam's leadership and is operational from 6:30 a.m. until 6:00 p.m. Monday through Friday.
                                </p>
                                <br/>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={exterior} loading='lazy' alt="Modern Cross Roads United Methodist Church building."/>
                        </div>
                    </div>

                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Honoring Our Roots</h2>
                            <div className='text-content'>
                                <p>
                                    We owe much to the "class leaders", as they were called in the early days. These people held the church steady while the minister was serving his other churches. Without their strong support, the church might have failed.
                                </p>
                                <br/>
                                <p>
                                    We are especially thanksful for ministers like John Henry Shadburn, Frank Young, and Denver Dickens, who labored to complete the new church and make it the lovely sanctuary of God that is today. These men worked with their hearts and minds, to wrought a good work; and along with them worked many of our laymen.
                                </p>
                                <br/>
                                <p>
                                    <b>We are beyond thankful for all of our ministers, and we are beyond grateful for our heritage. We trust that our purpose shall always be the mission Christ has given us.</b>
                                </p> 
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default OurHistory;
