import React, { useState, useEffect } from "react";
import axios from './../../api/axios';
import './styles/AdminDailyReadings.css';
import { Link } from "react-router-dom";

const AdminVerseOfDay = () => {
    const [verses, setVerses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchVerses = async () => {
            try {
                const response = await axios.get('/api/verse-of-the-day');
                setVerses(response.data);
            } catch (error) {
                console.error("Failed to fetch verses of the day:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchVerses();
    }, []);

    const handleDelete = async (id) => {
        if (!window.confirm("Are you sure you want to delete this verse?")) return;

        try {
            await axios.delete(`/api/verse-of-the-day/${id}`);
            setVerses((prevVerses) => prevVerses.filter((verse) => verse.id !== id));
        } catch (error) {
            console.error("Failed to delete verse:", error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
    };

    // Function to truncate the verse text to 15 characters
    const truncateText = (text, limit) => {
        return text.length > limit ? text.substring(0, limit) + "..." : text;
    };

    return (
        <>
            <div className="add-new-button">
                <Link to="/admin/verse-of-the-day/create-new">Add New Verse</Link>
            </div>
            <div className="daily-readings-wrapper admin">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    verses.map((verse) => (
                        <div className="bible-reading-wrapper" key={verse.id}>
                            <div className="bible-reading admin">
                                <h3>{formatDate(verse.date)}</h3>
                                <h5>{verse.scripture_location}</h5>
                                <p className="verse-text">
                                    {truncateText(verse.verse_text, 15)}
                                </p>
                                <div className="actions-wrapper">
                                    <Link to={`/admin/verse-of-the-day/edit/${verse.id}`}>Edit</Link>
                                    <button onClick={() => handleDelete(verse.id)} className="delete-button">Delete</button>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </>
    );
};

export default AdminVerseOfDay;
