import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from './../api/axios';
import './styles/Announcements.css';

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get('/api/announcements');
        setAnnouncements(response.data);
      } catch (error) {
        console.error("Error fetching announcements:", error);
        setError("Could not load announcements. Please try again later.");
      }
    };

    fetchAnnouncements();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  return (
    <div className='content-columns-wrapper'>
      <div className='content-column' aria-labelledby="announcements-header">
        <div className='content-header-wrapper'>
          <h2 id="announcements-header">View Our Latest News and Announcements</h2>
        </div>
        <div className='newsfeed-wrapper'>
          {error ? (
            <p className="error-message">{error}</p>
          ) : (
            announcements.length > 0 ? (
              announcements.slice(0, 2).map((announcement) => (
                <div key={announcement.id} className='newsfeed-item-wrapper'>
                  <div className='newsfeed-item'>
                    <div className='newsfeed-content-wrapper'>
                      <div className='newsfeed-content'>
                        <div className='newsfeed-preview'>
                          <div className='newsfeed-title'>
                            <h3>
                            {announcement.subject}
                            </h3>
                          </div>
                          <h4 className='newsfeed-date'>
                            {announcement.expires === 1 && (
                              <>
                                <i className="fa-solid fa-calendar-days" aria-hidden="true"></i> {formatDate(announcement.submitted_at)}
                              </>
                            )}
                          </h4>
                          <div 
                            className='announcement-content'
                            dangerouslySetInnerHTML={{ __html: truncateText(announcement.announcement, 9999) }}
                            aria-label="Announcement content"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No announcements at this time.</p>
            )
          )}
          <Link to='/announcements' className='newsfeed-showmore-button' aria-label="Show more announcements">
            <span>Show More<i className="fa-solid fa-arrow-right-long go-arrow"></i></span>
          </Link>
        </div>
      </div>
      <div className='content-column'>
        <section className='start-times' aria-labelledby="notable-times-header">
          <div className='content-header-wrapper'>
            <h2 id="notable-times-header">Notable Times</h2>
          </div>
          <p>Sunday Worship: <b>10:00 AM</b></p>
          <p>Sunday School: <b>11:15 AM</b></p>
          <p>Sunday worship is broadcast live and saved on our Facebook as well, with past recordings also uploaded to the "Past Services" section of this site.</p>
        </section>
        
        <section className='quick-links' aria-labelledby="quick-links-header">
          <div className='content-header-wrapper'>
            <h2 id="quick-links-header">Quick Links</h2>
          </div>
          <a 
            href='https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/' 
            target="_blank" 
            rel="noopener noreferrer" 
            className='facebook' 
            aria-label="Visit our Facebook page"
          >
            <i className="fa-brands fa-square-facebook" aria-hidden="true" />&nbsp;Our Facebook <i className="fa-solid fa-arrow-right-long go-arrow" aria-hidden="true"></i>
          </a>
          <Link to='/past-sessions' aria-label="Go to past services">
            <i className="fa-regular fa-calendar-days" aria-hidden="true"></i>&nbsp;Past Services <i className="fa-solid fa-arrow-right-long go-arrow" aria-hidden="true"></i>
          </Link>
          <Link to='/contact' aria-label="Contact us">
            <i className="fa-solid fa-envelope" aria-hidden="true"></i>&nbsp;Contact Us <i className="fa-solid fa-arrow-right-long go-arrow" aria-hidden="true"></i>
          </Link>
        </section>
      </div>
    </div>
  );
};

export default Announcements;
