import React from 'react';
import './styles/Baptism.css';
import { Helmet } from 'react-helmet-async';

import wesley1 from './../assets/images/wesley1.jpg';
import wesley2 from './../assets/images/wesley2.jpg';

const MarshallWesley = () => {

    return (
        <>
            <Helmet>
                <title>Marshall Wesley - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Marshall Wesley is a ministry at Cross Roads United Methodist Church dedicated to loving God, loving others, and serving the needs of Marshall University students."
                />
                <meta property="og:title" content="Marshall Wesley - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Join Marshall Wesley, a ministry dedicated to reaching out to the campus and students of Marshall University, for opportunities to grow in faith and serve others."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/marshall-wesley" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="%PUBLIC_URL%/wesley1.jpg" />
                <link rel="canonical" href="https://www.crossroadswv.com/marshall-wesley" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "name": "Marshall Wesley",
                        "description": "Marshall Wesley is a campus ministry at Cross Roads United Methodist Church that focuses on serving the needs of Marshall University students and providing a space for spiritual growth.",
                        "url": "https://www.crossroadswv.com/marshall-wesley",
                        "location": {
                            "@type": "Place",
                            "name": "Cross Roads United Methodist Church",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "3146 Saltwell Rd",
                                "addressLocality": "Huntington",
                                "addressRegion": "WV",
                                "postalCode": "25705",
                                "addressCountry": "US"
                            }
                        },
                        "image": "%PUBLIC_URL%/wesley1.jpg",
                        "organizer": {
                            "@type": "Organization",
                            "name": "Cross Roads United Methodist Church",
                            "url": "https://www.crossroadswv.com"
                        },
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ]
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper first-responders'>
                <div className='page-title'>
                    <h1>MARSHALL WESLEY</h1>
                    <hr/>
                </div>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>What is Marshall Wesley</h2>
                            <div className='text-content'>
                                <p>Marshall Wesley is a ministry to the campus & students of Marshall University focused on loving God, loving others, loving a world in need.</p>
                                <br/>
                                <p>Learn more on the Marshall Wesley Facebook page <a href='https://www.facebook.com/MarshallUWesley/' target="_blank" rel="noopener noreferrer" aria-label="Learn more about the Recovery Alive program, a Christ-centered 12-step recovery program">here</a>.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={wesley1} alt='Marshall University Marshall Wesley Logo.' />
                        </div>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper' style={{alignItems: 'center'}}>
                        <div className='content-text-wrapper'>
                            <h2>Get Involved</h2>
                            <div className='text-content'>
                                <p>Marshall Wesley meets <b>every Thursday at 8:00PM</b> at the Campus Christian Center at Marshall University.</p>
                                <p>If you would like to learn more about Marshall Wesley or get involved, or have any questions, please contact us at <a href="mailto:crossroadsumc@comcast.net">crossroadsumc@comcast.net</a> or call us at <a href="tel:304-522-7434">(304)-522-7434</a>.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={wesley2} alt='Overhead view of people gathered around tables with fall decorations, sharing a meal and socializing in a church-like setting.' />
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default MarshallWesley;