import React, { useState, useEffect } from 'react';
import './styles/Hero.css';
import { Link } from 'react-router-dom';
import axios from '../api/axios';

const Hero = () => {
  const [verse, setVerse] = useState({
    verse_text: "“For God so loved the world, that he gave his only begotten Son, that whoever believes in him should not perish, but have eternal life.”",
    scripture_location: "John 3:16"
  });

  // Fetch the active verse of the day on component mount
  useEffect(() => {
    const fetchActiveVerse = async () => {
      try {
        const response = await axios.get('/api/active-verse-of-the-day');
        if (response.data) {
          setVerse({
            verse_text: `“${response.data.verse_text}”`, // Wrap text in quotes
            scripture_location: response.data.scripture_location,
          });
        }
      } catch (error) {
        console.error("Failed to fetch the active verse of the day:", error);
      }
    };

    fetchActiveVerse();
  }, []);

  return (
    <div className='hero-content-wrapper'>
        <div className='hero-content'>
            <header className='hero-header'>
                <h1><span>Welcome to Cross Roads</span><br/><span>United Methodist Church!</span></h1>
            </header>
            <div className='hero-description'>
                <h2>Verse of the Day</h2>
                <blockquote>
                    <p>{verse.verse_text}</p>
                    <p>
                        <cite>({verse.scripture_location})</cite>
                    </p>
                </blockquote>
            </div>
            <Link 
                to='/about' 
                className='hero-cta'
                aria-label="Learn more about Cross Roads United Methodist Church on the About Us page"
            >
                <span>About Us <i className="fa-solid fa-arrow-right-long go-arrow"></i></span>
            </Link>
        </div>
    </div>
  );
};

export default Hero;
