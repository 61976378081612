import React, { useState } from 'react';
import './styles/Baptism.css';
import { Helmet } from 'react-helmet-async';
import Slider from 'react-slick';

import baptism1 from './../assets/images/baptism1.webp';
import baptism2 from './../assets/images/baptism2.webp';
import baptism3 from './../assets/images/baptism3.webp';

import babyBaptism from './../assets/images/babyBaptism.webp';

const Baptism = () => {


    const [activeSlide, setActiveSlide] = useState(0);


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        arrows: false,
        beforeChange: (current, next) => setActiveSlide(next),
        afterChange: (current) => document.querySelector(`[data-index="${current}"] img`).focus()
    };

    return (
        <>

            <Helmet>
                <title>Baptism - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Learn about the meaning of baptism in the United Methodist tradition, including its significance, practice, and baptism for all ages."
                />
                <meta property="og:title" content="Baptism at Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Discover the purpose and significance of baptism within the United Methodist Church, including beliefs, practices, and what baptism means for all ages."
                />
                
                <meta property="og:url" content="https://wwww.crossroadswv.com/baptism" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/baptism" />
                

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Baptism",
                        "url": "https://www.crossroadswv.com/baptism",
                        "mainEntity": {
                            "@type": "Article",
                            "headline": "Baptism",
                            "description": "An in-depth overview of baptism in the United Methodist Church, including its significance as a sacrament, the role of faith, and the church's views on baptism for all ages.",
                            "author": {
                                "@type": "Organization",
                                "name": "Cross Roads United Methodist Church",
                                "url": "https://www.crossroadswv.com"
                            },
                            "publisher": {
                                "@type": "Organization",
                                "name": "Cross Roads United Methodist Church",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "%PUBLIC_URL%/logo512.png"
                                }
                            },
                            "keywords": ["baptism", "sacrament", "United Methodist Church", "Christian baptism", "faith"],
                            "articleBody": [
                                {
                                    "@type": "FAQPage",
                                    "mainEntity": [
                                        {
                                            "@type": "Question",
                                            "name": "What is baptism in the United Methodist Church?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Baptism is one of two sacraments in the United Methodist Church, serving as a means of divine grace and initiation into the universal church. It symbolizes God's grace and can be performed through sprinkling, pouring, or immersion."
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "What does baptism mean for United Methodists?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Baptism signifies God's covenant with us, the presence of the Holy Spirit, and the promise of grace throughout one's life. It marks an initiation into the church and calls for a continued commitment to faith and growth in holiness."
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "Can people of all ages be baptized?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Yes, the United Methodist Church practices baptism for people of all ages, from infants to adults, as a reflection of God's grace that is available to everyone."
                                            }
                                        },
                                        {
                                            "@type": "Question",
                                            "name": "Do United Methodists recognize baptisms from other denominations?",
                                            "acceptedAnswer": {
                                                "@type": "Answer",
                                                "text": "Yes, United Methodists recognize baptisms from other Christian denominations, as long as they are performed in water and in the name of the Father, Son, and Holy Spirit. United Methodists do not re-baptize as they believe God's covenant remains steadfast."
                                            }
                                        }
                                    ]
                                }
                            ],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "https://www.crossroadswv.com/baptism"
                            }
                        }
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper baptism'>
                <div className='page-title'>
                    <h1>BAPTISM</h1>
                    <hr/>
                </div>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Interested in Baptism?</h2>
                            <div className='text-content'>
                                <p>Baptism is one of two sacraments of United Methodists, the other being Holy Communion. In a sacrament, God uses common elements — in this case, water — as a means of divine grace. Baptism is administered by the church as the Body of Christ. It is the act of God through the grace of Jesus Christ and the work of the Holy Spirit. Baptism is the act of initiation and incorporation into the universal church of Jesus Christ, The United Methodist Church, and a local congregation. Baptism is offering by sprinkling, pouring, or immersion.</p>
                            </div>
                        </div>
                        <figure className='section-image-placeholder'>
                            <Slider {...settings}>
                                {[baptism1, baptism2, baptism3 ].map((image, index) => (
                                    <div
                                        key={index}
                                        data-index={index}
                                        tabIndex={activeSlide === index ? 0 : -1} // Only focusable when active
                                    >
                                        <img
                                            src={image}
                                            alt={`Baptism slide ${index + 1}`}
                                            style={{ pointerEvents: activeSlide === index ? 'auto' : 'none' }}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </figure>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>The Sacrament Of Baptism</h2>
                            <div className='text-content'>
                                <p>Traditions that only practice “believer’s baptism”– those who have professed faith in Jesus Christ for themselves in some public way — practice baptism not as a means of grace by which God saves and claims us, but rather as a further act of public profession and/or an act of obedience to the command of Christ that his followers be baptized. That is why these “believer’s baptism only” traditions generally refer to baptism as an ordinance — an act ordained or commanded by Christ — rather than a sacrament. The term sacrament means “an oath” and refers to God’s covenant with us (first of all) and ours in response to God’s gracious provision of salvation in Jesus Christ.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>What Does Baptism Mean?</h2>
                            <div className='text-content'>
                                <p>Baptism does not mean a person is “saved.” Salvation is a lifelong process during which we must continue to respond to God’s grace. Baptism offers the promise that the Holy Spirit will always be working in our lives, but salvation requires our acceptance of that grace, trust in Christ, and ongoing growth in holiness as long as we live. To refuse to accept baptism is to reject one of the means of grace that God offers us.</p>
                            </div>
                        </div>
                    </div>
                </section>




                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Baptism As A United Methodist or other Denomination</h2>
                            <div className='text-content'>
                                <p>United Methodism stands in the historic heritage of the Christian faith through the ages and, specifically, in the legacy of John Wesley. Wesley was an Anglican priest. As a result, United Methodism has inherited a “high” understanding of the church, the sacraments, and other aspects of worship. Wesley was also an evangelical revivalist. As a result, United Methodism emphasizes the necessity of conversion, personal relationship with Christ, and witnessing to others. Neither of these aspects alone represents who we are. As United Methodists, we hold the two together in our baptismal theology and practice and in our broader understanding of how God works in our lives for salvation.</p>
                                <br/>
                                <p>United Methodists recognize the baptism of other Christian denominations who baptize people in water in the name of the Father, the Son, and the Holy Spirit as generally understood in historic Christianity. We do not re-baptize as baptism is an act of God who always remains faithful on the divine side. Our side of the covenant relationship with God will need recommitment and reaffirmation.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Baptism For All Ages</h2>
                            <div className='text-content'>
                                <p>In Christian baptism, God claims those being baptized, whatever their age or ability to profess their faith, with divine grace. We offer baptism to people of all ages who have not previously received Christian baptism. In services of profession of faith and confirmation before the congregation, we respond to God’s grace by repenting of our sins, declaring our faith in Jesus Christ, and becoming professing members of the church. Clearly an infant can do nothing to save himself or herself, but is totally dependent on God’s grace, as we all are — whatever our age. The baptism of a baby assumes that the child will be nurtured and formed in the faith at home and at church. Those baptized as infants or young children do not become professing members until they are able to profess their own faith.
                                Through appropriate remembrances and celebrations, our children can be enabled to “remember” their baptism as much as they “remember” their physical birthday.
                                </p>
                                <br/>
                                <p>Even when the people being baptized are believing adults and are ready to profess their faith, our first emphasis is upon the gracious action of God who establishes the covenant of baptism with us rather than upon the individual’s decision.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={babyBaptism} alt="A priest or parent pours water over a baby's head during a baptism ceremony."/>
                        </div>
                    </div>
                </section>

            </div>
        </>
    );
}

export default Baptism;
