import React from 'react';
import './styles/Baptism.css';
import { Helmet } from 'react-helmet-async';

import recovery1 from './../assets/images/recovery.jpg';
import recovery2 from './../assets/images/recovery2.jpg';


const Recovery = () => {

    return (
        <>
            <Helmet>
                <title>Recovery Alive - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Recovery Alive is a church-based program at Cross Roads United Methodist Church that helps individuals find healing through the POWER, PEOPLE, and PROCESS of the Christ-centered 12 steps."
                />
                <meta property="og:title" content="Recovery Alive - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Join Recovery Alive, a church-based program that connects individuals to the POWER, PEOPLE, and PROCESS of the Christ-centered 12 steps for healing and recovery."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/recovery-alive" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="%PUBLIC_URL%/recovery.jpg" />
                <link rel="canonical" href="https://www.crossroadswv.com/recovery-alive" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "name": "Recovery Alive",
                        "description": "Recovery Alive is a church-based program at Cross Roads United Methodist Church that helps individuals find healing through the Christ-centered 12-step process.",
                        "url": "https://www.crossroadswv.com/recovery-alive",
                        "location": {
                            "@type": "Place",
                            "name": "Cross Roads United Methodist Church",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "3146 Saltwell Rd",
                                "addressLocality": "Huntington",
                                "addressRegion": "WV",
                                "postalCode": "25705",
                                "addressCountry": "US"
                            }
                        },
                        "image": "%PUBLIC_URL%/recovery.jpg",
                        "organizer": {
                            "@type": "Organization",
                            "name": "Cross Roads United Methodist Church",
                            "url": "https://www.crossroadswv.com"
                        },
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ]
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper first-responders'>
                <div className='page-title'>
                    <h1>RECOVERY ALIVE</h1>
                    <hr/>
                </div>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>What is Recovery Alive?</h2>
                            <div className='text-content'>
                                <p>(Starting Spring 2025)</p>
                                <br/>
                                <p>Recovery Alive is a church-based program that connects the broken to the <b>POWER</b>, <b>PEOPLE</b>, and <b>PROCESS</b> of the Christ-centered 12 steps.</p>
                                <br/>
                                <p><b>The POWER of Recovery: </b> Our priority is to connect people to our one and only true Higher POWER, Jesus Christ.</p>
                                <p><b>The PEOPLE of Recovery: </b> We strive to help the broke nconnect to safe and supportive PEOPLE.</p>
                                <p><b>The PROCESS of Recovery: </b> We help broken people connect to the Christ-centered 12-step PROCESS of recovery.</p>
                                <br/>
                                <p>Learn more on the Recovery Alive website <a href='https://www.recoveryalive.com/' target="_blank" rel="noopener noreferrer" aria-label="Learn more about the Recovery Alive program, a Christ-centered 12-step recovery program">here</a>.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={recovery1} alt="Logo of the Recovery Alive program, featuring a vibrant sun rising over the words 'Recovery Alive!' with the tagline 'Raising Hope From The Dead' and 'Cross Roads UMC Huntington' below." />
                        </div>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper' style={{alignItems: 'center'}}>
                        <div className='content-text-wrapper'>
                            <h2>How to get Involved</h2>
                            <div className='text-content'>
                                <p>If you would like to learn more about Recovery Alive or get involved, or have any questions, please contact us at <a href="mailto:crossroadsumc@comcast.net">crossroadsumc@comcast.net</a> or call us at <a href="tel:304-522-7434">(304)-522-7434</a>.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={recovery2} alt="A person standing and speaking to the group during a Recovery Alive session at Cross Roads United Methodist Church. The individual is speaking from the front of the room with church pews in the background." />
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Recovery;