export const frontendAccessRules = {
    '/admin/users': ['siteadmin', 'admin'],
    '/admin/childcare-staff': ['siteadmin', 'admin', 'childcare', 'user'],
    '/admin/church-staff': ['siteadmin', 'admin', 'church', 'user'],
    '/admin/dashboard': ['siteadmin', 'admin', 'user', 'church', 'childcare'],
    '/admin/messages': ['siteadmin', 'admin', 'user', 'church'],
    '/admin/past-sessions': ['siteadmin', 'admin', 'user', 'church'],
    '/admin/daily-readings': ['siteadmin', 'admin', 'user', 'church'],
    '/admin/verse-of-the-day': ['siteadmin', 'admin', 'user', 'church'],
    '/admin/announcements': ['siteadmin', 'admin', 'user', 'church'],
    '/admin/operation-christmas-child': ['siteadmin', 'admin', 'user', 'church'],
    '/admin/first-responders': ['siteadmin', 'admin', 'user', 'church'],
    '/admin/mission-trips': ['siteadmin', 'admin', 'user', 'church'],
    '/admin/common-grounds': ['siteadmin', 'admin', 'user', 'church'],
    '/admin/holidays': ['siteadmin', 'admin', 'user', 'church'],
    '/admin/september-club': ['siteadmin', 'admin', 'user', 'church']
};