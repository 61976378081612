import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../api/axios';
import { useNavigate, useLocation } from 'react-router-dom';

import { frontendAccessRules } from './../components/utility/accessRules';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: !!sessionStorage.getItem('user'),
    user: JSON.parse(sessionStorage.getItem('user')) || null,
  });
  const [loading, setLoading] = useState(true); // Only for initial load
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await axios.get('/api/login', { withCredentials: true });
        if (response.data.loggedIn) {
          setAuth({ isAuthenticated: true, user: response.data.user });
          sessionStorage.setItem('user', JSON.stringify(response.data.user));
          if (location.pathname === '/login') {
            navigate('/admin/dashboard');
          }
        } else {
          handleSessionExpired();
        }
      } catch (error) {
        console.error('Session check error:', error);
        handleSessionExpired();
      } finally {
        setLoading(false);
      }
    };

    const handleSessionExpired = () => {
      setAuth({ isAuthenticated: false, user: null });
      sessionStorage.removeItem('user');
      if (location.pathname.startsWith('/admin')) {
        navigate('/login');
      }
    };

    checkSession();

    const interval = setInterval(checkSession, 5 * 60 * 1000); // Check session every 5 minutes

    return () => clearInterval(interval); // Cleanup on unmount
  }, [navigate, location.pathname]);

  // Function to check access for a given route
  const hasAccess = (route) => {
    // Find the matching base route in the rules
    const matchingRule = Object.keys(frontendAccessRules).find((baseRoute) => 
      route.startsWith(baseRoute)
    );
  
    // If a base route is found, check if the user has the required role
    const allowedRoles = frontendAccessRules[matchingRule] || [];
    return allowedRoles.includes(auth.user?.role);
  };
  

  const login = async (userData) => {
    try {
      const response = await axios.post('/api/login', userData, { withCredentials: true });
      if (response.data.user) {
        setAuth({ isAuthenticated: true, user: response.data.user });
        sessionStorage.setItem('user', JSON.stringify(response.data.user));
        navigate('/admin/dashboard');
        return response.data; // Return the response data
      } else {
        setAuth({ isAuthenticated: false, user: null });
        return { message: 'Login failed. Please try again.' }; // Return a failure message
      }
    } catch (error) {
      console.error('Login error:', error);
      setAuth({ isAuthenticated: false, user: null });
      return { message: 'Login failed. Please try again.' }; // Return an error message
    }
  };

  const logout = async () => {
    try {
      await axios.post('/api/logout', {}, { withCredentials: true });
      setAuth({ isAuthenticated: false, user: null });
      sessionStorage.removeItem('user');
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, hasAccess }}>
      {/* Show nothing while loading */}
      {loading && <></>}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
