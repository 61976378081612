import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from '../../api/axios';
import './styles/AdminChildcareStaff.css';

const AdminChildcareStaff = () => {
    const [staffList, setStaffList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const teamIcons = {
        "Infant Care Team": "fa-solid fa-baby-carriage",
        "Toddler Care Team": "fa-solid fa-child-reaching",
        "2-year-old Care Team": "fa-solid fa-2",
        "3-year-old Care Team": "fa-solid fa-3",
        "Pre-K Care Team": "fa-solid fa-children",
        "School-age Care Team": "fa-solid fa-school",
        "Kitchen Staff": "fa-solid fa-kitchen-set",
    };

    useEffect(() => {
        const fetchStaff = async () => {
            try {
                const response = await axios.get('/api/childcare-staff');
                setStaffList(response.data);
            } catch (error) {
                console.error("Failed to fetch childcare staff:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchStaff();
    }, []);

    const handleEdit = (id) => {
        navigate(`/admin/childcare-staff/edit/${id}`);
    };

    const handleDelete = async (id) => {
        if (!window.confirm("Are you sure you want to delete this staff member?")) return;

        try {
            await axios.delete(`/api/childcare-staff/${id}`);
            setStaffList((prevList) => prevList.filter((staff) => staff.id !== id));
        } catch (error) {
            console.error("Failed to delete staff member:", error);
        }
    };

    const director = staffList.find(staff => staff.team === "Director");
    const assistantDirector = staffList.find(staff => staff.team === "Assistant Director");
    const filteredStaffList = staffList.filter(staff => !["Director", "Assistant Director"].includes(staff.team));

    const groupedStaff = filteredStaffList.reduce((acc, staff) => {
        if (!acc[staff.team]) {
            acc[staff.team] = [];
        }
        acc[staff.team].push(staff);
        return acc;
    }, {});

    return (
        <>
            <div className="add-new-button">
                <Link to="/admin/childcare-staff/create-new">Add New Staff Member</Link>
            </div>
            <section className='staff-section-wrapper admin-childcare'>
                <div className='childcare-staff-heading-wrapper'>
                    <h2 className='childcare-staff-header'>Our Childcare Staff</h2>

                    {/* Director Section */}
                    <p className="director-wrapper">
                        Director: <b>{director ? director.name : "Not Assigned"}</b>
                        {director && (
                            <span className="staff-actions">
                                <button 
                                    className="edit-button" 
                                    onClick={() => handleEdit(director.id)}
                                >
                                    <i className="fa-solid fa-pen-to-square"></i>
                                </button>
                                <button 
                                    className="delete-button" 
                                    onClick={() => handleDelete(director.id)}
                                >
                                    <i className="fa-solid fa-trash-can"></i>
                                </button>
                            </span>
                        )}
                    </p>

                    {/* Assistant Director Section */}
                    <p className="director-wrapper">
                        Assistant Director: <b>{assistantDirector ? assistantDirector.name : "Not Assigned"}</b>
                        {assistantDirector && (
                            <span className="staff-actions">
                                <button 
                                    className="edit-button" 
                                    onClick={() => handleEdit(assistantDirector.id)}
                                >
                                    <i className="fa-solid fa-pen-to-square"></i>
                                </button>
                                <button 
                                    className="delete-button" 
                                    onClick={() => handleDelete(assistantDirector.id)}
                                >
                                    <i className="fa-solid fa-trash-can"></i>
                                </button>
                            </span>
                        )}
                    </p>
                </div>

                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    <div className='childcare-staff-wrapper'>
                        {Object.entries(groupedStaff).map(([team, members]) => (
                            <div className='staff-member-wrapper' key={team}>
                                <div className='staff-member'>
                                    <div className='staff-member-image'>
                                        <i className={teamIcons[team]}></i>
                                    </div>
                                    <div className='staff-member-info-wrapper'>
                                        <div className='staff-member-info'>
                                            <div className='info-line'>
                                                <h3>{team}</h3>
                                            </div>
                                            <div className='info-line admin'>
                                                {members.map((staff) => (
                                                    <div 
                                                        className="staff-name-wrapper" 
                                                        key={staff.id}
                                                    >
                                                        <p>{staff.name}</p>
                                                        <div className="staff-actions">
                                                            <button 
                                                                className="edit-button" 
                                                                onClick={() => handleEdit(staff.id)}
                                                            >
                                                                <i className="fa-solid fa-pen-to-square"></i>
                                                            </button>
                                                            <button 
                                                                className="delete-button" 
                                                                onClick={() => handleDelete(staff.id)}
                                                            >
                                                                <i className="fa-solid fa-trash-can"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </section>
        </>
    );
};

export default AdminChildcareStaff;
