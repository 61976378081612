import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from './../api/axios';
import './styles/PastService.css';
import { Helmet } from 'react-helmet-async';

const PastService = () => {
    const { id } = useParams();
    const [serviceData, setServiceData] = useState(null);
    const [isPdfVisible, setIsPdfVisible] = useState(false);

    const togglePdfVisibility = () => {
        setIsPdfVisible(!isPdfVisible);
    };

    useEffect(() => {
        const fetchService = async () => {
            try {
                const response = await axios.get(`/api/past-services/${id}`);
                setServiceData(response.data);
            } catch (error) {
                console.error("Error fetching service data:", error);
            }
        };
        fetchService();
    }, [id]);

    if (!serviceData) return <p>Loading...</p>;

    const { service_date, service_type, video_link, video_file_name, bulletin_file_name } = serviceData;
    const formattedDate = new Date(service_date).toLocaleDateString();

    // Remove inline styles or frameborder attributes in iframe strings
    const cleanIframeContent = (iframeStr) => {
        return iframeStr.replace(/\sstyle="[^"]*"/g, "").replace(/\sframeborder="[^"]*"/g, "");
    };

    // Structured data setup
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "PlaceOfWorship",
        "name": "Cross Roads United Methodist Church",
        "url": "https://crossroadswv.com",
        "logo": "%PUBLIC_URL%/logo512.png",
        "sameAs": ["https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"],
        "contactPoint": [
            {
                "@type": "ContactPoint",
                "telephone": "+1-304-522-7434",
                "contactType": "Church Support",
                "areaServed": "US",
                "availableLanguage": "English",
                "email": "crossroadsumc@comcast.net"
            },
            {
                "@type": "ContactPoint",
                "telephone": "+1-304-525-6547",
                "contactType": "Childcare Services",
                "areaServed": "US",
                "availableLanguage": "English",
                "description": "Contact Cross Roads Daycare and Preschool for enrollment and program details.",
                "email": "crccm@comcast.net"
            }
        ],
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "3146 Saltwell Rd",
            "addressLocality": "Huntington",
            "addressRegion": "WV",
            "postalCode": "25705",
            "addressCountry": "US"
        },
        "hasMap": "https://www.google.com/maps/place/Crossroads+UMC/@38.4024952,-82.3881641,15z/data=!4m2!3m1!1s0x0:0x4b99b1521962cec5?sa=X&ved=1t:2428&ictx=111",
        "description": "Join us at Cross Roads United Methodist Church, a warm and welcoming community of faith in Huntington. We offer weekly worship services, community outreach programs, and spiritual support for all ages.",
        "foundingDate": "1892",
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": "38.4024952",
            "longitude": "-82.3881641"
        },
        "department": [
            {
                "@type": "Organization",
                "name": "Cross Roads Daycare and Preschool",
                "sameAs": "https://crossroadswv.com/childcare-preschool",
                "description": "Our daycare and preschool program provides a nurturing environment for young children.",
                "serviceType": "Preschool and Childcare Services"
            }
        ],
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": `https://crossroadswv.com/past-session/${id}`
        },
        "relatedLink": {
            "@type": video_link !== 'none' || video_file_name !== 'none' ? "VideoObject" : "Event",
            "name": service_type,
            "description": `A past service at Cross Roads United Methodist Church held on ${formattedDate}.`,
            "startDate": service_date,
            "url": `https://crossroadswv.com/past-session/${id}`,
            ...(video_link !== 'none' && { "contentUrl": video_link }),
            ...(video_file_name !== 'none' && { 
                "contentUrl": `${process.env.REACT_APP_API_URL}/uploads/past-services/${id}/${video_file_name}`
            }),
            "uploadDate": service_date,
            "transcript": "Bulletin available for download.",
            ...(bulletin_file_name !== 'none' && {
                "additionalType": "Bulletin",
                "mainEntityOfPage": {
                    "@type": "MediaObject",
                    "contentUrl": `${process.env.REACT_APP_API_URL}/uploads/past-services/${id}/${bulletin_file_name}`,
                    "name": "Bulletin"
                }
            })
        }
    };

    return (
        <>
            <Helmet>
                <title>{`${service_type} - ${formattedDate} | Cross Roads United Methodist Church`}</title>
                <meta
                    name="description"
                    content={`Watch the ${service_type} held on ${formattedDate} at Cross Roads United Methodist Church. Access video and bulletin details.`}
                />
                <meta property="og:title" content={`${service_type} - ${formattedDate} | Cross Roads UMC`} />
                <meta property="og:description" content={`Catch up on the ${service_type} from ${formattedDate}.`} />
                <meta property="og:type" content="video.other" />
                {video_link !== 'none' && <meta property="og:video:url" content={video_link} />}
                <meta property="og:url" content={`https://crossroadswv.com/past-session/${id}`} />

                <link rel="canonical" href={`https://crossroadswv.com/past-session/${id}`} />

                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>

            <div className='page-wrapper past-service'>
                <div className='page-title'>
                    <h1>{service_type}</h1>
                    <h2 className='page-title-subtitle'>{formattedDate}</h2>
                    <hr />
                </div>

                <section className='service-content-wrapper'>
                    <div className='service-video-wrapper'>
                        <h3>Video</h3>
                            <div className="video-container">
                                {video_link !== 'none' && video_link.includes('<iframe') ? (
                                    <div dangerouslySetInnerHTML={{ __html: cleanIframeContent(video_link) }} />
                                ) : video_link !== 'none' ? (
                                    <iframe
                                        title='Service Video'
                                        src={video_link}
                                        width='560'
                                        height='314'
                                        allowFullScreen
                                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                    ></iframe>
                                ) : video_file_name !== 'none' ? (
                                    <video controls>
                                        <source
                                            src={`${process.env.REACT_APP_API_URL}/uploads/past-services/${id}/${video_file_name}`}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    <p>No video available for this service.</p>
                                )}
                            </div>

                    </div>
                </section>

                <div className='bulletin-wrapper'>
                    <h3>Bulletin</h3>
                    {bulletin_file_name !== 'none' && (
                        <>
                            <button onClick={togglePdfVisibility} className='toggle-bulletin-button'>
                                {isPdfVisible ? 'Hide Bulletin' : 'Show Bulletin'}
                            </button>
                            <a
                                href={`${process.env.REACT_APP_API_URL}/uploads/past-services/${id}/${bulletin_file_name}`}
                                download
                                className='download-bulletin-button'
                                aria-label="Download Bulletin"
                            >
                                <i className="fa-solid fa-download"></i> Download
                            </a>
                            <div className={`pdf-drawer ${isPdfVisible ? 'visible' : ''}`}>
                                <iframe
                                    src={`${process.env.REACT_APP_API_URL}/uploads/past-services/${id}/${bulletin_file_name}`}
                                    title="Service Bulletin"
                                    width="100%"
                                    height="600px"
                                ></iframe>
                            </div>
                        </>
                    )}
                    {bulletin_file_name === 'none' && <p>No bulletin available for this service.</p>}
                </div>
            </div>
        </>
    );
};

export default PastService;
