import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from './../api/axios';
import './styles/MissionTrips.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet-async';

// Static images for the main content
import tyrand2 from './../assets/images/Tyrand2.webp';
import tyrand5 from './../assets/images/Tyrand5.webp';
import tyrand7 from './../assets/images/Tyrand7.webp';
import tyrand104 from './../assets/images/Tyrand104.webp';
import tyrand107 from './../assets/images/Tyrand107.webp';
import missionTrip from './../assets/images/Tyrand14.webp';

const MissionTrips = () => {
    const [missionTrips, setMissionTrips] = useState([]);
    const baseURL = process.env.REACT_APP_API_URL;
    const [activeSlide, setActiveSlide] = useState(0);


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        arrows: false,
        beforeChange: (current, next) => setActiveSlide(next),
        afterChange: (current) => document.querySelector(`[data-index="${current}"] img`).focus()
    };

    // Fetch mission trips from the backend
    useEffect(() => {
        const fetchMissionTrips = async () => {
            try {
                const response = await axios.get('/api/mission-trips');
                setMissionTrips(response.data);
            } catch (error) {
                console.error("Failed to fetch mission trips:", error);
            }
        };
        fetchMissionTrips();
    }, []);

    // Helper function to format date as "Month Year"
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    return (
        <>
            <Helmet>
                <title>Mission Trips - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Join Cross Roads United Methodist Church in mission trips that serve communities both locally and abroad. Discover ways to get involved and view past missions."
                />
                <meta property="og:title" content="Mission Trips - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Our church is dedicated to serving others through mission trips. Explore our past trips and find out how you can join future missions."
                />
                <meta property="og:url" content="https://crossroadswv.com/mission-trips" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/mission-trips" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "PlaceOfWorship",
                        "name": "Cross Roads United Methodist Church",
                        "url": "https://crossroadswv.com",
                        "logo": "%PUBLIC_URL%/logo512.png",
                        "sameAs": [
                            "https://www.facebook.com/CrossRoadsUMC"
                        ],
                        "contactPoint": [
                            {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-522-7434",
                                "contactType": "Church Support",
                                "areaServed": "US",
                                "availableLanguage": "English",
                                "email": "crossroadsumc@comcast.net"
                            }
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "3146 Saltwell Rd",
                            "addressLocality": "Huntington",
                            "addressRegion": "WV",
                            "postalCode": "25705",
                            "addressCountry": "US"
                        },
                        "description": "Cross Roads United Methodist Church offers mission trips that provide support and resources to communities in need, building relationships and sharing the love of Christ.",
                        "department": {
                            "@type": "Organization",
                            "name": "Mission Trips Program",
                            "description": "A program that organizes mission trips both locally and internationally, aiming to support and uplift communities through hands-on service and faith-building activities.",
                            "url": "https://crossroadswv.com/mission-trips",
                            "parentOrganization": {
                                "@type": "PlaceOfWorship",
                                "name": "Cross Roads United Methodist Church"
                            }
                        },
                        "event": missionTrips.map((trip) => ({
                            "@type": "Event",
                            "name": `Mission Trip to ${trip.location}`,
                            "startDate": trip.trip_date,
                            "location": {
                                "@type": "Place",
                                "name": trip.location,
                                "address": {
                                    "@type": "PostalAddress",
                                    "addressCountry": "US"
                                }
                            },
                            "image": `${baseURL}/uploads/mission_trips/${trip.location.replace(/[^a-z0-9]/gi, '_').toLowerCase()}/${trip.image}`,
                            "description": trip.description,
                            "url": `https://crossroadswv.com/mission-trips/${trip.id}`
                        })),
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://crossroadswv.com/mission-trips"
                        }
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper missiontrips'>
                <div className='page-title'>
                    <h1>MISSION TRIPS</h1>
                    <hr/>
                </div>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Our Mission Work</h2>
                            <div className='text-content'>
                                <p>
                                    Our church is passionate about serving others through mission trips whenever possible.
                                    Throughout the year, we organize trips both locally and abroad to provide support, build
                                    relationships, and share the love of Christ.
                                </p>
                                <p>
                                    We believe in the power of community and hands-on service. By working side-by-side with
                                    those in need, we not only provide practical help but also foster lasting connections
                                    and hope.
                                </p>
                            </div>
                        </div>
                        <figure className='section-image-placeholder'>
                            <Slider {...settings}>
                                {[tyrand2, tyrand5, tyrand7, tyrand104, tyrand107].map((image, index) => (
                                    <div
                                        key={index}
                                        data-index={index}
                                        tabIndex={activeSlide === index ? 0 : -1} // Only focusable when active
                                    >
                                        <img
                                            src={image}
                                            alt={`Slide ${index + 1}`}
                                            style={{ pointerEvents: activeSlide === index ? 'auto' : 'none' }}
                                        />
                                    </div>
                                ))}
                            </Slider>
                            <figcaption className='carousel-caption'>
                                Our team working together to build a deck on a mission trip to Tyrand Cooperative Ministries in Randolf County.
                            </figcaption>
                        </figure>
                    </div>
                </section>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>How You Can Get Involved</h2>
                            <div className='text-content'>
                                <p>If you're interested in participating in one of our mission trips, we have opportunities throughout the year. You can get involved by signing up during Sunday services, attending our mission planning meetings, or joining one of our training sessions. No matter your skill set, there’s always a place for you to make a difference and serve alongside us. Reach out to our mission team leader for more details on how you can contribute.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img 
                                src={missionTrip} 
                                alt="Volunteers standing together outside a brick building, smiling and prepared for a mission trip; vehicles are parked nearby." 
                            />
                        </div>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Past Mission Trips</h2>
                            <div className='text-content'>
                            <p>We have been blessed to participate in various mission trips, helping communities rebuild after natural disasters, supporting educational projects, and partnering with local churches to provide essential resources. These trips not only allow us to serve others but also deepen our own faith as we witness the incredible work God is doing around the world. We look forward to many more opportunities to make an impact.</p>
                            </div>

                            {/* Mission Trip Cards */}
                            <div className='mission-trips-wrapper'>
                                {missionTrips.map((trip) => (
                                    <Link key={trip.id} to={`/mission-trips/${trip.id}`} className='mission-trip'>
                                        <div className='mission-image-wrapper'>
                                            <img
                                                src={`${baseURL}/uploads/mission_trips/${trip.location.replace(/[^a-z0-9]/gi, '_').toLowerCase()}/${trip.image}`}
                                                alt={trip.location}
                                            />
                                        </div>
                                        <div className='mission-boxes-wrapper'>
                                            <h5>{trip.location}</h5>
                                            <p>{formatDate(trip.trip_date)}</p>
                                        </div>
                                        <div className='mission-desc-wrapper'>
                                            <p>{trip.description}</p>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default MissionTrips;
