import React from 'react';
import './styles/Membership.css';
import { Helmet } from 'react-helmet-async';

import image from './../assets/images/newMemberReception.webp';

const Membership = () => {
    return (
        <>
            <Helmet>
                <title>Church Membership - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Learn about becoming a member of Cross Roads United Methodist Church, including Profession of Faith, Transfer of Membership, and more."
                />
                <meta property="og:title" content="Church Membership at Cross Roads UMC" />
                <meta
                    property="og:description"
                    content="Discover the paths to church membership at Cross Roads United Methodist Church, whether through Profession of Faith, transferring from another denomination, or rejoining the Methodist Church."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/membership" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/membership" />
                

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Church Membership",
                        "url": "https://www.crossroadswv.com/membership",
                        "mainEntity": {
                            "@type": "Article",
                            "headline": "Church Membership",
                            "description": "An overview of the membership options at Cross Roads United Methodist Church, including Profession of Faith, Transfer of Membership, and Joining from Another Denomination.",
                            "author": {
                                "@type": "Organization",
                                "name": "Cross Roads United Methodist Church",
                                "url": "https://www.crossroadswv.com"
                            },
                            "publisher": {
                                "@type": "Organization",
                                "name": "Cross Roads United Methodist Church",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "%PUBLIC_URL%/logo512.png"
                                }
                            },
                            "keywords": ["church membership", "Profession of Faith", "membership transfer", "baptism", "Cross Roads UMC"],
                            "about": [
                                {
                                    "@type": "Thing",
                                    "name": "Profession of Faith",
                                    "description": "The commitment to join the church through an initial expression of faith in Jesus Christ."
                                },
                                {
                                    "@type": "Thing",
                                    "name": "Transfer of Membership",
                                    "description": "The option to transfer membership from another United Methodist Church."
                                },
                                {
                                    "@type": "Thing",
                                    "name": "Joining from Another Denomination",
                                    "description": "Joining Cross Roads UMC from another Christian denomination."
                                }
                            ],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "https://www.crossroadswv.com/membership"
                            }
                        }
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper membership'>
                <div className='page-title'>
                    <h1>CHURCH MEMBERSHIP</h1>
                    <hr />
                </div>
                <section className='membership-page-content-section'>
                    <div className='section-content-wrapper'>

                        <div className='section-text-column'>
                            <div className='text-block'>
                                <p>Becoming a member of Cross Roads United Methodist Church is a step on your journey as a disciple of Jesus Christ. It signifies your commitment to grow and live out your faith within this local United Methodist community. By joining, you are choosing to make Cross Roads your church home and to plant spiritual roots here. There are several ways you can become a member of Cross Roads.</p>
                                <br />
                            </div>
                            <div className='text-separator'>
                                <hr />
                            </div>
                            <h2>Profession of Faith</h2>
                            <div className='text-content-wrapper'>
                                <p>Profession of Faith as a first time commitment to Christ and His church. If you have not been previously baptized, one of the ministers will arrange a time for you to receive the sacrament of baptism.</p>
                            </div>
                            <h2>Transfer of Membership From Another United Methodist Church</h2>
                            <div className='text-content-wrapper'>
                                <p>It is not necessary that you have your church letter before being received into membership. The church office will secure a certificate of transfer for you.</p>
                            </div>
                            <h2>Transfer of Membership From Another Denomination</h2>
                            <div className='text-content-wrapper'>
                                <p>You may also join from another Christian faith tradition. A second baptism is not required as we recognize the baptism of all Christian churches.</p>
                            </div>
                        </div>
                        <div className='section-image-column'>
                            <img src={image} alt="New member being received into the church, in the Sanctuary of Cross Roads United Methodist Church" className="membership-image"/>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Membership;
