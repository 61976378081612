import './App.css';
import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

import Home from './pages/Home.js';

import ContactBar from './components/ContactBar.js';
import NavBar from './components/NavBar.js';
import Footer from './components/Footer.js';
import About from './pages/About.js';
import Contact from './pages/Contact.js';
import Beliefs from './pages/Beliefs.js';
import Communion from './pages/Communion.js';
import Membership from './pages/Membership.js';
import Baptism from './pages/Baptism.js';
import Worship from './pages/Worship.js';
import Childcare from './pages/Childcare.js';
import ChristmasChild from './pages/ChristmasChild.js';
import FirstResponders from './pages/FirstResponders.js';
import MissionTrips from './pages/MissionTrips.js';
import PastSessions from './pages/PastSessions.js';
import CommonGrounds from './pages/CommonGrounds.js';
import DailyReadings from './pages/DailyReadings.js';
import Ministries from './pages/Ministries.js';
import PastService from './pages/PastService.js';
import Admin from './pages/Admin.js';

import AdminDashboard from './components/admin/AdminDashboard.js';

import PrivateRoute from './components/PrivateRoute.js';
import AdminChristmasChild from './components/admin/AdminChristmasChild.js';
import Login from './pages/Login.js';
import AdminUsers from './components/admin/AdminUsers.js';
import AddEditChristmasChild from './components/admin/AddEditChristmasChild.js';
import AdminCommonGrounds from './components/admin/AdminCommonGrounds.js';
import AdminFirstResponders from './components/admin/AdminFirstResponders.js';
import AdminMissionTrips from './components/admin/AdminMissionTrips.js';
import AddEditMissionTrip from './components/admin/AddEditMissionTrip.js';
import AdminDailyReadings from './components/admin/AdminDailyReadings.js';
import AddEditDailyReadings from './components/admin/AddEditDailyReadings.js';
import AdminAnnouncements from './components/admin/AdminAnnouncements.js';
import AddEditAnnouncement from './components/admin/AddEditAnnouncement.js';
import AdminMissionTripImages from './components/admin/AdminMissionTripImages.js';
import ViewMissionTrip from './pages/ViewMissionTrip.js';
import AdminVerseOfDay from './components/admin/AdminVerseOfDay.js';
import AddEditVerseOfDay from './components/admin/AddEditVerseOfDay.js';
import AdminPastServices from './components/admin/AdminPastServices.js';
import AdminChurchStaff from './components/admin/AdminChurchStaff.js';
import AddEditChurchStaff from './components/admin/AddEditChurchStaff.js';
import AdminChildcareStaff from './components/admin/AdminChildcareStaff.js';
import AddEditChildcareStaff from './components/admin/AddEditChildcareStaff.js';
import AddEditPastSession from './components/admin/AddEditPastSession.js';
import AddEditUser from './components/admin/AddEditUser.js';
import Announcements from './pages/Announcements.js';
import MobileNavBar from './components/MobileNavBar.js';
import NotFoundPage from './pages/NotFoundPage.js';
import AdminMessages from './components/admin/AdminMessages.js';

import ScrollToTop from './components/utility/ScrollToTop.js';
import Scouts from './pages/Scouts.js';
import Holidays from './pages/Holidays.js';
import VBS from './pages/VBS.js';
import OurHistory from './pages/OurHistory.js';

import logo from './assets/images/umcLogo.webp';

import hero from './assets/images/sanctuaryFull.webp';
import about from './assets/images/sanctuaryFromPew.webp';
import childcare from './assets/images/childcareWindow.webp';

import history from './assets/images/exteriorBW.webp';
import membership from './assets/images/newMemberReception.webp';
import baptism from './assets/images/baptism1.webp';
import communion from './assets/images/communion.webp';
import worship from './assets/images/entrance1.webp';

import christmasChild from './assets/images/operationchristmaschild2.webp';
import firstResponders1 from './assets/images/firstResponder10.webp';
import firstResponders2 from './assets/images/valentinesCards.webp';

import missionTrips from './assets/images/Tyrand2.webp';
import scouts from './assets/images/scouts.webp';
import commonGrounds from './assets/images/commonGrounds1.webp';
import holidays from './assets/images/santa.webp';
import vbs from './assets/images/vbs.webp';
import AdminHolidays from './components/admin/AdminHolidays.js';
import SeptemberClub from './pages/SeptemberClub.js';
import AdminSeptemberClub from './components/admin/AdminSeptemberClub.js';
import GriefSupport from './pages/GriefSupport.js';
import WednesdayNightKids from './pages/WednesdayNightKids.js';
import Recovery from './pages/Recovery.js';
import GBH from './pages/GBH.js';
import MarshallWesley from './pages/MarshallWesley.js';




function App() {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const imagesToPreload = [logo, hero, about, childcare, history, membership, baptism, communion, worship, christmasChild, 
        firstResponders1, firstResponders2, missionTrips, scouts, commonGrounds, holidays, vbs];

      imagesToPreload.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    }, []);

    // Update window width on resize
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <div className="App">
      <ScrollToTop />
      <header role="banner">
        <div className='header-wrapper'>
          {/* Conditionally render the ContactBar only for desktop screens */}
          {/*windowWidth >= 785 && <ContactBar />*/}

          {/* Conditionally render the NavBar or MobileNavBar based on screen width */}
          {/*windowWidth >= 785 ? <NavBar /> : <MobileNavBar />*/}

          {windowWidth >= 785 && <ContactBar />}
          {windowWidth >= 785 ? <NavBar /> : <MobileNavBar />}
        </div>
        <div className='navbar-backdrop'>
            <div className='backdrop-placeholder' />
        </div>
        
      </header>
      
      <main id="main-content" className="content-wrapper">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/beliefs" element={<Beliefs />} />
          <Route path="/communion" element={<Communion />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/baptism" element={<Baptism />} />
          <Route path="/worship" element={<Worship />} />
          <Route path="/childcare-preschool" element={<Childcare />} />
          <Route path="/operation-christmas-child" element={<ChristmasChild />} />
          <Route path="/first-responders" element={<FirstResponders />} />
          <Route path="/cub-boy-scouts" element={<Scouts />} />
          <Route path="/holidays" element={<Holidays />} />
          <Route path="/vacation-bible-school" element={<VBS />} />
          <Route path="/grief-support" element={<GriefSupport />} />
          <Route path="/wednesday-night-kids" element={<WednesdayNightKids />} />
          <Route path="/recovery-alive" element={<Recovery />} />
          <Route path="/gods-big-house" element={<GBH />} />
          <Route path="/marshall-wesley" element={<MarshallWesley />} />

          <Route path="/mission-trips" element={<MissionTrips />} />
          <Route path="/mission-trips/:id" element={<ViewMissionTrip />} />

          <Route path="/past-sessions" element={<PastSessions />} />
          <Route path="/past-session/:id" element={<PastService />} />
          <Route path="/common-grounds" element={<CommonGrounds />} />
          <Route path="/september-club" element={<SeptemberClub />} />
          <Route path="/daily-readings" element={<DailyReadings />} />
          <Route path="/ministries-outreach" element={<Ministries />} />
          <Route path="/announcements" element={<Announcements />} />
          <Route path="/our-history" element={<OurHistory />} />

          <Route path="/login" element={<Login />} />
          
          <Route element={<PrivateRoute/>}>
            <Route path="/admin" element={<Admin />}>
              <Route path="dashboard" element={<AdminDashboard />} />

              <Route path="operation-christmas-child" element={<AdminChristmasChild />} />
              <Route path="operation-christmas-child/create-new" element={<AddEditChristmasChild />} />
              <Route path="operation-christmas-child/edit/:id" element={<AddEditChristmasChild />} />
              
              <Route path="common-grounds" element={<AdminCommonGrounds />} />
              <Route path="september-club" element={<AdminSeptemberClub />} />
              <Route path="holidays" element={<AdminHolidays />} />
              <Route path="first-responders" element={<AdminFirstResponders />} />

              <Route path="mission-trips" element={<AdminMissionTrips />} />
              <Route path="mission-trips/create-new" element={<AddEditMissionTrip />} />
              <Route path="mission-trips/edit/:id" element={<AddEditMissionTrip />} />
              <Route path="mission-trips/:id/images" element={<AdminMissionTripImages />} />

              <Route path="daily-readings" element={<AdminDailyReadings />} />
              <Route path="daily-readings/create-new" element={<AddEditDailyReadings />} />
              <Route path="daily-readings/edit/:id" element={<AddEditDailyReadings />} />

              <Route path="announcements" element={<AdminAnnouncements />} />
              <Route path="announcements/create-new" element={<AddEditAnnouncement />} />
              <Route path="announcements/edit/:id" element={<AddEditAnnouncement />} />

              <Route path="verse-of-the-day" element={<AdminVerseOfDay />} />
              <Route path="verse-of-the-day/create-new" element={<AddEditVerseOfDay />} />
              <Route path="verse-of-the-day/edit/:id" element={<AddEditVerseOfDay />} />

              <Route path="past-sessions" element={<AdminPastServices />} />
              <Route path="past-sessions/create-new" element={<AddEditPastSession />} />
              <Route path="past-sessions/edit/:id" element={<AddEditPastSession />} />

              <Route path="church-staff" element={<AdminChurchStaff />} />
              <Route path="church-staff/create-new" element={<AddEditChurchStaff />} />
              <Route path="church-staff/edit/:id" element={<AddEditChurchStaff />} />

              <Route path="childcare-staff" element={<AdminChildcareStaff />} />
              <Route path="childcare-staff/create-new" element={<AddEditChildcareStaff />} />
              <Route path="childcare-staff/edit/:id" element={<AddEditChildcareStaff />} />

              <Route path="users" element={<AdminUsers />} />
              <Route path="users/new-user" element={<AddEditUser />} />
              <Route path="users/edit-user/:id" element={<AddEditUser />} />



              <Route path="messages" element={<AdminMessages />} />
            </Route>
          </Route>
          



          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>

      {!isAdminRoute && <Footer />}
    </div>
  );
}

export default App;
