import React from 'react';
import './styles/Baptism.css';
import { Helmet } from 'react-helmet-async';

import wnk from './../assets/images/wnk1.jpg';


const WednesdayNightKids = () => {

    return (
        <>
            <Helmet>
                <title>Wednesday Night Kids - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Wednesday Night Kids (WNK) is a ministry at Cross Roads United Methodist Church that provides faith-based lessons, service projects, and fellowship for children and youth."
                />
                <meta property="og:title" content="Wednesday Night Kids - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Join Wednesday Night Kids, a ministry for children and youth, for lessons, games, and fellowship every Wednesday evening at Cross Roads United Methodist Church."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/wednesday-night-kids" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/wednesday-night-kids" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "name": "Wednesday Night Kids",
                        "description": "A weekly ministry for children and youth at Cross Roads United Methodist Church to grow spiritually through lessons, activities, and fellowship.",
                        "url": "https://www.crossroadswv.com/wednesday-night-kids",
                        "location": {
                            "@type": "Place",
                            "name": "Cross Roads United Methodist Church",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "3146 Saltwell Rd",
                                "addressLocality": "Huntington",
                                "addressRegion": "WV",
                                "postalCode": "25705",
                                "addressCountry": "US"
                            }
                        },
                        "image": "%PUBLIC_URL%/wnk.jpg",
                        "organizer": {
                            "@type": "Organization",
                            "name": "Cross Roads United Methodist Church",
                            "url": "https://www.crossroadswv.com"
                        },
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ]
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper first-responders'>
                <div className='page-title'>
                    <h1>WEDNESDAY NIGHT KIDS</h1>
                    <hr/>
                </div>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>WNK at Cross Roads</h2>
                            <div className='text-content'>
                                <p>Wednesday Night Kids (WNK) is a ministry for children and youth every Wednesday night, with the purpose of helping the kids grow spiritually and grow in their relationship with Christ with faith-based lessons, service projects, mission work, and fellowship and games. Through faith-based lessons, service projects, mission work, and fellowship, kids engage in activities that nurture their faith while also having fun and building friendships.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={wnk} alt="A group of children and adults standing together in a Walmart store, smiling at the camera, during a community service activity." />
                        </div>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>Who, When, and Where?</h2>
                            <div className='text-content'>
                                <p>Wednesday Night Kids (WNK) meets every <b>Wednesday</b> at <b>6:30 PM</b> at Cross Roads United Methodist Church. The program is designed to help <b>children and youth</b> grow spiritually and build a relationship with Christ.</p>
                                <p>If you would like to learn more about Wednesday Night Kids (WNK) or have any questions, please contact us at <a href="mailto:crossroadsumc@comcast.net">crossroadsumc@comcast.net</a> or call us at <a href="tel:304-522-7434">(304)-522-7434</a>.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default WednesdayNightKids;