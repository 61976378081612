import React from 'react';
import './styles/Baptism.css';
import { Helmet } from 'react-helmet-async';

import grief from './../assets/images/grief.jpg';


const GriefSupport = () => {

    return (
        <>
            <Helmet>
                <title>Grief Support Group - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="The Grief Support Group at Cross Roads United Methodist Church offers a compassionate and safe space for individuals who have experienced loss. We help one another heal and navigate the challenges of grief."
                />
                <meta property="og:title" content="Grief Support Group - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Join the Grief Support Group at Cross Roads UMC for a compassionate space where individuals can heal, share their experiences, and support one another through the grieving process."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/grief-support" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/grief-support" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "name": "Grief Support Group",
                        "description": "A compassionate group for those who have experienced loss, providing a safe space for healing and mutual support.",
                        "url": "https://www.crossroadswv.com/grief-support",
                        "location": {
                            "@type": "Place",
                            "name": "Cross Roads United Methodist Church",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "3146 Saltwell Rd",
                                "addressLocality": "Huntington",
                                "addressRegion": "WV",
                                "postalCode": "25705",
                                "addressCountry": "US"
                            }
                        },
                        "image": "%PUBLIC_URL%/griefSupportImage.jpg",
                        "organizer": {
                            "@type": "Organization",
                            "name": "Cross Roads United Methodist Church",
                            "url": "https://www.crossroadswv.com"
                        },
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ]
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper first-responders'>
                <div className='page-title'>
                    <h1>GRIEF SUPPORT GROUP</h1>
                    <hr/>
                </div>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>What is it?</h2>
                            <div className='text-content'>
                                <p>The Grief Support Group at Cross Roads United Methodist Church offers a compassionate and safe space for individuals who have experienced loss. We provide a place where people can heal, share their feelings, and find comfort among others who understand. Through group discussions, prayers, and mutual support, we walk together on the path of healing and recovery from grief.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <img src={grief} alt="Close-up of hands joining together in solidarity, symbolizing support and comfort for individuals in a grief support group." />
                        </div>
                    </div>
                </section>

                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>When and Where?</h2>
                            <div className='text-content'>
                                <p>The Grief Support Group meets on the <b>3rd Tuesday</b> of each month at <b>1:30 PM</b> at Cross Roads United Methodist Church. We gather to share stories, reflect, and offer each other comfort during difficult times. Our group is open to anyone who is grieving the loss of a loved one or facing other challenges, and all are welcome to join in the healing process.</p>
                                <p>If you would like to learn more about the Grief Support Group or have any questions, please contact us at <a href="mailto:crossroadsumc@comcast.net">crossroadsumc@comcast.net</a> or call us at <a href="tel:304-522-7434">(304)-522-7434</a>.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default GriefSupport;