import React, { useState, useEffect, useRef } from 'react';
import axios from './../api/axios';
import './styles/ChristmasChild.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet-async';

import banner from './../assets/images/operationchristmaschild2.webp';
import bannerVideo from './../assets/images/occPersonalStory.mp4';

const ChristmasChild = () => {
    const [entries, setEntries] = useState([]);
    const sliderRef = useRef(null);
    const videoRef = useRef(null);
    const baseURL = process.env.REACT_APP_API_URL;
    const [currentSlide, setCurrentSlide] = useState(0); // Track the visible slide


    // Fetch Operation Christmas Child highlights
    useEffect(() => {
        const fetchEntries = async () => {
            try {
                const response = await axios.get('/api/operation-christmas-child');
                setEntries(response.data);
            } catch (error) {
                console.error("Failed to fetch Operation Christmas Child highlights:", error);
            }
        };
        fetchEntries();
    }, []);

    // Carousel settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        arrows: false,
        afterChange: (index) => setCurrentSlide(index)
    };
    
    // Function to handle video play and pause events
    const handleVideoPlay = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPause(); // Pause the carousel when the video plays
        }
    };

    const handleVideoPause = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPlay(); // Resume the carousel when the video pauses
        }
    };

    return (
        <>
            <Helmet>
                <title>Operation Christmas Child - Cross Roads United Methodist Church</title>
                <meta
                    name="description"
                    content="Learn about Operation Christmas Child, a global outreach program by Cross Roads United Methodist Church, bringing joy and hope to children worldwide through gift-filled shoeboxes."
                />
                <meta property="og:title" content="Operation Christmas Child - Cross Roads United Methodist Church" />
                <meta
                    property="og:description"
                    content="Discover how you can contribute to Operation Christmas Child, sharing love and the Gospel with children in need around the world."
                />
                <meta property="og:url" content="https://www.crossroadswv.com/operation-christmas-child" />
                <meta property="og:type" content="website" />

                <link rel="canonical" href="https://www.crossroadswv.com/operation-christmas-child" />
                

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "PlaceOfWorship",
                        "name": "Cross Roads United Methodist Church",
                        "url": "https://www.crossroadswv.com",
                        "logo": "%PUBLIC_URL%/logo512.png",
                        "sameAs": [
                            "https://www.facebook.com/p/Cross-Roads-UMC-Huntington-100064638013481/"
                        ],
                        "contactPoint": [
                            {
                                "@type": "ContactPoint",
                                "telephone": "+1-304-522-7434",
                                "contactType": "Church Support",
                                "areaServed": "US",
                                "email": "crossroadsumc@comcast.net"
                            }
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "3146 Saltwell Rd",
                            "addressLocality": "Huntington",
                            "addressRegion": "WV",
                            "postalCode": "25705",
                            "addressCountry": "US"
                        },
                        "description": "Cross Roads United Methodist Church’s outreach includes Operation Christmas Child, delivering shoebox gifts to children worldwide.",
                        "foundingDate": "1892",
                        "department": {
                            "@type": "Organization",
                            "name": "Operation Christmas Child",
                            "description": "Operation Christmas Child delivers shoeboxes filled with essentials and toys to children in need worldwide, sharing hope and the Gospel.",
                            "url": "https://www.crossroadswv.com/operation-christmas-child",
                            "additionalType": "OutreachProgram",
                            "image": [
                                `${baseURL}/uploads/operation_christmas_child/${entries[0]?.image_file_name || ''}`,
                                `${baseURL}/uploads/operation_christmas_child/${entries[1]?.image_file_name || ''}`,
                                `${baseURL}/uploads/operation_christmas_child/${entries[2]?.image_file_name || ''}`
                            ].filter(Boolean)
                        },
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.crossroadswv.com/operation-christmas-child"
                        }
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper christmaschild'>
                <div className='page-title'>
                    <h1>OPERATION CHRISTMAS CHILD</h1>
                    <hr/>
                </div>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>What Is It?</h2>
                            <div className='text-content'>
                            <p>Operation Christmas Child is a global outreach program that delivers gift-filled shoeboxes to children in need around the world. Through this initiative, individuals and families pack shoeboxes with toys, school supplies, hygiene items, and other essentials. These boxes are then distributed by Samaritan’s Purse to children in communities affected by war, poverty, natural disasters, and other crises, sharing not only practical gifts but also the message of hope and love through the Gospel of Jesus Christ.</p>
                            </div>
                        </div>
                        <div className='section-image-placeholder'>
                            <Slider ref={sliderRef} {...settings}>
                                <div
                                    className='carousel-item'
                                    tabIndex={currentSlide === 0 ? 0 : -1} // Only focusable when visible
                                >
                                    <img 
                                        src={banner} 
                                        alt='Operation Christmas Child Banner'
                                        style={{ borderRadius: '5rem', boxShadow: 'rgba(0, 0, 0, 0.514) 0 3px 8px', width: '100%', height: 'auto', marginBottom: '4.8rem'}}
                                    />
                                </div>
                                <div
                                    className='carousel-item'
                                    tabIndex={currentSlide === 1 ? 0 : -1} // Only focusable when visible
                                >
                                    <video
                                        ref={videoRef}
                                        controls
                                        onPlay={handleVideoPlay}
                                        onPause={handleVideoPause}
                                        onEnded={handleVideoPause}
                                        style={{ borderRadius: '5rem', boxShadow: 'rgba(0, 0, 0, 0.514) 0 3px 8px', width: '100%', height: 'auto' }}
                                    >
                                        <source src={bannerVideo} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <p className='carousel-caption'>Personal story demonstrating how Operation Christmas Child works and helps.</p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                    <div className='content-text-wrapper'>
                        <h2>How To Contribute</h2>
                        <div className='text-content'>
                            <p>Shoeboxes for Operation Christmas Child are collected year-round to ensure we have plenty of supplies to fill as many boxes as possible when the time comes. We accept donations of toys, school supplies, hygiene items, and other small gifts that can bring joy and hope to children in need. Whether you contribute once or throughout the year, every donation makes a difference. If you have items to donate or would like to get involved, or have any questions at all, please contact John Smith to learn more about how you can contribute.</p>
                        </div>
                    </div>


                    </div>
                </section>
                <section className='text-content-section'>
                    <div className='section-content-wrapper'>
                        <div className='content-text-wrapper'>
                            <h2>A Successful Outreach Effort</h2>
                            <div className='text-content'>
                                <p>A huge <b>THANK YOU</b> to everyone who contributed items for the shoeboxes for Operation Christmas Child. Through your generosity, we were able to exceed our expected number of shoeboxes! These shoeboxes, packed with love and essential items, are ready to be distributed to children aged 2 to 14 around the world, spreading hope and the love of Jesus. Some of our boxes have already reached various countries across the globe, such as Panama, Ecuador, Peru, Haiti, and Mali. Please continue to pray for those delivering these gifts, especially in challenging areas, and for the children who will receive them. Thank you for being a part of this important mission outreach.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='text-content-section'>
                <div className='section-content-wrapper'>
                    <div className='content-text-wrapper'>
                        <h2>Through the years...</h2>
                        <div className='occ-highlights-row'>
                            {entries.map(entry => (
                                <div className='occ-highlight-wrapper' key={entry.id}>
                                    <div className='highlight-year-wrapper'>
                                        <h3>{entry.occ_year}</h3>
                                    </div>
                                    <div className='occ-highlight'>
                                        <div className='highlight-image-wrapper'>
                                            <img src={`${baseURL}/uploads/operation_christmas_child/${entry.image_file_name}`} alt={`${entry.boxes_filled} Operation Christmas Child boxes filled in ${entry.occ_year}`} />
                                        </div>
                                        <div className='highlight-boxes-wrapper'>
                                            <h5>{entry.boxes_filled}</h5>
                                            <p>boxes filled and sent</p>
                                        </div>
                                        <div className='highlight-desc-wrapper'>
                                            <p>{entry.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

                
                
            </div>
        </>
    );
}

export default ChristmasChild;
